/**
 * @generated SignedSource<<fbbe540602d848061b56449d5b993ce9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SubscriptionStatus = "active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "trialing" | "unpaid";
import { FragmentRefs } from "relay-runtime";
export type activityWorkflowDialog_root$data = {
  readonly mandateProbabilityTypes: ReadonlyArray<{
    readonly label: string;
    readonly name: string;
  }>;
  readonly me: {
    readonly firstName: string | null;
    readonly id: string;
    readonly isAdmin: boolean | null;
    readonly lastName: string | null;
    readonly modules: ReadonlyArray<string>;
    readonly organisation: {
      readonly formattedAddress: string | null;
      readonly name: string | null;
    } | null;
    readonly primaryEmail: {
      readonly email: string;
    } | null;
    readonly primaryImage: {
      readonly url: string;
    } | null;
    readonly subscription: {
      readonly status: SubscriptionStatus;
    } | null;
  } | null;
  readonly tenantSettings: {
    readonly countryCode: string;
  } | null;
  readonly " $fragmentType": "activityWorkflowDialog_root";
};
export type activityWorkflowDialog_root$key = {
  readonly " $data"?: activityWorkflowDialog_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"activityWorkflowDialog_root">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "activityWorkflowDialog_root",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Email",
          "kind": "LinkedField",
          "name": "primaryEmail",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "primaryImage",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organisation",
          "kind": "LinkedField",
          "name": "organisation",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "formattedAddress",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Subscription",
          "kind": "LinkedField",
          "name": "subscription",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAdmin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "modules",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "mandateProbabilityTypes",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "mandate_probability_types"
        }
      ],
      "concreteType": "Dictionary",
      "kind": "LinkedField",
      "name": "dictionaries",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": "dictionaries(type:\"mandate_probability_types\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenantSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "countryCode",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "b6122a5e8da0e3aa180735aabfa5a10c";

export default node;
