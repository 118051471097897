import {
  type JSXElementConstructor,
  type ReactElement,
  useMemo,
  useState,
} from 'react';

import { Box, Typography } from '@mui/material';
import {
  GridActionsCellItem,
  type GridActionsCellItemProps,
  type GridColDef,
  type GridEventListener,
  type GridRowParams,
  type GridRowsProp,
  gridClasses,
} from '@mui/x-data-grid-premium';
import { useNavigate } from 'react-router-dom';

import { toGlobalId } from '../../../shared/global-id';
import { type Translate, useLocale } from '../../../src/hooks/locale';
import { type Currency, getCurrencyByCountryCode } from '../../../src/locale';
import { formatPrice } from '../../../src/utils/format-price';
import {
  type GetListingOffersQuery,
  type GetOffersQuery,
  type MeQuery,
  Offers_Status_Enum,
} from '../../__generated__/graphql';
import { RaDataGrid } from '../../components/data-grid/RaDataGrid';
import { useAppData } from '../../providers/AppDataProvider';
import { toHumanReadableDate } from '../../utils/formatting';
import { ListingCell } from '../data-grid/ListingCell';
import { ListingPriceCell } from '../data-grid/ListingPriceCell';
import { Tag } from '../Tag';
import { TimeAgo } from '../TimeAgo';
import { UserCard } from '../UserCard';

import { ChangeStatusModal } from './ChangeStatusModal';

type OfferRow =
  | GetOffersQuery['offers'][number]
  | GetListingOffersQuery['offers'][number];

const isGlobalOffersRow = (
  row: OfferRow,
): row is GetOffersQuery['offers'][number] => {
  return 'single_property_images' in row.lot.property;
};

type OffersTableProps = {
  rows: GridRowsProp<OfferRow>;
  fromListing?: boolean;
  rowCount?: number;
  isLoading?: boolean;
  onRowClick?: GridEventListener<'rowClick'>;
};

export const getOfferStatusLabel = (offer: OfferRow, t: Translate) => {
  switch (offer.status) {
    case Offers_Status_Enum.Pending:
      return t('pending');
    case Offers_Status_Enum.Accepted:
      return t('accepted');
    case Offers_Status_Enum.Refused:
      return `${t('refused')}${
        offer.offer_refused_type?.label
          ? ` - ${offer.offer_refused_type.label}`
          : ''
      }`;
  }
};

const getOfferRowActions = ({
  params,
  disableActions = false,
  onStatusChange,
  t,
  currentUser,
}: {
  params: GridRowParams<OfferRow>;
  disableActions?: boolean;
  onStatusChange: (
    offer: OfferRow,
    status: Offers_Status_Enum.Accepted | Offers_Status_Enum.Refused,
  ) => void;
  t: Translate;
  currentUser: MeQuery['me'];
}): ReactElement<
  GridActionsCellItemProps,
  string | JSXElementConstructor<any>
>[] => {
  if (
    currentUser?.id !== params.row.created_by &&
    currentUser?.is_admin !== true
  ) {
    return [];
  }

  return params.row.status === 'pending'
    ? [
        <GridActionsCellItem
          showInMenu={true}
          label={t('Refused')}
          onClick={() => onStatusChange(params.row, Offers_Status_Enum.Refused)}
          color="inherit"
          key="update-offer-status-refused-action"
          disabled={disableActions}
        />,
        <GridActionsCellItem
          showInMenu={true}
          label={t('Accepted')}
          onClick={() =>
            onStatusChange(params.row, Offers_Status_Enum.Accepted)
          }
          color="inherit"
          key="update-offer-status-accepted-action"
          disabled={disableActions}
        />,
      ]
    : [];
};

export const OffersTable: React.FC<OffersTableProps> = ({
  rows,
  fromListing = false,
  rowCount,
  isLoading,
  onRowClick,
}) => {
  const { t, locale } = useLocale();
  const { me } = useAppData();
  const [statusChangeSelection, setStatusChangeSelection] = useState<{
    offer: OfferRow;
    status: Offers_Status_Enum.Accepted | Offers_Status_Enum.Refused;
  } | null>(null);
  const navigate = useNavigate();

  const columns: GridColDef<OfferRow>[] = useMemo(
    () => [
      ...(fromListing
        ? []
        : ([
            {
              field: 'lot',
              headerName: t('Listing'),
              width: 250,
              display: 'flex',
              renderCell: ({ row }) =>
                isGlobalOffersRow(row) ? (
                  <ListingCell listing={row.lot} />
                ) : null,
            },
            {
              field: 'lot.sale_price',
              headerName: t('Listing price'),
              width: 100,
              align: 'right',
              display: 'flex',
              renderHeader: () => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                  }}
                >
                  <Typography
                    lineHeight="20px"
                    variant="body2"
                    fontWeight={500}
                  >
                    {t('Price')}
                  </Typography>
                  <Typography lineHeight="20px" variant="caption">
                    {t('Appraisal')}
                  </Typography>
                </Box>
              ),
              renderCell: ({ row }) =>
                isGlobalOffersRow(row) ? (
                  <ListingPriceCell row={row.lot} />
                ) : null,
            },
          ] as GridColDef<OfferRow>[])),
      {
        field: 'amount',
        headerName: t('Offer amount'),
        sortable: fromListing !== true,
        display: 'flex',
        width: 200,
        renderCell: ({ row }) => {
          const amount = row.amount;
          const currency = (row.lot.currency ??
            getCurrencyByCountryCode(
              row.lot.property.country_code ?? 'CH',
            )) as Currency;

          return formatPrice(amount, locale, currency);
        },
      },
      {
        field: 'status',
        headerName: t('Offer status'),
        sortable: false,
        display: 'flex',
        width: 250,
        renderCell: ({ row }) => {
          return (
            <Tag
              status={
                row.status === Offers_Status_Enum.Pending
                  ? 'info'
                  : row.status === Offers_Status_Enum.Accepted
                  ? 'success'
                  : 'error'
              }
            >
              {getOfferStatusLabel(row, t)}
            </Tag>
          );
        },
      },
      {
        field: 'buyer',
        headerName: t('Buyer'),
        display: 'flex',
        width: 280,
        sortable: false,
        renderCell: ({ row }) => <UserCard user={row.buyer} condensed />,
      },
      {
        field: 'valid_until',
        headerName: t('Valid until'),
        sortable: fromListing !== true,
        width: 150,
        renderCell: ({ row }) =>
          row.valid_until != null
            ? toHumanReadableDate(locale, row.valid_until, {
                month: 'numeric',
              })
            : '-',
      },
      {
        field: 'made_on',
        headerName: t('Offer made'),
        sortable: fromListing !== true,
        width: 150,
        renderCell: ({ row }) => (
          <TimeAgo dateString={row.made_on} ignoreTime addSuffix />
        ),
      },
      ...(fromListing
        ? []
        : ([
            {
              field: 'lot.broker',
              headerName: t('Agent'),
              display: 'flex',
              width: 250,
              sortable: false,
              renderCell: ({ row }) =>
                row.lot.broker != null ? (
                  <UserCard user={row.lot.broker} condensed />
                ) : null,
            },
          ] as GridColDef<OfferRow>[])),
      {
        field: 'actions',
        type: 'actions',
        align: 'right',
        flex: 1,
        getActions: params =>
          getOfferRowActions({
            params,
            t,
            currentUser: me,
            disableActions: false,
            onStatusChange: (row, status) => {
              setStatusChangeSelection({ offer: row, status });
            },
          }),
      },
    ],
    [me, locale, t, fromListing],
  );

  return (
    <>
      <RaDataGrid
        sx={{
          borderRadius: 0,
          borderColor: 'transparent',
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
            {
              outline: 'none',
            },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
            {
              outline: 'none',
            },
        }}
        loading={isLoading}
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        rowReordering={false}
        getRowId={row => row.id}
        rowHeight={60}
        disableMultipleRowSelection
        disableRowSelectionOnClick
        disableColumnReorder
        onRowClick={onRowClick}
        paginationMode={fromListing ? 'client' : 'server'}
        hideFooter={fromListing}
        autoHeight={fromListing}
      />
      <ChangeStatusModal
        offer={statusChangeSelection?.offer || null}
        open={statusChangeSelection != null}
        onClose={updateData => {
          setStatusChangeSelection(null);

          if (
            updateData?.status === Offers_Status_Enum.Accepted &&
            updateData.lot != null
          ) {
            navigate({
              pathname: `/listings/${updateData.lot.id}/transaction/new/`,
              search: `?lotId=${toGlobalId('Lot', updateData.lot.id)}`,
            });
          }
        }}
        targetStatus={statusChangeSelection?.status}
      />
    </>
  );
};
