import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import { useMediaQuery } from '@material-ui/core';
import { GpsFixed } from '@mui/icons-material/';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  type SxProps,
  type Theme,
  debounce,
  useTheme,
} from '@mui/material';

import { useLocale } from '../../src/hooks/locale';
import { gql } from '../__generated__';
import {
  type LeadSelectFragment,
  type Leads_Bool_Exp,
  type Leads_Order_By,
  Order_By,
} from '../__generated__/graphql';
import { formatAddress } from '../utils/formatting';

import { RaAutoComplete } from './data-grid/RaAutoComplete';

export const LEAD_SELECT_FRAGMENT = gql(`
  fragment LeadSelect on leads {
    id
    property {
      route
      street_number
      postcode
      locality
    }
    contact {
      id
      first_name
      last_name
    }
  }
`);

const SEARCH_LEADS_TSV = gql(`
  query SearchLeadsTsv($q: String!, $where: leads_bool_exp!, $orderBy: [leads_order_by!]) {
    search_leads_tsv(
      args: { search_text: $q }
      limit: 10
      where: $where
      order_by: $orderBy
    ) {
      id
      ...LeadSelect
    }
  }
`);

const SEARCH_LEADS = gql(`
  query SearchLeads($where: leads_bool_exp!, $orderBy: [leads_order_by!]) {
    leads(
      where: $where
      limit: 10
      order_by: $orderBy
    )  {
      id
      ...LeadSelect
    }
  }
`);

const GET_DEFAULT_LEAD = gql(`
  query GetDefaultLead($id: uuid!) {
    leads_by_pk(id: $id) {
      id
      ...LeadSelect
    }
  }
`);

type LeadSelectProps = {
  leadId?: string;
  onChange?: (lead: LeadSelectFragment | null) => void;
  placeholder?: string;
  sx?: SxProps<Theme>;
  autoFocus?: boolean;
  customWhereClause?: (searchValue?: string) => Leads_Bool_Exp;
  instantQuery?: boolean;
  orderBy?: Leads_Order_By;
  disabled?: boolean;
};

const getContactName = ({
  firstName,
  lastName,
}: {
  firstName?: string | null;
  lastName?: string | null;
}) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return firstName ?? lastName ?? null;
};

export const LeadSelect = ({
  onChange,
  placeholder,
  leadId,
  autoFocus = false,
  sx,
  customWhereClause,
  instantQuery = false,
  orderBy = { created_at: Order_By.Desc },
  disabled,
}: LeadSelectProps) => {
  const { t } = useLocale();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<LeadSelectFragment[]>([]);
  const [selectedValue, setSelectedValue] = useState<LeadSelectFragment | null>(
    null,
  );

  const { data: defaultLead } = useQuery(GET_DEFAULT_LEAD, {
    skip: !leadId,
    variables: { id: leadId ?? '' },
  });

  useEffect(() => {
    if (defaultLead?.leads_by_pk != null) {
      setSelectedValue(defaultLead.leads_by_pk);
    }
  }, [defaultLead]);

  const [searchLeadsTsv, { loading: loadingTsv, error: errorTsv }] =
    useLazyQuery(SEARCH_LEADS_TSV);
  const [searchLeads, { loading: loadingLeads, error: errorLeads }] =
    useLazyQuery(SEARCH_LEADS);

  const handleSearch = useCallback(
    async (value?: string) => {
      if (selectedValue != null) {
        return;
      }

      const shouldSearch = instantQuery || (value != null && value.length >= 3);

      if (!shouldSearch) {
        return;
      }

      const variables = {
        where: {
          completed: { _eq: true },
          ...(customWhereClause ? customWhereClause(value) : {}),
        },
        orderBy,
      };

      if (value && value.length >= 1) {
        const response = await searchLeadsTsv({
          variables: { ...variables, q: value },
        });
        setOpen(true);
        setOptions(response.data?.search_leads_tsv ?? []);
      } else {
        const response = await searchLeads({ variables });
        setOpen(true);
        setOptions(response.data?.leads ?? []);
      }
    },
    [
      instantQuery,
      selectedValue,
      customWhereClause,
      orderBy,
      searchLeadsTsv,
      searchLeads,
    ],
  );

  const debouncedSearch = useMemo(
    () => debounce(handleSearch, 275),
    [handleSearch],
  );

  useEffect(() => {
    if (instantQuery) {
      handleSearch();
    }
  }, [instantQuery, handleSearch]);

  const handleChange = useCallback(
    (_event: any, value: LeadSelectFragment | null) => {
      setSelectedValue(value);
      onChange?.(value);

      if (isMobile && inputRef.current) {
        inputRef.current.blur();
      }
    },
    [onChange, isMobile],
  );

  const renderOption = useCallback(
    (props: any, option: LeadSelectFragment) => (
      <ListItem
        {...props}
        key={option.id}
        sx={{
          padding: '1px 10px',
        }}
      >
        <ListItemAvatar>
          <Avatar>
            <GpsFixed />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
          primary={getContactName({
            firstName: option.contact?.first_name,
            lastName: option.contact?.last_name,
          })}
          secondary={formatAddress(
            {
              street_number: option.property?.street_number,
              route: option.property?.route,
              postcode: option.property?.postcode,
              locality: option.property?.locality,
            },
            ', ',
          )}
        />
      </ListItem>
    ),
    [],
  );

  return (
    <RaAutoComplete<LeadSelectFragment>
      sx={sx}
      value={selectedValue}
      getOptionLabel={option =>
        `${getContactName({
          firstName: option.contact?.first_name,
          lastName: option.contact?.last_name,
        })} ${option.property && formatAddress(option.property, ', ')}`
      }
      open={open}
      autoFocus={autoFocus}
      options={options}
      InputProps={{
        inputRef,
        placeholder: placeholder ?? t('Search leads'),
        onFocus: () => {
          if (!selectedValue) {
            handleSearch();
          }
        },
        onBlur: () => {
          setOpen(false);
          setOptions([]);
        },
      }}
      disabled={disabled}
      loading={loadingTsv || loadingLeads}
      setOpen={setOpen}
      setOptions={setOptions}
      allowEmptySearch={instantQuery}
      debouncedSearch={debouncedSearch}
      renderOption={renderOption}
      onChange={handleChange}
      error={errorTsv != null || errorLeads != null}
    />
  );
};
