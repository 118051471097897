import { useState } from 'react';

import type { ApolloError } from '@apollo/client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Stack,
} from '@mui/material';

import { useLocale } from '../../src/hooks/locale';

interface MutationErrorModalProps {
  error: ApolloError | null;
  onClose: () => void;
}

export const MutationErrorModal = ({
  error,
  onClose,
}: MutationErrorModalProps) => (
  <Dialog
    open={!!error}
    onClose={onClose}
    scroll="paper"
    fullWidth
    maxWidth="md"
  >
    <DialogTitle sx={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
      Error: {error?.message}
    </DialogTitle>
    <DialogContent dividers>
      {error?.graphQLErrors.map((error, i) => (
        <>
          {Array.isArray(error.extensions) &&
            error.extensions.map((ext, j) => (
              <Alert severity="error" key={`${i}-${j}`} sx={{ mb: 1 }}>
                <AlertTitle>{ext.path.join('.')}</AlertTitle>
                {ext.message}
              </Alert>
            ))}
        </>
      ))}
      <Alert severity="error">
        <AlertTitle>{error?.message}</AlertTitle>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
);

export const MutationErrorSnackbar = ({
  error,
  onClose,
}: MutationErrorModalProps) => {
  const { t } = useLocale();
  const [showDetails, setShowDetails] = useState(false);

  return (
    <Snackbar
      open={!!error}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{ width: 600, maxWidth: 'calc(100% - 16px)' }}
    >
      <Alert
        onClose={onClose}
        severity="error"
        sx={{
          width: '100%',
          maxHeight: 'calc(100vh - 16px)',
          overflow: 'auto',
        }}
      >
        <Stack sx={{ height: '100%', alignItems: 'flex-start' }}>
          <AlertTitle>{error?.message ?? t('Error')}</AlertTitle>
          {error?.graphQLErrors
            .map((gqlError, i) =>
              Array.isArray(gqlError.extensions) ? (
                <ul key={i} css={{ marginTop: 8, marginBottom: 8 }}>
                  {gqlError.extensions.map((ext, j) => (
                    <li key={`${i}-${j}`} css={{ mb: 8 }}>
                      <strong>{ext.path.join('.')}</strong>
                      {ext.message}
                    </li>
                  ))}
                </ul>
              ) : null,
            )
            .filter(Boolean)}
          <Button
            size="small"
            variant="text"
            onClick={() => setShowDetails(prev => !prev)}
          >
            {showDetails ? t('Hide details') : t('Show details')}
            <ExpandMoreIcon
              sx={{
                transition: 'transform 0.2s ease-in-out',
                transform: showDetails ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </Button>
          <Box sx={{ flexGrow: 1, minHeight: 0, overflow: 'auto' }}>
            <Collapse in={showDetails}>
              <pre>{JSON.stringify(error, null, 2)}</pre>
            </Collapse>
          </Box>
        </Stack>
      </Alert>
    </Snackbar>
  );
};
