/**
 * @generated SignedSource<<901a1d3180fce0d81c10329ec0a27b5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedEmailForm_lot$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EmailFormDataWrapper_lot">;
  readonly " $fragmentType": "FeedEmailForm_lot";
};
export type FeedEmailForm_lot$key = {
  readonly " $data"?: FeedEmailForm_lot$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedEmailForm_lot">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedEmailForm_lot",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmailFormDataWrapper_lot"
    }
  ],
  "type": "Lot",
  "abstractKey": null
};

(node as any).hash = "20e6500d9879acb0557ac761edb3919a";

export default node;
