import { gql } from '@apollo/client';

const TENANT_SETTINGS_FRAGMENT = gql`
  fragment TenantSettingsFragment on tenants {
    id
    name
    route
    street_number
    postcode
    locality
    state
    country
    google_place_description
    country_code
    lng
    lat
    google_place_id
    default_pipeline_id
  }
`;

export const GET_TENANT_SETTINGS = gql`
  ${TENANT_SETTINGS_FRAGMENT}
  query GetTenantSettings($id: uuid!) {
    tenants_by_pk(id: $id) {
      id
      ...TenantSettingsFragment
    }
  }
`;

export const UPDATE_TENANT_SETTINGS = gql`
  ${TENANT_SETTINGS_FRAGMENT}
  mutation UpdateTenantSettings($id: uuid!, $tenant: tenants_set_input!) {
    update_tenants_by_pk(pk_columns: { id: $id }, _set: $tenant) {
      id
      ...TenantSettingsFragment
    }
  }
`;
