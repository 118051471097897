import * as React from 'react';

import { Accordion, Button, CardContent } from '@material-ui/core';
import { graphql, useFragment } from 'react-relay';
import { Link, useNavigate } from 'react-router-dom';

import { CreateListingModal } from '../../../apollo/components/create-listing/CreateListingModal';
import { fromGlobalId } from '../../../shared/global-id';
import { useLocale } from '../../hooks/locale';
import { PlusCircleOutline } from '../../icons/plus-circle-outline';
import { AccordionCardSummary } from '../../shared/accordion-card-summary';

import type {
  leadLotCard_lead$data,
  leadLotCard_lead$key,
} from './__generated__/leadLotCard_lead.graphql';

type Props = {
  lead: leadLotCard_lead$key;
};

const LotLink = ({
  lot,
}: {
  lot: NonNullable<leadLotCard_lead$data['lot']>;
}) => {
  const { t } = useLocale();
  return (
    <Button
      variant="contained"
      color="primary"
      component={Link}
      to={`/listings/${fromGlobalId(lot.id)}`}
    >
      {t('openLot')}
    </Button>
  );
};

export const LeadLotCard = (props: Props) => {
  const lead = useFragment(
    graphql`
      fragment leadLotCard_lead on Lead {
        id
        lot {
          id
        }
      }
    `,
    props.lead,
  );
  const { t } = useLocale();
  const navigate = useNavigate();
  const [lotDialog, setLotDialog] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Accordion
      defaultExpanded={false}
      onChange={(_event, expanded) => {
        setExpanded(expanded);
      }}
    >
      <AccordionCardSummary
        expanded={expanded}
        title={t('listing')}
        subTitle={lead.lot == null ? t('createLot') : t('openLot')}
      />
      <CardContent>
        {lead.lot == null ? (
          <Button
            variant="contained"
            color="primary"
            startIcon={<PlusCircleOutline />}
            onClick={() => setLotDialog(true)}
          >
            {t('createLot')}
          </Button>
        ) : (
          <LotLink lot={lead.lot} />
        )}
        <CreateListingModal
          fromLeadId={fromGlobalId(lead.id)}
          opened={lotDialog}
          onClose={() => setLotDialog(false)}
          onListingCreated={lotId => navigate(`/listings/${lotId}`)}
        />
      </CardContent>
    </Accordion>
  );
};
