import {
  type FC,
  type MutableRefObject,
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useMutation, useQuery } from '@apollo/client';
import CheckCircle from '@mui/icons-material/CheckCircle';
import {
  Box,
  CircularProgress,
  Stack,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { toGlobalId } from '../../../../shared/global-id';
import { TemplateViewerDialog } from '../../../../src/controls/template-viewer-dialog';
import { useLocale } from '../../../../src/hooks/locale';
import { useCMATemplateViewerDialog } from '../../../../src/routes/Documents/cma-template-viewer-context';
import type { GetCmaReportQuery } from '../../../__generated__/graphql';
import { useGetDefaultApolloTemplate } from '../../../utils/template';
import {
  DOC_TEMPLATES_CMA_REPORT,
  UPDATE_CMA_REPORT_DOC_TEMPLATE,
} from '../cmaReportsQueries';
import {
  type CMAReportComponentProps,
  HorizontalScrollContainer,
  type PageId,
} from '../shared';
import StepHedonisticValuation from '../step-listing-appraise/StepHedonisticValuation';
import StepListingsCompare from '../step-listings-compare/StepListingsCompare';
import StepAppendFiles from '../StepAppendFiles';
import StepCoverPhoto from '../stepCoverPhoto/StepCoverPhoto';
import StepDescription from '../StepDescription';
import StepFinal from '../StepFinal';
import StepIntroduction from '../StepIntroduction';
import StepOtherValuations from '../StepOtherValuations';
import StepPotentialBuyers from '../StepPotentialBuyers';
import StepPropertyDetails from '../StepPropertyDetails';

import type { CMAReportType } from './CMAReportEditorDrawer';
import DesktopLayout from './DesktopLayout';
import InteractiveStepContainer from './InteractiveStepContainer';
import MobileLayout from './MobileLayout';
import ResponsiveActionsLayout from './ResponsiveActionsLayout';

const CmaMobileTabContext = createContext<{
  mobileTab: 'viewer' | 'editor';
  setMobileTab: (value: 'viewer' | 'editor') => void;
}>({
  mobileTab: 'editor',
  setMobileTab: () => {},
});

const CmaMobileTabProvider = ({ children }: { children: ReactNode }) => {
  const [mobileTab, setMobileTab] = useState<'viewer' | 'editor'>('editor');
  const value = useMemo(() => ({ mobileTab, setMobileTab }), [mobileTab]);

  return (
    <CmaMobileTabContext.Provider value={value}>
      {children}
    </CmaMobileTabContext.Provider>
  );
};

export const useCmaMobileTab = () => {
  const context = useContext(CmaMobileTabContext);
  if (!context) {
    throw new Error('useCmaMobileTab must be used within a CMA Reports Layout');
  }

  return context;
};

const useSteps = () => {
  const { t, countryCode } = useLocale();

  return [
    {
      title: t('Cover'),
      Component: StepCoverPhoto,
      pageId: 'page-cover' as const,
    },
    {
      title: t('Introduction'),
      Component: StepIntroduction,
      pageId: 'page-introduction' as const,
    },
    {
      title: t('Property'),
      Component: StepPropertyDetails,
      pageId: 'page-property' as const,
    },
    {
      title: t('Description'),
      Component: StepDescription,
      pageId: 'page-description' as const,
    },
    {
      title: t('AVM'),
      Component: StepHedonisticValuation,
      pageId: 'page-hedonistic-valuation' as const,
    },
    {
      title: t('Comparable'),
      Component: StepListingsCompare,
      pageId: 'page-comparables-list' as const,
    },
    {
      title: t('Other valuations'),
      Component: StepOtherValuations,
      pageId: 'page-other-valuations' as const,
    },
    {
      title: t('Final value'),
      Component: StepFinal,
      pageId: 'page-final' as const,
    },
    ...(countryCode === 'CH'
      ? [
          {
            title: t('Potential buyers'),
            Component: StepPotentialBuyers,
            pageId: 'page-potential-buyers' as const,
          },
        ]
      : []),
    {
      title: t('Append files'),
      Component: StepAppendFiles,
      pageId: 'page-append-files' as const,
    },
  ];
};

const getDefaultStep = (
  steps: { pageId: PageId }[],
  pageId: PageId | undefined,
) => steps.find(step => step.pageId === pageId)?.pageId || steps[0].pageId;

type StepType = {
  title: string;
  Component: FC<CMAReportComponentProps>;
  pageId: PageId;
};

type LayoutProps = {
  cmaReport: CMAReportType | null;
  editor: ReactNode;
  iFrameRef: MutableRefObject<HTMLIFrameElement>;
  stepper: ReactNode;
  viewerPageId: PageId;
};

const CMAReportEditorWorkflow = ({
  data,
}: {
  data: GetCmaReportQuery | undefined;
}) => {
  const { pageId, cmaReportId } = useParams() as {
    cmaReportId: string;
    pageId?: PageId;
  };
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { templateOpen, setTemplateOpen } = useCMATemplateViewerDialog();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const iFrameRef = useRef<HTMLIFrameElement | null>(null);
  const scrollToPageRef = useRef<(pageId: PageId) => void>(() => {});
  const { getDefaultTemplate } = useGetDefaultApolloTemplate();

  const { data: docTemplatesData } = useQuery(DOC_TEMPLATES_CMA_REPORT, {
    skip: data?.cma_reports_by_pk?.doc_template != null,
    fetchPolicy: 'network-only',
  });
  const [updateCmaReport] = useMutation(UPDATE_CMA_REPORT_DOC_TEMPLATE);

  const steps: StepType[] = useSteps();
  const [editorPageId, setEditorPageId] = useState<PageId>(
    getDefaultStep(steps, pageId),
  );
  const [viewerPageId, setViewerPageId] = useState<PageId>(
    getDefaultStep(steps, pageId),
  );

  const currentStepIndex = steps.findIndex(
    step => step.pageId === editorPageId,
  );
  const currentStepData = steps[currentStepIndex];

  const setCurrentStepByIndex = useCallback(
    (index: number) => {
      navigate({
        pathname: `./../${steps[index].pageId}`,
        search: searchParams.toString(),
      });
      setEditorPageId(steps[index].pageId);
      setViewerPageId(steps[index].pageId);
    },
    [navigate, searchParams, steps, setEditorPageId, setViewerPageId],
  );

  const handleScrollToPage = useCallback(
    (pageId: PageId, index: number) => {
      scrollToPageRef.current(pageId);
      setCurrentStepByIndex(index);
    },
    [setCurrentStepByIndex],
  );

  const [isInitializingTemplate, setIsInitializingTemplate] = useState(
    !data?.cma_reports_by_pk?.doc_template,
  );

  // If the doc_template is not set, fetch the default template and update the cma_report
  useEffect(() => {
    const initializeTemplate = async () => {
      try {
        if (data?.cma_reports_by_pk?.doc_template == null && docTemplatesData) {
          const defaultTemplateId = getDefaultTemplate(
            docTemplatesData?.doc_templates,
          )?.id;

          if (defaultTemplateId) {
            await updateCmaReport({
              variables: {
                id: cmaReportId,
                input: {
                  doc_template_id: defaultTemplateId,
                },
              },
              update: (cache, { data: mutationData }) => {
                cache.modify({
                  id: cache.identify({
                    __typename: 'cma_reports',
                    id: cmaReportId,
                  }),
                  fields: {
                    doc_template: () =>
                      mutationData?.update_cma_reports_by_pk?.doc_template,
                  },
                });
                setIsInitializingTemplate(false);
              },
            });
          }
        }
      } catch (error) {
        console.error('Error initializing template:', error);
      }
    };

    initializeTemplate();
  }, [
    cmaReportId,
    data?.cma_reports_by_pk?.doc_template,
    docTemplatesData,
    getDefaultTemplate,
    updateCmaReport,
  ]);

  const stepper = (
    <Stepper
      alternativeLabel
      nonLinear
      activeStep={currentStepIndex}
      component={HorizontalScrollContainer}
      height={isMobile ? 90 : 128}
      nodeIndex={currentStepIndex}
    >
      {steps.map(({ title }, index) => (
        <Step
          key={title}
          completed={currentStepIndex > index}
          sx={{ minWidth: 80 }}
        >
          <StepButton onClick={() => setCurrentStepByIndex(index)}>
            <StepLabel
              StepIconComponent={({ active, completed }) => (
                <Box
                  alignItems="center"
                  display="flex"
                  sx={{
                    color: '#eaeaf0',
                    height: 22,
                  }}
                >
                  {completed ? (
                    <CheckCircle sx={{ color: theme.palette.primary.main }} />
                  ) : (
                    <Box
                      sx={{
                        width: 14,
                        height: 14,
                        borderRadius: '50%',
                        backgroundColor: active
                          ? theme.palette.primary.main
                          : 'currentColor',
                      }}
                    />
                  )}
                </Box>
              )}
            >
              {title}
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );

  const editor = (
    <InteractiveStepContainer
      onInteraction={() => {
        handleScrollToPage(currentStepData.pageId, currentStepIndex);
      }}
    >
      <currentStepData.Component
        ResponsiveActionsLayout={ResponsiveActionsLayout}
        cmaReportId={cmaReportId}
        iFrameRef={iFrameRef as MutableRefObject<HTMLIFrameElement>}
        setStep={setCurrentStepByIndex}
        propertyTypes={data?.property_types ?? []}
        step={currentStepIndex}
        setViewerPageId={setViewerPageId}
      />
    </InteractiveStepContainer>
  );

  const layoutProps: LayoutProps = {
    cmaReport: data?.cma_reports_by_pk ?? null,
    editor,
    iFrameRef: iFrameRef as MutableRefObject<HTMLIFrameElement>,
    stepper,
    viewerPageId,
  };

  if (isInitializingTemplate) {
    return (
      <Stack flexGrow={1} justifyContent="center" alignItems="center" p={3}>
        <CircularProgress disableShrink />
      </Stack>
    );
  }

  return (
    <>
      {data?.cma_reports_by_pk?.doc_template != null && (
        <TemplateViewerDialog
          documentId={toGlobalId('CMAReport', data.cma_reports_by_pk.id)}
          open={templateOpen}
          templateId={toGlobalId(
            'DocTemplate',
            data.cma_reports_by_pk.doc_template.id,
          )}
          onClose={() => setTemplateOpen(false)}
        />
      )}
      {isMobile ? (
        <CmaMobileTabProvider>
          <MobileLayout {...layoutProps} />
        </CmaMobileTabProvider>
      ) : (
        <DesktopLayout {...layoutProps} scrollToPageRef={scrollToPageRef} />
      )}
    </>
  );
};

export default CMAReportEditorWorkflow;
