/**
 * @generated SignedSource<<e7dccea0464862f4dd93ab29ab0ef42c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedEmailForm_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EmailFormDataWrapper_user">;
  readonly " $fragmentType": "FeedEmailForm_user";
};
export type FeedEmailForm_user$key = {
  readonly " $data"?: FeedEmailForm_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedEmailForm_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedEmailForm_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmailFormDataWrapper_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "32edd74a305c44fdc6915af972ef1a84";

export default node;
