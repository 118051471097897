import * as React from 'react';

import { useLazyQuery } from '@apollo/client';
import {
  ArrowDropDown,
  AutoFixHigh,
  SettingsSuggest,
  SmartToy,
  Spellcheck,
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grow,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
} from '@mui/material';
import { Flex } from 'react-system';

import { useLocale } from '../../src/hooks/locale';
import { gql } from '../__generated__';

const GENERATE_DESCRIPTION = gql(`
  query LotGenerateDescription($lot_id: uuid!, $language: String) {
    generate_listings_description(lot_id: $lot_id, language: $language) {
      description
    }
  }
`);

const IMPROVE_DESCRIPTION = gql(`
  query LotImproveDescription($text: String!) {
    improve_text(text: $text) {
      text
    }
  }
`);

type Props = {
  lot_id: string | null | undefined;
  description: string;
  onComplete: (description: string) => void;
};
const LANGUAGES = [
  'English',
  'French',
  'German',
  'Spanish',
  'Italian',
  'Portuguese',
];

export const LotGenerateDescription = (props: Props) => {
  const { lot_id, onComplete, description } = props;
  const [open, setOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [language, setLanguage] = React.useState<string | null>(null);
  const anchorRef = React.useRef<HTMLAnchorElement>(null);

  const { t } = useLocale();

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const [generateDesc, { loading: loadingGenerate }] = useLazyQuery(
    GENERATE_DESCRIPTION,
    {
      variables: {
        lot_id: lot_id ?? '',
        language,
      },
      onCompleted: data => {
        onComplete &&
          onComplete(data?.generate_listings_description?.description ?? '');
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'no-cache',
    },
  );

  const [improveDesc, { loading: loadingImprove }] = useLazyQuery(
    IMPROVE_DESCRIPTION,
    {
      variables: {
        text: description,
      },
      onCompleted: data => {
        onComplete && onComplete(data?.improve_text?.text ?? '');
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'no-cache',
    },
  );

  return (
    <Flex alignSelf="center" ref={anchorRef} mr={2}>
      <LoadingButton
        variant="contained"
        loading={loadingGenerate || loadingImprove}
        loadingPosition="start"
        startIcon={<SmartToy />}
        endIcon={<ArrowDropDown />}
        size="small"
        onClick={handleToggle}
      >
        {'AI'}
      </LoadingButton>

      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList dense autoFocusItem>
                  {lot_id != null && (
                    <MenuItem
                      onClick={() => {
                        generateDesc();
                        setOpen(false);
                      }}
                    >
                      <ListItemIcon>
                        <AutoFixHigh fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>{t('generate')}</ListItemText>
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      improveDesc();
                      setOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <Spellcheck fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('improve')}</ListItemText>
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      setOpen(false);
                      setDialogOpen(true);
                    }}
                  >
                    <ListItemIcon>
                      <SettingsSuggest fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('exportMode')}</ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Dialog
        disableEscapeKeyDown
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>{t('exportMode')}</DialogTitle>
        <DialogContent>
          <FormControl sx={{ minWidth: 220 }}>
            <InputLabel>{t('Language')}</InputLabel>
            <Select
              value={language ?? ''}
              onChange={e => setLanguage(e.target.value)}
            >
              <MenuItem dense value={''}>
                {t('notSet')}
              </MenuItem>
              {LANGUAGES.map(l => (
                <MenuItem key={l} dense value={l}>
                  {l}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>{t('cancel')}</Button>
          <Button
            onClick={() => {
              setDialogOpen(false);
              generateDesc();
            }}
          >
            {t('ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </Flex>
  );
};
