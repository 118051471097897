/**
 * @generated SignedSource<<27def00b7049a69ea91b7f6650238abc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedEmailForm_buyerLead$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EmailFormDataWrapper_buyerLead">;
  readonly " $fragmentType": "FeedEmailForm_buyerLead";
};
export type FeedEmailForm_buyerLead$key = {
  readonly " $data"?: FeedEmailForm_buyerLead$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedEmailForm_buyerLead">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedEmailForm_buyerLead",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmailFormDataWrapper_buyerLead"
    }
  ],
  "type": "BuyerLead",
  "abstractKey": null
};

(node as any).hash = "4299fe41c66edbbb26cc772b50fb517a";

export default node;
