import { gql } from '@apollo/client';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';

import type {
  UserCardDisplayNameFragment,
  UserCard_UserFragment,
} from '../__generated__/graphql';
import { formatPhone } from '../utils/formatting';

import { USER_AVATAR_FRAGMENT, UserAvatar } from './UserAvatar';

export const USER_CARD_DISPLAY_NAME_FRAGMENT = gql`
  fragment UserCardDisplayName on users {
    id
    first_name
    last_name
    full_name
    emails(order_by: { primary: desc, created_at: asc }, limit: 1) {
      id
      email
      primary
    }
  }
`;

export const USER_CARD_FRAGMENT = gql`
  ${USER_AVATAR_FRAGMENT}
  ${USER_CARD_DISPLAY_NAME_FRAGMENT}
  fragment UserCard_user on users {
    id
    ...UserAvatar
    ...UserCardDisplayName
    phone_numbers(order_by: { primary: desc, created_at: asc }) {
      id
      primary
      number
      type
    }
  }
`;

interface UserCardProps {
  user: UserCard_UserFragment;
  condensed?: boolean;
  userActions?: React.ReactNode;
  extraItems?: React.ReactNode;
  secondaryText?: React.ReactNode;
  onSelect?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selected?: boolean;
  forceCheckbox?: boolean;
  alignItems?: 'center' | 'flex-start';
}

export const UserCardSkeleton: React.FC<{
  hasSecondaryText?: boolean;
  condensed?: boolean;
}> = ({ hasSecondaryText = false, condensed = false }) => {
  const size = condensed ? 40 : 70;

  return (
    <List
      dense={condensed}
      sx={[
        { display: 'flex', flexDirection: { xs: 'column', sm: 'row' } },
        condensed ? { p: 0 } : { columnGap: 3 },
      ]}
    >
      <ListItem sx={condensed ? { py: 0 } : {}} disableGutters>
        <ListItemAvatar sx={condensed ? {} : { mr: 1.5 }}>
          <Skeleton variant="circular">
            <Avatar sx={{ width: size, height: size }} />
          </Skeleton>
        </ListItemAvatar>
        <ListItemText
          sx={theme => ({
            '& .MuiTypography-root': theme.mixins.truncate(),
            ...(condensed ? { my: 0 } : {}),
          })}
          primary={
            <Skeleton width="80%">
              <Typography variant={condensed ? 'body1' : 'h6'}>
                Loading
              </Typography>
            </Skeleton>
          }
          secondary={
            hasSecondaryText && (
              <Skeleton width="50%">
                <Typography variant="body2">loading</Typography>
              </Skeleton>
            )
          }
        />
      </ListItem>
    </List>
  );
};

export const getUserDisplayName = (user: UserCardDisplayNameFragment) => {
  const getNameOperations = [
    (user: UserCardDisplayNameFragment) =>
      `${user.first_name ?? ''} ${user.last_name ?? ''}`,
    (user: UserCardDisplayNameFragment) => user.full_name ?? '',
    (user: UserCardDisplayNameFragment) =>
      user.emails[0]?.email.split('@')[0] ?? '',
    (user: UserCardDisplayNameFragment) => user.id,
  ];

  let displayName: string | undefined = '';

  do {
    displayName = getNameOperations.shift()?.(user);
  } while (displayName?.replace(' ', '') === '');

  return displayName ?? '';
};

export const UserCard: React.FC<UserCardProps> = ({
  user,
  condensed = false,
  userActions,
  extraItems,
  secondaryText,
  onSelect,
  forceCheckbox = false,
  selected,
  alignItems,
}) => {
  const primaryPhone =
    user.phone_numbers.find(p => p.primary) ?? user.phone_numbers[0];
  const subTitle = secondaryText ?? formatPhone(primaryPhone?.number ?? null);
  const nameToDisplay = getUserDisplayName(user);

  return (
    <List
      dense={condensed}
      sx={[
        { display: 'flex', flexDirection: { xs: 'column', sm: 'row' } },
        condensed ? { p: 0 } : { columnGap: 3 },
      ]}
    >
      <ListItem
        sx={[
          {
            '& .MuiListItemSecondaryAction-root': {
              transform: 'none',
              position: 'static',
              whiteSpace: 'nowrap',
            },
          },
          condensed ? { py: 0 } : {},
          userActions != null ? { pr: 0 } : {},
        ]}
        disableGutters
        secondaryAction={userActions}
        alignItems={alignItems}
      >
        <ListItemAvatar sx={condensed ? { mt: 0 } : { mr: 1.5 }}>
          <UserAvatar
            user={user}
            size={condensed ? 40 : 70}
            onSelect={onSelect}
            forceCheckbox={forceCheckbox}
            selected={selected}
            nameFallback={nameToDisplay}
          />
        </ListItemAvatar>
        <ListItemText
          sx={theme => ({
            ...(condensed ? { my: 0 } : {}),
            '& .MuiListItemText-secondary': theme.mixins.truncate(),
            '& .MuiListItemText-primary': theme.mixins.truncate(),
          })}
          primary={
            <Typography
              variant="body1"
              sx={condensed ? {} : { fontWeight: 700 }}
            >
              {nameToDisplay}
            </Typography>
          }
          secondary={subTitle}
        />
      </ListItem>
      {extraItems}
    </List>
  );
};
