import { toGlobalId } from '../../../../shared/global-id';
import { useLocale } from '../../../../src/hooks/locale';
import { useTemplateRenderer } from '../../../../src/routes/Documents/template-renderer';

import type { CMAReportType } from './CMAReportEditorDrawer';

const useCMATemplateRenderer = (cmaReport: CMAReportType) => {
  const { countryCode } = useLocale();

  const latestUpdate = Math.max(
    new Date(cmaReport.updated_at).getTime(),
    new Date(cmaReport.lead?.property?.updated_at ?? 0).getTime(),
  );

  const { markup, dataReady, loading } = useTemplateRenderer({
    key: latestUpdate,
    documentId: toGlobalId('CMAReport', cmaReport.id),
    content: cmaReport.doc_template?.content ?? '',
    translations: cmaReport.doc_template?.translations ?? '',
    filenameTemplate: cmaReport.doc_template?.filename_template ?? '',
    countryCode,
    documentType: 'cma_report',
  });

  return { markup, dataReady, loading };
};

export default useCMATemplateRenderer;
