import { useState } from 'react';

import { Alert, AlertTitle, Snackbar } from '@mui/material';

import { type Translate, useLocale } from '../../src/hooks/locale';

type PortalPublicationSnackbarProps = {
  warnings: { message: string; type: string; raw: string; timestamp: number }[];
  onClose: () => void;
  title?: string;
};

const formatRawJson = (raw: string) => {
  try {
    return JSON.stringify(JSON.parse(raw), null, 2);
  } catch (e) {
    return raw;
  }
};

const typeToHumanReadable = (type: string, t: Translate) => {
  switch (type) {
    case 'images':
      return t('Images');
    default:
      return type;
  }
};

export const PortalPublicationSnackbar: React.FC<
  PortalPublicationSnackbarProps
> = ({ warnings, onClose, title }) => {
  const { t } = useLocale();
  const [opened, setOpened] = useState(true);

  return (
    <Snackbar
      open={opened}
      onClose={() => setOpened(false)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={{ width: 550, maxWidth: 'calc(100% - 16px)' }}
      TransitionProps={{
        onExited: onClose,
      }}
    >
      <Alert
        onClose={() => setOpened(false)}
        severity="warning"
        sx={{ width: '100%', alignItems: 'center' }}
      >
        <AlertTitle>
          {title ?? t('Listing published with some warnings')}
        </AlertTitle>
        <ul css={{ paddingLeft: 16, marginBottom: 0 }}>
          {warnings.map(({ raw, message, type }, i) => (
            <li key={i}>
              <strong>
                {typeToHumanReadable(type, t)}: {message}
              </strong>
              <pre css={{ width: '100%', overflow: 'auto' }}>
                {formatRawJson(raw)}
              </pre>
            </li>
          ))}
        </ul>
      </Alert>
    </Snackbar>
  );
};
