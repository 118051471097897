import { useMutation, useQuery } from '@apollo/client';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import {
  Alert,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  Link,
  Route,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { v4 } from 'uuid';

import { useLocale } from '../../../src/hooks/locale';
import { useAppData } from '../../providers/AppDataProvider';
import { PortalDrawer } from '../portals/PortalDrawer';

import { DELETE_TEAM_PORTAL, TEAM_PORTALS } from './teamQueries';

export const TeamPortals = () => {
  const { t } = useLocale();
  const { teamId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { me } = useAppData();

  const { data, error } = useQuery(TEAM_PORTALS, {
    variables: { teamId: teamId ?? '' },
    skip: teamId == null,
  });

  const refetchQueries = [
    {
      query: TEAM_PORTALS,
      variables: { teamId },
    },
  ];

  const [deleteTeamPortal] = useMutation(DELETE_TEAM_PORTAL, {
    refetchQueries,
  });

  return (
    <>
      {error && (
        <Alert severity="error">
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      )}
      <Grid container padding={2} spacing={2}>
        <Grid item flexGrow={1}>
          <Typography variant="h5">{t('Portals owned by team')}</Typography>
        </Grid>
        {me?.is_admin && (
          <Grid item minWidth={150}>
            <Button
              variant="contained"
              component={Link}
              to={v4()}
              disableElevation
            >
              <AddCircleOutlineOutlined sx={{ ml: -1, mr: 0.75 }} />
              {t('Portal')}
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <TableContainer component={Paper} variant="outlined">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Name')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.portals.map(portal => (
                  <TableRow
                    key={portal.id}
                    hover
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      navigate({
                        pathname: portal.id,
                        search: searchParams.toString(),
                      })
                    }
                  >
                    <TableCell>{portal.name}</TableCell>
                  </TableRow>
                ))}
                {data?.portals.length === 0 && (
                  <TableRow>
                    <TableCell>{t('No portals found')}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">{t('Portals shared with team')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper} variant="outlined">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t('Name')}</TableCell>
                  <TableCell width={120}>{t('Listings')}</TableCell>
                  <TableCell align="center" width={80}>
                    {t('Actions')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.teams_portals.map(({ id, portal }) => (
                  <TableRow key={portal.id}>
                    <TableCell>{portal.name}</TableCell>
                    <TableCell>
                      {
                        data?.portal_listings.filter(
                          pl => pl.portal.id === portal.id,
                        ).length
                      }
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        disableElevation
                        color="error"
                        size="small"
                        onClick={() => deleteTeamPortal({ variables: { id } })}
                      >
                        {t('Remove')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                {data?.teams_portals.length === 0 && (
                  <TableRow>
                    <TableCell>{t('No portals found')}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Routes>
        <Route path=":portalId/*" element={<PortalDrawer />} />
      </Routes>
    </>
  );
};
