import { useQuery } from '@apollo/client';
import { Box, LinearProgress, Paper, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';
import {
  GET_AVAILABLE_PORTALS,
  GET_LISTING_DETAILS,
} from '../../pages/listings/lotsQueries';
import { useAppData } from '../../providers/AppDataProvider';
import { ListingPortalsList } from '../listing-overview/ListingPortalsButton';

import { type ListingCreationStepComponent } from './CreateListingStepper';

export const ListingPublicationStep: ListingCreationStepComponent = ({
  footerActionsComponent,
  onFormSubmitted,
  listing,
}) => {
  const { t } = useLocale();
  const { me } = useAppData();
  const { data: portalsData } = useQuery(GET_AVAILABLE_PORTALS, {
    variables: {
      team_ids: me?.teams.map(({ team_id }) => team_id) ?? [],
      lot_id: listing?.id ?? '',
    },
    skip: listing == null,
  });
  const formMethods = useForm();

  if (portalsData == null || listing == null) {
    return <LinearProgress />;
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          px: 2,
          py: 2,
        }}
      >
        <Typography variant="h6" mb={2}>
          {t('Available portals')}
        </Typography>

        <Paper sx={{ px: 2 }}>
          <ListingPortalsList
            listing={listing}
            fullWidth
            allPortals={portalsData.portals}
            refetchQueries={[
              {
                // TODO: Maybe use a simpler query in this component retrieving PortalListingButton fragment instead.
                query: GET_LISTING_DETAILS,
                variables: { id: listing.id },
              },
            ]}
          />
        </Paper>
      </Box>
      <FormProvider {...formMethods}>
        {/* Empty form to match the footer behavior */}
        <form
          onSubmit={() => onFormSubmitted({ action: 'update' }, true, true)}
        >
          {footerActionsComponent}
        </form>
      </FormProvider>
    </Box>
  );
};
