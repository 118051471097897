import { useCallback } from 'react';

import type {
  Doc_Template_Type_Enum_Enum,
  Doc_Templates,
  Doc_Templates_Tenants,
} from '../__generated__/graphql';
import { useAppData } from '../providers/AppDataProvider';

export type ApolloDocTemplate = Pick<
  Doc_Templates,
  'id' | 'name' | 'active'
> & {
  type?: Type;
  doc_templates_tenants: Pick<
    Doc_Templates_Tenants,
    'tenant_id' | 'is_default'
  >[];
};

type Type = Doc_Template_Type_Enum_Enum;

export const useGetDefaultApolloTemplate = () => {
  const { me } = useAppData();
  const tenantId = me?.tenant_id;

  const getDefaultTemplate = useCallback(
    (templates: ApolloDocTemplate[] | null | undefined, type?: Type) => {
      if (!templates?.length) {
        return null;
      }

      const isValidTemplate = (template: ApolloDocTemplate) => {
        const isDefault = template.doc_templates_tenants?.some(
          tenant => tenant?.tenant_id === tenantId && tenant?.is_default,
        );
        const matchesType = !type || template.type === type;
        return template.active && matchesType && isDefault;
      };

      return (
        templates.find(isValidTemplate) ??
        templates.find(t => t.active && (!type || t.type === type)) ??
        null
      );
    },
    [tenantId],
  );

  return { getDefaultTemplate };
};
