import { useMutation, useQuery } from '@apollo/client';
import {
  DeleteForever,
  MapsHomeWorkOutlined,
  PaidOutlined,
  PhotoLibraryOutlined,
} from '@mui/icons-material';
import { Alert } from '@mui/material';
import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import { Drawer } from '../../components/drawer/Drawer';
import {
  type DrawerTab,
  generateTab,
} from '../../components/drawer/DrawerTopBar';
import { PropertyForm } from '../../components/property-form/PropertyForm';
import { ListingPropertyImages } from '../listings/ListingPropertyImages';

import { TransactionForm } from './TransactionForm';
import {
  DELETE_TRANSACTION,
  DRAWER_GET_PROPERTY_TRANSACTION,
} from './transactionQueries';

interface TransactionDrawerProps {
  maxWidth?: number;
}

export const TransactionDrawer = ({ maxWidth }: TransactionDrawerProps) => {
  const { t } = useLocale();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { transactionId } = useParams() as { transactionId: string };

  const { data, error } = useQuery(DRAWER_GET_PROPERTY_TRANSACTION, {
    variables: { id: transactionId },
  });

  const [deleteTransaction] = useMutation(DELETE_TRANSACTION);

  const matchA = useMatch('/v2/transactions/:transactionId/:tabName/*');
  const matchB = useMatch(
    '/teams/:teamId/transactions/:transactionId/:tabName/*',
  );
  const matchC = useMatch(
    '/listings/:lotId/transaction/:transactionId/:tabName/*',
  );

  const tabName =
    matchA?.params.tabName ?? matchB?.params.tabName ?? matchC?.params.tabName;

  const tabsDef: [string, string, React.ReactElement?][] = [
    [t('Transaction'), 'transaction', <PaidOutlined />],
    [t('Property'), 'property', <MapsHomeWorkOutlined />],
    [t('Images'), 'images', <PhotoLibraryOutlined />],
  ];

  const tabs: DrawerTab[] = tabsDef.map(([label, value, icon]) =>
    generateTab([searchParams.toString(), label, value, icon]),
  );

  const handleClose = () =>
    navigate({
      pathname: '../',
      search: searchParams.toString(),
    });

  const drawerActions = [
    {
      label: t('Delete transaction'),
      disabled: false,
      onClick: () => {
        if (
          window.confirm(t('Are you sure you want to delete this transaction?'))
        ) {
          // Delete team and remove from cache
          deleteTransaction({
            variables: {
              id: transactionId,
            },
            update: cache => {
              cache.evict({ id: `property_transactions:${transactionId}` });
              cache.gc();
            },
          }).then(() => {
            // Close drawer
            handleClose();
          });
        }
      },
      icon: <DeleteForever />,
    },
  ];

  return (
    <Drawer
      onClose={handleClose}
      title={`Transaction / ${transactionId}`}
      currentTab={tabName ?? 'transaction'}
      tabs={tabs}
      actions={drawerActions}
      maxWidth={maxWidth}
    >
      {error && (
        <Alert severity="error" sx={{ m: 2 }}>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      )}

      <Routes>
        <Route
          index
          element={
            <Navigate
              to={{
                pathname: 'transaction',
                search: searchParams.toString(),
              }}
            />
          }
        />

        <Route
          path="transaction"
          element={<TransactionForm transactionId={transactionId} />}
        />
        <Route
          path="images/*"
          element={
            <ListingPropertyImages
              propertyId={data?.property_transactions_by_pk?.property_id}
            />
          }
        />
        <Route
          path="property"
          element={
            <PropertyForm
              propertyId={data?.property_transactions_by_pk?.property_id}
            />
          }
        />
      </Routes>
    </Drawer>
  );
};

export default TransactionDrawer;
