import { gql } from '../../__generated__';
import type {
  AddressOverridesListingFragment,
  ListedByOverridesListingFragment,
  Lots_Set_Input,
} from '../../__generated__/graphql';
import type { Optional } from '../../common/types';
import type { RaAddressType } from '../form/RaAddressInput';
import { createCheckboxElement } from '../form/RaForm';

import type { LightFormDefinition } from './models';

export type ListedByOverridesFormData = Pick<
  Lots_Set_Input,
  | 'use_dummy_contact'
  | 'dummy_broker_name'
  | 'dummy_broker_phone'
  | 'dummy_organisation_name'
  | 'dummy_organisation_phone'
> & { dummy_organisation_address: RaAddressType };

export type AddressOverridesFormData = Pick<
  Lots_Set_Input,
  'use_dummy_address' | 'hide_route'
> & { dummy_address: RaAddressType };

export const LISTED_BY_OVERRIDES_LISTING_FRAGMENT = gql(`
  fragment ListedByOverridesListing on lots {
    use_dummy_contact
    dummy_broker_name
    dummy_broker_phone
    dummy_organisation_name
    dummy_organisation_phone
    dummy_organisation_route
    dummy_organisation_street_number
    dummy_organisation_locality
    dummy_organisation_state
    dummy_organisation_country_code
    dummy_organisation_postcode
    dummy_organisation_lng
    dummy_organisation_lat
    broker {
      ...UserCard_user
      default_team {
        id
        name
        route
        street_number
        locality
        state
        country
        postcode
        lat
        lng
        phone_numbers(order_by: { primary: desc, created_at: asc }, limit: 1) {
          id
          primary
          number
          type
        }
      }
    }
  }
`);

export const ADDRESS_OVERRIDES_LISTING_FRAGMENT = gql(`
  fragment AddressOverridesListing on lots {
    use_dummy_address
    hide_route
    dummy_route
    dummy_street_number
    dummy_locality
    dummy_state
    dummy_country_code
    dummy_postcode
    dummy_lng
    dummy_lat
    property {
      id
      street_number
      route
      locality
      postcode
      state
      country
      country_code
      lat
      lng
    }
  }
`);

export const getListedByOverridesDefinition = (
  listing: Optional<ListedByOverridesListingFragment, 'use_dummy_contact'>,
  countryCode: string,
): LightFormDefinition<ListedByOverridesFormData> => ({
  formDefinition: ({ t }) => [
    {
      name: 'use_dummy_contact',
      label: t('useDummyContact'),
      type: 'checkbox',
      style: 'switch',
      gridProps: { md: 12 },
    },
    {
      name: 'dummy_broker_name',
      label: t('brokerName'),
      type: 'text',
      disabled: ({ use_dummy_contact }) => use_dummy_contact !== true,
    },
    {
      name: 'dummy_broker_phone',
      label: t('brokerPhone'),
      type: 'text',
      disabled: ({ use_dummy_contact }) => use_dummy_contact !== true,
    },
    {
      name: 'dummy_organisation_name',
      label: t('organisationName'),
      type: 'text',
      disabled: ({ use_dummy_contact }) => use_dummy_contact !== true,
    },
    {
      name: 'dummy_organisation_phone',
      label: t('organisationPhone'),
      type: 'text',
      disabled: ({ use_dummy_contact }) => use_dummy_contact !== true,
    },
    {
      path: 'dummy_organisation_address',
      label: '',
      type: 'address',
      countryRestriction: countryCode,
      disabled: ({ use_dummy_contact }) => use_dummy_contact !== true,
    },
  ],
  defaultValues: {
    use_dummy_contact: listing.use_dummy_contact ?? false,
    ...(listing.use_dummy_contact
      ? {
          dummy_broker_name: listing.dummy_broker_name,
          dummy_broker_phone: listing.dummy_broker_phone,
          dummy_organisation_name: listing.dummy_organisation_name,
          dummy_organisation_phone: listing.dummy_organisation_phone,
          dummy_organisation_address: {
            route: listing.dummy_organisation_route,
            street_number: listing.dummy_organisation_street_number,
            locality: listing.dummy_organisation_locality,
            state: listing.dummy_organisation_state,
            country_code: listing.dummy_organisation_country_code,
            postcode: listing.dummy_organisation_postcode,
            lng: listing.dummy_organisation_lng,
            lat: listing.dummy_organisation_lat,
          },
        }
      : {
          dummy_broker_name: [
            listing.broker?.first_name,
            listing.broker?.last_name,
          ]
            .filter(Boolean)
            .join(' '),
          dummy_broker_phone: listing.broker?.phone_numbers?.[0]?.number,
          dummy_organisation_name: listing.broker?.default_team?.name,
          dummy_organisation_phone:
            listing.broker?.default_team?.phone_numbers?.[0]?.number,
          dummy_organisation_address: {
            route: listing.broker?.default_team?.route ?? null,
            street_number: listing.broker?.default_team?.street_number ?? null,
            postcode: listing.broker?.default_team?.postcode ?? null,
            locality: listing.broker?.default_team?.locality ?? null,
            state: listing.broker?.default_team?.state ?? null,
            country: listing.broker?.default_team?.country ?? null,
            lat: listing.broker?.default_team?.lat ?? null,
            lng: listing.broker?.default_team?.lng ?? null,
          },
        }),
  },
  hasContext: false,
  transformData: data => {
    const { use_dummy_contact, dummy_organisation_address } = data;

    return {
      lot: {
        use_dummy_contact,
        dummy_broker_name: use_dummy_contact ? data.dummy_broker_name : null,
        dummy_broker_phone: use_dummy_contact ? data.dummy_broker_phone : null,
        dummy_organisation_name: use_dummy_contact
          ? data.dummy_organisation_name
          : null,
        dummy_organisation_phone: use_dummy_contact
          ? data.dummy_organisation_phone
          : null,
        dummy_organisation_route: use_dummy_contact
          ? dummy_organisation_address.route
          : null,
        dummy_organisation_street_number: use_dummy_contact
          ? dummy_organisation_address.street_number
          : null,
        dummy_organisation_locality: use_dummy_contact
          ? dummy_organisation_address.locality
          : null,
        dummy_organisation_state: use_dummy_contact
          ? dummy_organisation_address.state
          : null,
        dummy_organisation_country_code: use_dummy_contact
          ? dummy_organisation_address.country_code
          : null,
        dummy_organisation_postcode: use_dummy_contact
          ? dummy_organisation_address.postcode
          : null,
        dummy_organisation_lng: use_dummy_contact
          ? dummy_organisation_address.lng
          : null,
        dummy_organisation_lat: use_dummy_contact
          ? dummy_organisation_address.lat
          : null,
      },
    };
  },
});

export const getAddressOverridesDefinition = (
  listing: AddressOverridesListingFragment,
  countryCode: string,
): LightFormDefinition<AddressOverridesFormData> => ({
  formDefinition: ({ t }) => [
    createCheckboxElement('use_dummy_address', t('useDummyAddress'), {
      style: 'switch',
    }),
    createCheckboxElement('hide_route', t('hideRoute'), {
      style: 'switch',
    }),
    {
      label: t('Address'),
      path: 'dummy_address',
      type: 'address',
      countryRestriction: countryCode,
      gridProps: { xs: 12 },
      render: ({ use_dummy_address }) => use_dummy_address === true,
    },
  ],
  defaultValues: {
    use_dummy_address: listing.use_dummy_address,
    hide_route: listing.hide_route,
    dummy_address: {
      ...(listing.use_dummy_address
        ? {
            route: listing.dummy_route,
            street_number: listing.dummy_street_number,
            locality: listing.dummy_locality,
            state: listing.dummy_state,
            country_code: listing.dummy_country_code,
            postcode: listing.dummy_postcode,
            lng: listing.dummy_lng,
            lat: listing.dummy_lat,
          }
        : {
            street_number: listing.property.street_number,
            route: listing.property.route,
            locality: listing.property.locality,
            postcode: listing.property.postcode,
            state: listing.property.state,
            country: listing.property.country,
            country_code: listing.property.country_code,
            lat: listing.property.lat,
            lng: listing.property.lng,
          }),
    },
  },
  hasContext: false,
  transformData: ({ use_dummy_address, hide_route, dummy_address }) => ({
    lot: {
      use_dummy_address,
      hide_route,
      dummy_route: use_dummy_address ? dummy_address.route : null,
      dummy_street_number: use_dummy_address
        ? dummy_address.street_number
        : null,
      dummy_locality: use_dummy_address ? dummy_address.locality : null,
      dummy_state: use_dummy_address ? dummy_address.state : null,
      dummy_country_code: use_dummy_address ? dummy_address.country_code : null,
      dummy_postcode: use_dummy_address ? dummy_address.postcode : null,
      dummy_lng: use_dummy_address ? dummy_address.lng : null,
      dummy_lat: use_dummy_address ? dummy_address.lat : null,
    },
  }),
});
