import * as React from 'react';

import { IconButton, MenuItem } from '@material-ui/core';
import { graphql, useFragment, useMutation } from 'react-relay';
import { useLocation, useNavigate } from 'react-router-dom';

import { CreateListingModal } from '../../../apollo/components/create-listing/CreateListingModal';
import { fromGlobalId } from '../../../shared/global-id';
import { Menu } from '../../controls/popup';
import { useLocale } from '../../hooks/locale';
import { MoreVert } from '../../icons/more-vert';
import { LeadDeleteDialog } from '../../shared/lead-delete-dialog';
import { LeadLostDialog } from '../../shared/lead-lost-dialog';

import type { leadControls_lead$key } from './__generated__/leadControls_lead.graphql';
import type { leadControls_root$key } from './__generated__/leadControls_root.graphql';
import type { leadControlsCreateActivityMutation } from './__generated__/leadControlsCreateActivityMutation.graphql';
import type { leadControlsUpdateLeadMutation } from './__generated__/leadControlsUpdateLeadMutation.graphql';

type Props = {
  root: leadControls_root$key;
  lead: leadControls_lead$key;
  onActivityCreate: () => void;
  onDelete?: () => void;
};

export const LeadControls = (props: Props) => {
  const root = useFragment(
    graphql`
      fragment leadControls_root on Query {
        me {
          isAdmin
          modules
        }
        # this should not be hardcoded
        # if we move mortgages back to the leads table
        pipelines(name: "brokerage") {
          stages {
            id
            name
          }
        }
      }
    `,
    props.root,
  );
  const lead = useFragment(
    graphql`
      fragment leadControls_lead on Lead {
        id
        ...leadDeleteDialog_lead
        completed
        status
        stage {
          id
        }
        lot {
          id
        }
      }
    `,
    props.lead,
  );
  const [updateLead] = useMutation<leadControlsUpdateLeadMutation>(
    graphql`
      mutation leadControlsUpdateLeadMutation($input: UpsertLeadInput!) {
        upsertLead(input: $input) {
          lead {
            ...leadControls_lead
            ...leadPipelineCard_lead
          }
        }
      }
    `,
  );
  const [upsertActivity] = useMutation<leadControlsCreateActivityMutation>(
    graphql`
      mutation leadControlsCreateActivityMutation(
        $input: UpsertActivityInput!
      ) {
        upsertActivity(input: $input) {
          activity {
            id
          }
        }
      }
    `,
  );

  const { t } = useLocale();
  const navigate = useNavigate();
  const [menu, setMenu] = React.useState(false);
  const menuRef = React.useRef(null);
  const [mandateDialog, setMandateDialog] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [lostDialog, setLostDialog] = React.useState(false);
  const { me } = root;
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const onDeleteHandler = () => {
    searchParams.delete('leadId');
    navigate({
      pathname: `/leads`,
      search: `?${searchParams.toString()}`,
    });
    props.onDelete?.();
  };

  return (
    <>
      <IconButton
        ref={menuRef}
        title={t('leadActions')}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setMenu(v => !v);
        }}
        size="small"
      >
        <MoreVert />
      </IconButton>
      <Menu referenceRef={menuRef} open={menu} onClose={() => setMenu(false)}>
        {lead.completed === false && me?.isAdmin && (
          <MenuItem
            onClick={() => {
              const assignStage = (root.pipelines[0].stages || []).find(
                stage => stage.name === 'assign',
              );
              updateLead({
                variables: {
                  input: {
                    lead: {
                      id: lead.id,
                      completed: true,
                      stageId: lead.stage?.id ?? assignStage?.id,
                    },
                  },
                },
              });
            }}
          >
            {t('markAsCompleted')}
          </MenuItem>
        )}

        {lead.status !== 'active' && (
          <MenuItem
            onClick={() => {
              updateLead({
                variables: {
                  input: {
                    lead: { id: lead.id, status: 'active' },
                  },
                },
              });
            }}
          >
            {t('markAsActive')}
          </MenuItem>
        )}
        {(lead.status === 'active' || lead.status === 'lost') && (
          <MenuItem
            onClick={() => {
              if (me?.modules.includes('brokerage') && lead.lot == null) {
                setMandateDialog(true);
              } else {
                updateLead({
                  variables: {
                    input: {
                      lead: { id: lead.id, status: 'won' },
                    },
                  },
                });
              }
            }}
          >
            {t('markAsWon')}
          </MenuItem>
        )}
        {(lead.status === 'active' || lead.status === 'won') && (
          <MenuItem onClick={() => setLostDialog(true)}>
            {t('markAsLost')}
          </MenuItem>
        )}
        {root.me?.isAdmin === true && (
          <MenuItem onClick={() => setDeleteDialog(true)}>
            {t('deleteLead')}
          </MenuItem>
        )}
      </Menu>

      {me?.modules.includes('brokerage') && lead.lot == null && (
        <CreateListingModal
          fromLeadId={fromGlobalId(lead.id)}
          opened={mandateDialog}
          onClose={() => setMandateDialog(false)}
          onListingCreated={lotId => {
            updateLead({
              variables: {
                input: {
                  lead: { id: lead.id, status: 'won' },
                },
              },
              onCompleted: () => {
                setMandateDialog(false);
                navigate(`/listings/${lotId}`);
              },
            });
          }}
        />
      )}
      <LeadLostDialog
        open={lostDialog}
        title={t('whyIsThisLeadDead')}
        onConfirm={(id, note) => {
          setLostDialog(false);
          updateLead({
            variables: {
              input: {
                lead: { id: lead.id, status: 'lost', leadLostId: id },
              },
            },
          });

          upsertActivity({
            variables: {
              input: {
                activity: {
                  parentId: lead.id,
                  activityType: 'note',
                  note,
                  success: false,
                  done: true,
                  doneAt: new Date().toISOString(),
                },
              },
            },
            onCompleted: props.onActivityCreate,
          });
        }}
        onClose={() => setLostDialog(false)}
      />

      {root.me?.isAdmin === true && (
        <LeadDeleteDialog
          lead={lead}
          open={deleteDialog}
          onClose={() => setDeleteDialog(false)}
          onDelete={() => onDeleteHandler()}
        />
      )}
    </>
  );
};
