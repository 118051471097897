import { gql } from '@apollo/client';
import { HomeWorkOutlined } from '@mui/icons-material';
import { Image } from '@realadvisor/image';

import { useTheme } from '../../../src/hooks/theme';
import type { PropertyCell_PropertyFragment } from '../../__generated__/graphql';
import { formatAddress } from '../../utils/formatting';

export const PROPERTY_CELL_LISTING_FRAGMENT = gql`
  fragment PropertyCell_property on properties {
    id
    route
    street_number
    postcode
    locality
    country_code
    property_images(order_by: { order_nr: asc }, limit: 1) {
      id
      order_nr
      image {
        id
        url
      }
    }
  }
`;

interface PropertyCellProps {
  property: PropertyCell_PropertyFragment;
  image_url?: string | null;
}

export const PropertyCell = ({ image_url, property }: PropertyCellProps) => {
  const { text, colors } = useTheme();

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'max-content 1fr',
        alignItems: 'center',
        gap: 8,
        height: '100%',
      }}
    >
      <div
        style={{
          height: '100%',
          width: 50,
          backgroundColor: colors.grey200,
          overflow: 'hidden',
          display: 'flex',
          flex: 1,
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {image_url != null || property?.property_images.length > 0 ? (
          <Image
            src={image_url ?? property?.property_images[0]?.image.url ?? ''}
            options={{ w: 256, f: 'jpg' }}
          />
        ) : (
          <HomeWorkOutlined
            style={{
              fontSize: 24,
              color: colors.grey400,
            }}
          />
        )}
      </div>

      <div style={{ minWidth: 0 }}>
        {property &&
          formatAddress(property, null).map((line, index) => (
            <div
              key={index}
              style={{
                ...text.caption,
                ...text.truncate,
                color: colors.mediumText,
              }}
            >
              {line}
            </div>
          ))}
      </div>
    </div>
  );
};
