import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Grid,
  type GridProps,
  Paper,
  Snackbar,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { de, enGB, es, fr, it } from 'date-fns/locale';
import isEqual from 'lodash.isequal';
import {
  Controller,
  type DeepPartial,
  type FieldPath,
  type FieldValue,
  FormProvider,
  type Path,
  type PathValue,
  type Primitive,
  type UseFormReturn,
  type UseFormSetError,
  useForm,
} from 'react-hook-form';

import { MarkdownComposer } from '../../../shared/composer';
import type { LotSnippetData } from '../../../src/components/EmailForm/RichEditor';
import { type Translate, useLocale } from '../../../src/hooks/locale';
import {
  type Leads_Bool_Exp,
  type Leads_Order_By,
  type UserSelectFragment,
  type Users_Bool_Exp,
} from '../../__generated__/graphql';
import { getKeys } from '../../utils/objects';
import {
  type IFormError,
  extractMessageFromError,
} from '../../utils/parseError';
import type { AutocompleteMultiProps } from '../AutocompleteMulti';
import type { RaAutoCompleteCreatableItem } from '../data-grid/RaAutoComplete';

import { RaAddressInput } from './RaAddressInput';
import { RaCheckbox } from './RaCheckbox';
import { RaCheckboxGroup } from './RaCheckboxGroup';
import { RaColorPicker } from './RaColorPicker';
import { RaDate } from './RaDate';
import type { RaDictionaryProps } from './RaDictionary';
import { RaDictionary } from './RaDictionary';
import { type ImageUploadResult, RaImageUpload } from './RaImageUpload';
import { RaLead } from './RaLead';
import { RaLot } from './RaLot';
import { RaNumber } from './RaNumber';
import { RaOrganisation } from './RaOrganisation';
import { RaPhoneNumber } from './RaPhoneNumber';
import { RaRichEditorSuggestions } from './RaRichEditorSuggestions';
import { RaSelect } from './RaSelect';
import { RaTags } from './RaTags';
import { RaTeam } from './RaTeam';
import { RaTextField } from './RaTextfield';
import { RaUser } from './RaUser';

export interface BaseFieldDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> {
  type:
    | 'text'
    | 'date'
    | 'select'
    | 'checkbox'
    | 'checkbox-group'
    | 'category-title'
    | 'address'
    | 'organisation'
    | 'team'
    | 'lot'
    | 'color-picker'
    | 'lead'
    | 'user'
    | 'number'
    | 'dictionary'
    | 'rich-text'
    | 'custom'
    | 'phone_number'
    | 'tags'
    | 'rich-editor-suggestions'
    | 'image';
  name: FieldPath<TFormData>;
  label?: string;
  required?: boolean;
  disabled?: (data: TFormData) => boolean;
  gridProps?: GridProps;
  render?: (data: TFormData) => boolean;
  helpText?: string | ((data: TFormData) => string);
}

export interface SelectFieldDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> extends BaseFieldDefinition<TFormData> {
  type: 'select';
  multiple?: boolean;
  renderValue?: (value: any) => React.ReactNode;
  options: (data: TFormData) => {
    value: any;
    label: string;
    disabled?: boolean;
  }[];
  label: string;
}

export interface TextFieldDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> extends BaseFieldDefinition<TFormData> {
  type: 'text';
  multiline?: boolean;
  rows?: number;
  suffix?: string;
  prefix?: string;
  label: string;
}

export interface NumberFieldDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> extends BaseFieldDefinition<TFormData> {
  type: 'number';
  min?: number;
  max?: number;
  decimalNumbers?: number;
  suffix?: string;
  prefix?: string;
  disableFormatting?: boolean;
  label: string;
}

export interface PhoneFieldDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> extends BaseFieldDefinition<TFormData> {
  type: 'phone_number';
  label: string;
}

export interface DatePickerDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> extends BaseFieldDefinition<TFormData> {
  type: 'date';
  label: string;
}

export interface CheckboxFieldDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> extends BaseFieldDefinition<TFormData> {
  type: 'checkbox';
  style?: 'switch' | 'checkbox';
  label: string;
}

export interface CheckboxGroupFieldDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> extends Omit<BaseFieldDefinition<TFormData>, 'name'> {
  name: string;
  type: 'checkbox-group';
  checkboxes: CheckboxFieldDefinition<TFormData>[];
  label: string;
}

export interface ColorPickerFieldDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> extends BaseFieldDefinition<TFormData> {
  type: 'color-picker';
  label: string;
}

export interface CategoryTitleDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> extends Omit<BaseFieldDefinition<TFormData>, 'name'> {
  name: string;
  type: 'category-title';
  label: string;
}

export type AddressFieldDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> = Omit<BaseFieldDefinition<TFormData>, 'name'> & {
  type: 'address';
  path?: FieldPath<TFormData>;
  includeGoogleFields?: boolean;
  countryRestriction?: string;
  requiredFields?: (data: TFormData) => {
    street_number?: boolean;
    country_code?: boolean;
    country?: boolean;
  };
};

export interface OrganisationFieldDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> extends BaseFieldDefinition<TFormData> {
  type: 'organisation';
  label: string;
}

export interface TeamFieldDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> extends BaseFieldDefinition<TFormData> {
  type: 'team';
  label: string;
}

export interface LotFieldDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> extends BaseFieldDefinition<TFormData> {
  type: 'lot';
  label: string;
}

export interface LeadFieldDefinitionBase<
  TFormData extends Record<string, any> = Record<string, any>,
> extends BaseFieldDefinition<TFormData> {
  type: 'lead';
  label: string;
  placeholder?: string;
  autoFocus?: boolean;
  customWhereClause?: (searchValue?: string) => Leads_Bool_Exp;
  orderBy?: Leads_Order_By;
}

export type LeadFieldDefinitionWithTsv<
  TFormData extends Record<string, any> = Record<string, any>,
> = LeadFieldDefinitionBase<TFormData> & {
  useSearchTsv: true;
  instantQuery?: never;
};

export type LeadFieldDefinitionWithoutTsv<
  TFormData extends Record<string, any> = Record<string, any>,
> = LeadFieldDefinitionBase<TFormData> & {
  useSearchTsv?: false;
  instantQuery?: boolean;
};

export type LeadFieldDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> =
  | LeadFieldDefinitionWithTsv<TFormData>
  | LeadFieldDefinitionWithoutTsv<TFormData>;

export interface UserFieldDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> extends BaseFieldDefinition<TFormData> {
  type: 'user';
  label: string;
  filters?: Users_Bool_Exp;
  createUserSelected?: (user: {
    first_name: string | null;
    last_name: string | null;
    email: string | null;
  }) => Promise<UserSelectFragment> | UserSelectFragment | void;
}

export interface DictionaryFieldDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> extends BaseFieldDefinition<TFormData> {
  type: 'dictionary';
  dictionaryType: RaDictionaryProps['dictionaryType'];
  multiple?: boolean;
  label: string;
  valueField?: 'id' | 'name';
}

export interface RichTextFieldDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> extends BaseFieldDefinition<TFormData> {
  type: 'rich-text';
  label: string;
  resetKey?: number;
  actions?:
    | React.ReactNode
    | ((
        formMethods: Pick<
          UseFormReturn<TFormData>,
          'setValue' | 'getValues' | 'reset'
        >,
      ) => React.ReactNode);
}

export interface RichEditorSuggestionsFieldDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> extends BaseFieldDefinition<TFormData> {
  type: 'rich-editor-suggestions';
  label: string;
  singleLine?: boolean;
  suggestions?: { name: string; id: string }[];
  resetKey?: string;
  listing?: LotSnippetData;
}

export interface CustomElementDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> extends Omit<BaseFieldDefinition<TFormData>, 'name'> {
  name: string;
  type: 'custom';
  element: React.ReactNode;
  label?: never;
}

interface BaseTagsElementDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
  TFieldValue extends FieldValue<TFormData> = any,
> extends BaseFieldDefinition<TFormData>,
    Pick<
      AutocompleteMultiProps<TFieldValue>,
      'InputProps' | 'getOptionLabel' | 'isOptionEqualToValue' | 'renderTags'
    > {
  type: 'tags';
  onFilter: (inputStr: string) => Promise<TFieldValue[]> | TFieldValue[];
  label: string;
}

interface CreatableTagsElementDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
  TFieldValue extends FieldValue<TFormData> = any,
> extends BaseTagsElementDefinition<TFormData, TFieldValue> {
  onNewTagSelected: (tag: string) => Promise<TFieldValue> | TFieldValue;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: TFieldValue | RaAutoCompleteCreatableItem<TFieldValue>,
  ) => React.ReactNode;
}

interface NonCreatableTagsElementDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
  TFieldValue extends FieldValue<TFormData> = any,
> extends BaseTagsElementDefinition<TFormData, TFieldValue> {
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: TFieldValue,
  ) => React.ReactNode;
}

export type TagsElementDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
  TFieldValue extends FieldValue<TFormData> = any,
> =
  | CreatableTagsElementDefinition<TFormData, TFieldValue>
  | NonCreatableTagsElementDefinition<TFormData, TFieldValue>;

export interface ImageElementDefinition<
  TFormData extends Record<string, any> = Record<string, any>,
> extends BaseFieldDefinition<TFormData> {
  type: 'image';
  onFileUpload: (file: ImageUploadResult) => Promise<string | null>;
}

const defaultGridProps = {
  xs: 12,
  md: 6,
};

export const defaultFormElement: Pick<
  BaseFieldDefinition,
  'type' | 'gridProps' | 'render'
> = {
  type: 'text',
  gridProps: {
    ...defaultGridProps,
  },
  render: () => true,
};

type BaseElementOptions<
  TFormData extends Record<string, any> = Record<string, any>,
> = Pick<
  BaseFieldDefinition<TFormData>,
  'render' | 'required' | 'disabled' | 'gridProps' | 'helpText'
>;

type CreateElementParams<
  TFormData extends Record<string, any> = Record<string, any>,
  TExtraOptions extends object = {},
> = [
  name: FieldPath<TFormData>,
  label: string,
  options?: BaseElementOptions<TFormData> & TExtraOptions,
];

const createBaseElement = <
  TFormData extends Record<string, any> = Record<string, any>,
>(
  ...args: CreateElementParams<TFormData>
): BaseFieldDefinition<TFormData> & { label: string } => {
  const [name, label, options] = args;

  return {
    ...defaultFormElement,
    name,
    label,
    ...(options ?? {}),
  };
};

function createTextFieldElementFn<
  TFormData extends Record<string, any> = Record<string, any>,
>(
  name: FieldPath<TFormData>,
  label: string,
  render?: (data: TFormData) => boolean,
): TextFieldDefinition<TFormData>;
function createTextFieldElementFn<
  TFormData extends Record<string, any> = Record<string, any>,
>(
  ...args: CreateElementParams<
    TFormData,
    Pick<TextFieldDefinition, 'multiline' | 'suffix' | 'prefix'>
  >
): TextFieldDefinition<TFormData>;
function createTextFieldElementFn<
  TFormData extends Record<string, any> = Record<string, any>,
>(
  name: FieldPath<TFormData>,
  label: string,
  renderOrOpts?:
    | ((data: TFormData) => boolean)
    | (BaseElementOptions<TFormData> &
        Pick<TextFieldDefinition, 'multiline' | 'suffix' | 'prefix'>),
): TextFieldDefinition<TFormData> {
  return {
    ...createBaseElement(
      name,
      label,
      typeof renderOrOpts === 'function'
        ? { render: renderOrOpts }
        : renderOrOpts,
    ),
    type: 'text',
  };
}

export const createTextFieldElement = createTextFieldElementFn;

function createNumberFieldElementFn<
  TFormData extends Record<string, any> = Record<string, any>,
>(
  name: FieldPath<TFormData>,
  label: string,
  render?: (data: TFormData) => boolean,
): NumberFieldDefinition<TFormData>;
function createNumberFieldElementFn<
  TFormData extends Record<string, any> = Record<string, any>,
>(
  ...args: CreateElementParams<
    TFormData,
    Pick<
      NumberFieldDefinition,
      | 'min'
      | 'max'
      | 'decimalNumbers'
      | 'suffix'
      | 'prefix'
      | 'disableFormatting'
    >
  >
): NumberFieldDefinition<TFormData>;
function createNumberFieldElementFn<
  TFormData extends Record<string, any> = Record<string, any>,
>(
  name: FieldPath<TFormData>,
  label: string,
  renderOrOpts?:
    | ((data: TFormData) => boolean)
    | (BaseElementOptions<TFormData> &
        Pick<
          NumberFieldDefinition,
          | 'min'
          | 'max'
          | 'decimalNumbers'
          | 'suffix'
          | 'prefix'
          | 'disableFormatting'
        >),
): NumberFieldDefinition<TFormData> {
  return {
    ...createBaseElement(
      name,
      label,
      typeof renderOrOpts === 'function'
        ? { render: renderOrOpts }
        : renderOrOpts,
    ),
    type: 'number',
  };
}

export const createNumberFieldElement = createNumberFieldElementFn;

function createSelectElementFn<
  TFormData extends Record<string, any> = Record<string, any>,
>(
  name: FieldPath<TFormData>,
  label: string,
  selectOptions: SelectFieldDefinition<TFormData>['options'],
  render?: (data: TFormData) => boolean,
): SelectFieldDefinition<TFormData>;

function createSelectElementFn<
  TFormData extends Record<string, any> = Record<string, any>,
>(
  name: FieldPath<TFormData>,
  label: string,
  selectOptions: SelectFieldDefinition<TFormData>['options'],
  options?: BaseElementOptions<TFormData>,
  multiple?: boolean,
  renderValue?: (value: any) => React.ReactNode,
): SelectFieldDefinition<TFormData>;

function createSelectElementFn<
  TFormData extends Record<string, any> = Record<string, any>,
>(
  name: FieldPath<TFormData>,
  label: string,
  selectOptions: SelectFieldDefinition['options'],
  renderOrOpts?: ((data: TFormData) => boolean) | BaseElementOptions<TFormData>,
  multiple = false,
  renderValue?: (value: any) => React.ReactNode,
): SelectFieldDefinition<TFormData> {
  return {
    ...createBaseElement(
      name,
      label,
      typeof renderOrOpts === 'function'
        ? { render: renderOrOpts }
        : renderOrOpts,
    ),
    type: 'select',
    options: selectOptions,
    multiple,
    renderValue,
  };
}

export const createSelectElement = createSelectElementFn;

function createCheckboxElementFn<
  TFormData extends Record<string, any> = Record<string, any>,
>(
  name: FieldPath<TFormData>,
  label: string,
  render?: (data: TFormData) => boolean,
): CheckboxFieldDefinition<TFormData>;
function createCheckboxElementFn<
  TFormData extends Record<string, any> = Record<string, any>,
>(
  ...args: CreateElementParams<
    TFormData,
    Pick<CheckboxFieldDefinition, 'style'>
  >
): CheckboxFieldDefinition<TFormData>;
function createCheckboxElementFn<
  TFormData extends Record<string, any> = Record<string, any>,
>(
  name: FieldPath<TFormData>,
  label: string,
  renderOrOpts?:
    | ((data: TFormData) => boolean)
    | (BaseElementOptions<TFormData> & Pick<CheckboxFieldDefinition, 'style'>),
): CheckboxFieldDefinition<TFormData> {
  return {
    ...createBaseElement(
      name,
      label,
      typeof renderOrOpts === 'function'
        ? { render: renderOrOpts }
        : renderOrOpts,
    ),
    type: 'checkbox',
  };
}

export const createCheckboxElement = createCheckboxElementFn;

function createCheckboxGroupElementFn<
  TFormData extends Record<string, any> = Record<string, any>,
>(
  name: string,
  label: string,
  checkboxes: CheckboxGroupFieldDefinition<TFormData>['checkboxes'],
  render?: (data: TFormData) => boolean,
): CheckboxGroupFieldDefinition<TFormData>;
function createCheckboxGroupElementFn<
  TFormData extends Record<string, any> = Record<string, any>,
>(
  name: string,
  label: string,
  checkboxes: CheckboxGroupFieldDefinition<TFormData>['checkboxes'],
  options?: BaseElementOptions<TFormData>,
): CheckboxGroupFieldDefinition<TFormData>;
function createCheckboxGroupElementFn<
  TFormData extends Record<string, any> = Record<string, any>,
>(
  name: string,
  label: string,
  checkboxes: CheckboxGroupFieldDefinition<TFormData>['checkboxes'],
  renderOrOpts?: ((data: TFormData) => boolean) | BaseElementOptions<TFormData>,
): CheckboxGroupFieldDefinition<TFormData> {
  return {
    ...defaultFormElement,
    name,
    label,
    ...((typeof renderOrOpts === 'function'
      ? { render: renderOrOpts }
      : renderOrOpts) ?? {}),
    type: 'checkbox-group',
    checkboxes,
  };
}
export const createCheckboxGroupElement = createCheckboxGroupElementFn;

function createTagsElementFn<
  TFormData extends Record<string, any>,
  TFieldValue extends FieldValue<TFormData>,
>(
  name: FieldPath<TFormData>,
  label: string,
  onFilter: (inputStr: string) => Promise<TFieldValue[]> | TFieldValue[],
  options?: BaseElementOptions<TFormData> &
    Pick<
      NonCreatableTagsElementDefinition<TFormData, TFieldValue>,
      | 'InputProps'
      | 'getOptionLabel'
      | 'isOptionEqualToValue'
      | 'renderTags'
      | 'renderOption'
    >,
): NonCreatableTagsElementDefinition<TFormData, TFieldValue>;
function createTagsElementFn<
  TFormData extends Record<string, any>,
  TFieldValue extends FieldValue<TFormData>,
>(
  name: FieldPath<TFormData>,
  label: string,
  onFilter: (inputStr: string) => Promise<TFieldValue[]> | TFieldValue[],
  onNewTagSelected: (tag: string) => Promise<TFieldValue> | TFieldValue,
  options?: CreatableTagsElementDefinition<TFormData> &
    Pick<
      TagsElementDefinition<TFormData, TFieldValue>,
      | 'InputProps'
      | 'getOptionLabel'
      | 'isOptionEqualToValue'
      | 'renderTags'
      | 'renderOption'
    >,
): CreatableTagsElementDefinition<TFormData, TFieldValue>;
function createTagsElementFn<
  TFormData extends Record<string, any>,
  TFieldValue extends FieldValue<TFormData>,
>(
  name: FieldPath<TFormData>,
  label: string,
  onFilter: (inputStr: string) => Promise<TFieldValue[]> | TFieldValue[],
  onNewOrOpts?:
    | ((tag: string) => Promise<TFieldValue> | TFieldValue)
    | (BaseElementOptions<TFormData> &
        Pick<
          TagsElementDefinition<TFormData, TFieldValue>,
          | 'InputProps'
          | 'getOptionLabel'
          | 'isOptionEqualToValue'
          | 'renderTags'
        >),
  options?: BaseElementOptions<TFormData> &
    Pick<
      TagsElementDefinition<TFormData, TFieldValue>,
      'InputProps' | 'getOptionLabel' | 'isOptionEqualToValue' | 'renderTags'
    >,
): TagsElementDefinition<TFormData, TFieldValue> {
  const opts = typeof onNewOrOpts === 'object' ? onNewOrOpts : options;

  const element = {
    ...createBaseElement(name, label, opts),
    onFilter,
    type: 'tags',
  };

  if (typeof onNewOrOpts === 'function') {
    return {
      ...element,
      onNewTagSelected: onNewOrOpts,
    } as CreatableTagsElementDefinition<TFormData, TFieldValue>;
  }

  return element as NonCreatableTagsElementDefinition<TFormData, TFieldValue>;
}

export const createTagsElement = createTagsElementFn;

export const createCategoryElement = <
  TFormData extends Record<string, any> = Record<string, any>,
>(
  name: string,
  label: string,
  render?: (data: TFormData) => boolean,
): CategoryTitleDefinition<TFormData> => ({
  type: 'category-title',
  label,
  name,
  render,
});

type FormDefinitionArgsType<TContext extends Record<string, any> | undefined> =
  {
    t: Translate;
    context: TContext;
  };

export type FormFieldDefinitionType<
  TFormData extends Record<string, any> = Record<string, any>,
> =
  | SelectFieldDefinition<TFormData>
  | TextFieldDefinition<TFormData>
  | NumberFieldDefinition<TFormData>
  | PhoneFieldDefinition<TFormData>
  | DatePickerDefinition<TFormData>
  | CheckboxFieldDefinition<TFormData>
  | CheckboxGroupFieldDefinition<TFormData>
  | ColorPickerFieldDefinition<TFormData>
  | CategoryTitleDefinition<TFormData>
  | AddressFieldDefinition<TFormData>
  | OrganisationFieldDefinition<TFormData>
  | TeamFieldDefinition<TFormData>
  | LotFieldDefinition<TFormData>
  | LeadFieldDefinition<TFormData>
  | UserFieldDefinition<TFormData>
  | DictionaryFieldDefinition<TFormData>
  | RichTextFieldDefinition<TFormData>
  | RichEditorSuggestionsFieldDefinition<TFormData>
  | CustomElementDefinition<TFormData>
  | TagsElementDefinition<TFormData>
  | ImageElementDefinition<TFormData>;

export type FormDefinitionType<
  TFormData extends Record<string, any> = Record<string, any>,
  TContext extends Record<string, any> | undefined = undefined,
> = (
  args: FormDefinitionArgsType<TContext>,
) => FormFieldDefinitionType<TFormData>[];

type NonInternalProps<T> = T extends Record<string, any>
  ? { [K in Exclude<keyof T, `__${string}`>]: T[K] }
  : never;

// This function recursively replaces empty strings with null
const makeEmptyNull = <T extends Record<string, Primitive>>(obj: T): T => {
  const newObj = { ...obj }; // shallow copy the object to prevent direct modification
  for (const key in newObj) {
    if (
      typeof newObj[key] === 'object' &&
      !Array.isArray(newObj[key]) &&
      newObj[key] != null
    ) {
      newObj[key] = makeEmptyNull(newObj[key]); // recursive call for nested objects
    } else if (newObj[key] === '') {
      newObj[key] = null as any;
    }
  }
  return newObj;
};

const removeInternalKeys = <T extends Record<string, any>>(obj: T) =>
  Object.fromEntries(
    Object.entries(obj).filter(([key]) => !key.startsWith('__')),
  ) as NonInternalProps<T>;

export type RaFormOnChange<
  TFormData extends Record<string, any> = Record<string, any>,
> = (
  data: DeepPartial<TFormData> | undefined,
  name: FieldPath<TFormData> | undefined,
  helpers: {
    runValidation: () => boolean;
  } & UseFormReturn<TFormData>,
) => void;

export type RaFormProps<
  TFormData extends Record<string, any>,
  TContext extends Record<string, any> | undefined,
  TSubmitResponse extends Promise<any> = Promise<any>,
> = React.PropsWithChildren<
  {
    defaultValues?: DeepPartial<NoInfer<TFormData>>;
    freezeInitialDefaultValues?: boolean;
    valuesOverride?: DeepPartial<NoInfer<TFormData>>;
    onSubmit: (
      data: NonInternalProps<TFormData>,
      unStrippedData: TFormData,
      event?: React.BaseSyntheticEvent<SubmitEvent>,
    ) => TSubmitResponse;
    onChange?: RaFormOnChange<TFormData>;
    onCancel?: (isFormDirty: boolean) => void;
    validate?: (data: TFormData) => Parameters<UseFormSetError<TFormData>>[];
    onFormDirtyChange?: Dispatch<SetStateAction<boolean>>;
    contentScrollable?: boolean;
    gridProps?: GridProps;
    allowSubmitNotDirty?: boolean;
    customExtractMessagesFromError?: (
      error: Error,
      formDataFields: TFormData,
      t: Translate,
    ) => IFormError<TFormData>[];
  } & (TContext extends undefined
    ? {
        formDefinition: FormDefinitionType<TFormData>;
      }
    : {
        formDefinition: FormDefinitionType<TFormData, NonNullable<TContext>>;
        context: NonNullable<TContext>;
      }) &
    (
      | {
          submitButtonText?: string;
          cancelButtonText?: string;
          actionButtonsComponent?: never;
        }
      | {
          submitButtonText?: never;
          cancelButtonText?: never;
          actionButtonsComponent: React.ReactNode;
        }
    )
>;

export const RaForm = <
  TFormData extends Record<string, any>,
  TContext extends Record<string, any> | undefined = undefined,
  TSubmitResponse extends Promise<any> = Promise<any>,
>(
  props: RaFormProps<TFormData, TContext, TSubmitResponse>,
) => {
  const {
    defaultValues,
    freezeInitialDefaultValues = false,
    valuesOverride,
    onSubmit,
    onCancel,
    onChange,
    submitButtonText,
    cancelButtonText,
    validate,
    onFormDirtyChange,
    actionButtonsComponent,
    contentScrollable = false,
    gridProps,
    children,
    allowSubmitNotDirty = false,
    customExtractMessagesFromError,
  } = props;
  const formMethods = useForm<TFormData>({
    defaultValues,
  });
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isDirty, isSubmitting },
    reset,
    watch,
  } = formMethods;

  // only reset form when defaultValues change
  const prevDefaultValues = useRef<DeepPartial<TFormData> | undefined>();
  const prevValuesOverride = useRef<DeepPartial<TFormData> | undefined>();

  useEffect(() => {
    if (
      freezeInitialDefaultValues === false &&
      defaultValues &&
      prevDefaultValues.current != null &&
      !isEqual(prevDefaultValues.current, defaultValues)
    ) {
      reset(defaultValues);
    }
    prevDefaultValues.current = defaultValues;
  }, [defaultValues, freezeInitialDefaultValues, reset]);

  useEffect(() => {
    if (
      valuesOverride &&
      prevValuesOverride.current != null &&
      !isEqual(prevValuesOverride.current, valuesOverride)
    ) {
      getKeys(valuesOverride).forEach(key => {
        setValue(key as Path<TFormData>, valuesOverride[key], {
          shouldDirty: true,
        });
      });
    }
    prevValuesOverride.current = valuesOverride;
  }, [valuesOverride, reset, setValue]);

  // trigger onChange callback when form data changes
  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (type === 'change') {
        const runValidation = () => {
          let isValid = true;
          if (validate) {
            const validateErrors = validate(values as TFormData);

            validateErrors.forEach(errorArgs => {
              setError(...errorArgs);
              isValid = false;
            });

            if (isValid) {
              clearErrors();
            }
          }
          return isValid;
        };

        onChange?.(values, name, { ...formMethods, runValidation });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, onChange, formMethods, validate, setError, clearErrors]);

  const { t, language } = useLocale();
  const [snackErrorOpened, setSnackErrorOpened] = useState(false);

  useEffect(() => {
    onFormDirtyChange?.(isDirty);
  }, [isDirty, onFormDirtyChange]);

  const localeText = {
    en: enGB,
    fr,
    de,
    es,
    it,
  }[language];

  const handleSnackClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackErrorOpened(false);
  };

  const submitNulledValues = useCallback(
    async (formData: TFormData, event?: React.BaseSyntheticEvent) => {
      const cleanedValues = removeInternalKeys(makeEmptyNull(formData));

      if (validate) {
        const validateErrors = validate(formData);

        if (validateErrors.length > 0) {
          validateErrors.forEach(errorArgs => {
            setError(...errorArgs);
          });

          return null;
        }
      }

      try {
        const result = await onSubmit(
          cleanedValues,
          formData,
          event as React.BaseSyntheticEvent<SubmitEvent> | undefined,
        );

        return result;
      } catch (error: any) {
        const errorMessages = [
          ...(customExtractMessagesFromError?.(error, formData, t) ?? []),
          ...extractMessageFromError(error, formData, t),
        ];

        if (
          errorMessages.length === 1 &&
          errorMessages[0].errorMessage === 'defaultError'
        ) {
          // Display snack wih default error message.
          setSnackErrorOpened(true);
        } else {
          errorMessages.forEach(({ field, errorMessage }) => {
            setError(field, { message: errorMessage });
          });
        }

        return null;
      }
    },
    [validate, setError, onSubmit, customExtractMessagesFromError, t],
  );

  const watchedValues = watch();
  const fieldsDefinition =
    'context' in props
      ? props.formDefinition({
          t,
          context: props.context,
        })
      : props.formDefinition({
          t,
          context: undefined,
        });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={localeText}
    >
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(submitNulledValues)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            height: contentScrollable ? '100%' : 'auto',
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              overflow: contentScrollable ? 'auto' : 'visible',
            }}
          >
            <Grid container spacing={2} padding={2} {...gridProps}>
              {
                // Render form fields
                fieldsDefinition.map(field => {
                  if (field.render && !field.render(watchedValues)) {
                    return null;
                  }

                  const helpText =
                    typeof field.helpText === 'function'
                      ? field.helpText(watchedValues)
                      : field.helpText;

                  switch (field.type) {
                    case 'category-title':
                      return (
                        <Grid
                          item
                          xs={12}
                          key={field.name}
                          {...field.gridProps}
                        >
                          <Box
                            sx={{
                              borderBottom: '1px solid',
                              borderColor: 'grey.400',
                              mb: 1,
                            }}
                          >
                            <Typography variant="h6" component="h3">
                              {field.label}
                            </Typography>
                          </Box>
                        </Grid>
                      );
                    case 'custom':
                      return (
                        <Grid
                          item
                          {...defaultGridProps}
                          {...field.gridProps}
                          key={field.name}
                        >
                          {field.element}
                        </Grid>
                      );
                    case 'text':
                      return (
                        <Grid
                          item
                          {...defaultGridProps}
                          {...field.gridProps}
                          key={field.name}
                        >
                          <RaTextField
                            name={field.name}
                            label={field.label}
                            errors={errors}
                            register={register}
                            required={field.required}
                            multiline={field.multiline}
                            minRows={field.rows}
                            disabled={field.disabled?.(watchedValues)}
                            suffix={field.suffix}
                            prefix={field.prefix}
                          />
                        </Grid>
                      );
                    case 'number':
                      return (
                        <Grid
                          item
                          {...defaultGridProps}
                          {...field.gridProps}
                          key={field.name}
                        >
                          <RaNumber
                            name={field.name}
                            label={field.label}
                            required={field.required}
                            min={field.min}
                            max={field.max}
                            control={control}
                            errors={errors}
                            disabled={field.disabled?.(watchedValues)}
                            decimalNumbers={field.decimalNumbers}
                            helpText={helpText}
                            suffix={field.suffix}
                            prefix={field.prefix}
                            disableFormatting={field.disableFormatting}
                          />
                        </Grid>
                      );
                    case 'phone_number':
                      return (
                        <Grid
                          item
                          {...defaultGridProps}
                          {...field.gridProps}
                          key={field.name}
                        >
                          <RaPhoneNumber
                            name={field.name}
                            label={field.label}
                            control={control}
                            errors={errors}
                            disabled={field.disabled?.(watchedValues)}
                          />
                        </Grid>
                      );
                    case 'date':
                      return (
                        <Grid
                          item
                          {...defaultGridProps}
                          {...field.gridProps}
                          key={field.name}
                        >
                          <RaDate
                            control={control}
                            name={field.name.toString()}
                            label={field.label}
                            required={field.required}
                            setValue={setValue}
                            getValues={getValues}
                            disabled={field.disabled?.(watchedValues)}
                          />
                        </Grid>
                      );
                    case 'checkbox-group':
                      return (
                        <Grid item {...field.gridProps} key={field.name}>
                          <RaCheckboxGroup
                            control={control}
                            label={field.label}
                            checkboxes={field.checkboxes}
                            formValues={watchedValues}
                            disabled={field.disabled?.(watchedValues)}
                          />
                        </Grid>
                      );
                    case 'checkbox':
                      return (
                        <Grid
                          item
                          {...defaultGridProps}
                          {...field.gridProps}
                          key={field.name}
                        >
                          <RaCheckbox
                            name={field.name.toString()}
                            control={control}
                            label={field.label}
                            useSwitch={field.style === 'switch'}
                            disabled={field.disabled?.(watchedValues)}
                          />
                        </Grid>
                      );
                    case 'select':
                      return (
                        <Grid
                          item
                          {...defaultGridProps}
                          {...field.gridProps}
                          key={field.name}
                        >
                          <RaSelect
                            name={field.name.toString()}
                            label={field.label}
                            required={field.required}
                            control={control}
                            options={field.options(watchedValues)}
                            disabled={field.disabled?.(watchedValues)}
                            multiple={field.multiple}
                            renderValue={field.renderValue}
                          />
                        </Grid>
                      );
                    case 'address':
                      return (
                        <Grid
                          item
                          {...field.gridProps}
                          key={`${field.path ?? ''}_address`}
                        >
                          <RaAddressInput
                            id={`${field.path ?? ''}_address`}
                            path={field.path}
                            label={field.label}
                            setValue={setValue}
                            disabled={field.disabled?.(watchedValues)}
                            formValues={
                              field.path != null
                                ? watch(field.path)
                                : watchedValues
                            }
                            requiredFields={field.requiredFields?.(
                              watchedValues,
                            )}
                            required={field.required}
                            includeGoogleFields={field.includeGoogleFields}
                            countryRestriction={field.countryRestriction}
                          />
                        </Grid>
                      );
                    case 'organisation':
                      return (
                        <Grid
                          item
                          {...defaultGridProps}
                          {...field.gridProps}
                          key={field.name}
                        >
                          <RaOrganisation
                            errors={errors}
                            name={field.name}
                            label={field.label}
                            getValues={getValues}
                            setValue={setValue}
                          />
                        </Grid>
                      );
                    case 'team':
                      return (
                        <Grid
                          item
                          {...defaultGridProps}
                          {...field.gridProps}
                          key={field.name}
                        >
                          <RaTeam
                            required={field.required}
                            name={field.name}
                            label={field.label}
                            control={control}
                            disabled={field.disabled?.(watchedValues)}
                          />
                        </Grid>
                      );
                    case 'lot':
                      return (
                        <Grid
                          item
                          {...defaultGridProps}
                          {...field.gridProps}
                          key={field.name}
                        >
                          <RaLot
                            required={field.required}
                            name={field.name}
                            label={field.label}
                            control={control}
                            disabled={field.disabled?.(watchedValues)}
                          />
                        </Grid>
                      );
                    case 'lead':
                      return (
                        <Grid
                          item
                          {...defaultGridProps}
                          {...field.gridProps}
                          key={field.name}
                        >
                          <RaLead
                            errors={errors}
                            name={field.name}
                            label={field.label}
                            placeholder={field.placeholder}
                            getValues={getValues}
                            setValue={setValue}
                            autoFocus={field.autoFocus}
                            customWhereClause={field.customWhereClause}
                            orderBy={field.orderBy}
                            instantQuery={field.instantQuery}
                          />
                        </Grid>
                      );
                    case 'color-picker':
                      return (
                        <Grid
                          item
                          {...defaultGridProps}
                          {...field.gridProps}
                          key={field.name}
                        >
                          <RaColorPicker
                            label={field.label}
                            control={control}
                            name={field.name}
                          />
                        </Grid>
                      );

                    case 'user':
                      return (
                        <Grid
                          item
                          {...defaultGridProps}
                          {...field.gridProps}
                          key={field.name}
                        >
                          <RaUser
                            required={field.required}
                            name={field.name}
                            label={field.label}
                            control={control}
                            disabled={field.disabled?.(watchedValues)}
                            createUserSelected={field.createUserSelected}
                            filters={field.filters}
                          />
                        </Grid>
                      );

                    case 'dictionary':
                      return (
                        <Grid
                          item
                          {...defaultGridProps}
                          {...field.gridProps}
                          key={field.name}
                        >
                          <RaDictionary
                            required={field.required}
                            name={field.name}
                            label={field.label}
                            control={control}
                            dictionaryType={field.dictionaryType}
                            disabled={field.disabled?.(watch())}
                            multiple={field.multiple}
                            valueField={field.valueField}
                          />
                        </Grid>
                      );
                    case 'rich-text':
                      return (
                        <Grid
                          item
                          {...defaultGridProps}
                          {...field.gridProps}
                          key={field.name}
                        >
                          <Controller
                            name={field.name}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <MarkdownComposer
                                placeholder={field.label}
                                key={field?.resetKey}
                                initialValue={value}
                                variant="outlined"
                                readOnly={field.disabled?.(watchedValues)}
                                actions={
                                  field.actions == null
                                    ? field.actions
                                    : typeof field.actions === 'function'
                                    ? field.actions({
                                        getValues,
                                        reset,
                                        setValue,
                                      })
                                    : field.actions
                                }
                                onChange={newValue =>
                                  onChange(
                                    newValue as PathValue<
                                      TFormData,
                                      Path<TFormData>
                                    >,
                                  )
                                }
                              />
                            )}
                          />
                        </Grid>
                      );
                    case 'rich-editor-suggestions':
                      return (
                        <Grid
                          item
                          {...defaultGridProps}
                          {...field.gridProps}
                          key={field.name}
                        >
                          <RaRichEditorSuggestions
                            name={field.name}
                            label={field.label}
                            control={control}
                            suggestions={field.suggestions}
                            singleLine={field.singleLine}
                            required={field.required}
                            resetKey={field.resetKey}
                            listing={field.listing}
                          />
                        </Grid>
                      );
                    case 'tags':
                      return (
                        <Grid
                          item
                          {...defaultGridProps}
                          {...field.gridProps}
                          key={field.name}
                        >
                          <RaTags
                            required={field.required}
                            name={field.name}
                            label={field.label}
                            control={control}
                            disabled={field.disabled?.(watchedValues)}
                            onFilter={field.onFilter}
                            onNewTagSelected={
                              'onNewTagSelected' in field
                                ? field.onNewTagSelected
                                : undefined
                            }
                            renderOption={field.renderOption}
                            renderTags={field.renderTags}
                            getOptionLabel={field.getOptionLabel}
                            isOptionEqualToValue={field.isOptionEqualToValue}
                            InputProps={field.InputProps}
                          />
                        </Grid>
                      );
                    case 'image':
                      return (
                        <Grid
                          item
                          {...defaultGridProps}
                          {...field.gridProps}
                          key={field.name}
                        >
                          <RaImageUpload
                            required={field.required}
                            name={field.name}
                            label={field.label}
                            control={control}
                            onFileUpload={field.onFileUpload}
                            disabled={field.disabled?.(watchedValues)}
                          />
                        </Grid>
                      );
                    default:
                      return null;
                  }
                })
                // End render form fields
              }
            </Grid>
            {children}
          </Box>
          <Snackbar
            open={snackErrorOpened}
            autoHideDuration={6000}
            onClose={handleSnackClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handleSnackClose}
              severity="error"
              sx={{ width: '100%' }}
            >
              {t('An error occured while submitting your form')}
            </Alert>
          </Snackbar>
          {actionButtonsComponent ?? (
            <div
              style={{
                position: 'sticky',
                bottom: 0,
              }}
            >
              <Box
                display="flex"
                justifyContent="flex-end"
                gap={1}
                padding={1}
                component={Paper}
                elevation={3}
                borderRadius={0}
              >
                <Button
                  variant="outlined"
                  color="neutral"
                  disabled={isSubmitting}
                  onClick={() => {
                    reset(defaultValues);
                    onCancel?.(isDirty);
                  }}
                >
                  {cancelButtonText ?? t('Cancel')}
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isDirty && !allowSubmitNotDirty}
                  loading={isSubmitting}
                  loadingPosition="start"
                  startIcon={<Save />}
                >
                  {submitButtonText ?? t('Save')}
                </LoadingButton>
              </Box>
            </div>
          )}
        </form>
      </FormProvider>
    </LocalizationProvider>
  );
};
