import { Box, Typography } from '@mui/material';

import { useLocale } from '../../../src/hooks/locale';
import { type Currency, getCurrencyByCountryCode } from '../../../src/locale';
import { formatPrice } from '../../../src/utils/format-price';
import { gql } from '../../__generated__';
import { type ListingPriceCellFragment } from '../../__generated__/graphql';
import { getListingPrices } from '../../utils/propertyTemplateFunctions';

export const LISTING_PRICE_CELL_FRAGMENT = gql(`
  fragment ListingPriceCell on lots {
    offer_type
    sale_price
    price_unit
    rent_net
    rent_extra
    currency
    hide_price_on_portals
    property {
      id
      country_code
      computed_surface
      latest_appraisal {
        id
        value
        price_per_living_surface_meter_squared
      }
    }
  }
`);

export const ListingPriceCell: React.FC<{ row: ListingPriceCellFragment }> = ({
  row,
}) => {
  const { t, locale } = useLocale();
  const price = row.sale_price ?? row.rent_net;

  if (!price) {
    return null;
  }

  const prices = getListingPrices(row, t, locale);
  const currency = (row.currency ??
    getCurrencyByCountryCode(row.property.country_code ?? 'CH')) as Currency;

  if (prices.appraisalDiff == null) {
    return (
      <Typography variant="body2" fontWeight={500}>
        {prices.primary}
      </Typography>
    );
  }

  const appraisal =
    row.price_unit === 'sell'
      ? row.property.latest_appraisal?.value
      : row.property.latest_appraisal?.price_per_living_surface_meter_squared;

  return (
    <Box>
      <Typography variant="body2" lineHeight="18px" fontWeight={500}>
        {prices.primary}
      </Typography>
      <Typography
        variant="caption"
        display="block"
        textAlign="right"
        color={prices.appraisalDiff > 0 ? 'error.main' : 'success.main'}
        lineHeight="18px"
      >
        {prices.appraisalDiff > 0 ? '+' : ''}
        {prices.appraisalDiff.toLocaleString(locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })}
        %
      </Typography>
      {appraisal && (
        <Typography
          variant="caption"
          textAlign="right"
          lineHeight="18px"
          sx={{ display: 'block', color: 'grey.500' }}
        >
          {formatPrice(appraisal, locale, currency)}
        </Typography>
      )}
    </Box>
  );
};
