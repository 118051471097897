import { useMemo } from 'react';

import {
  type InternalRefetchQueriesInclude,
  useMutation,
  useQuery,
} from '@apollo/client';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Alert, LinearProgress, useTheme } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { toGlobalId } from '../../../shared/global-id';
import { useLocale } from '../../../src/hooks/locale';
import { Offers_Status_Enum } from '../../__generated__/graphql';
import { Drawer } from '../../components/drawer/Drawer';
import { OfferForm } from '../../components/offers/OfferForm';
import { useAppData } from '../../providers/AppDataProvider';

import { DELETE_OFFER_MUTATION, GET_OFFER_DRAWER_QUERY } from './offersQueries';

export const OfferDrawer: React.FC<{
  refetchQueries?: InternalRefetchQueriesInclude;
}> = ({ refetchQueries }) => {
  const { t } = useLocale();
  const { breakpoints } = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { offerId, lotId } = useParams();
  const { me } = useAppData();

  const { data, error, loading } = useQuery(GET_OFFER_DRAWER_QUERY, {
    variables: { id: offerId ?? '' },
    skip: offerId == null,
  });

  const handleClose = () => {
    searchParams.delete('fromListing');
    searchParams.delete('buyerId');

    navigate({
      pathname: '../',
      search: searchParams.toString(),
    });
  };

  const [deleteOffer] = useMutation(DELETE_OFFER_MUTATION);
  const listingLink = (listingId: string) => ({
    pathname: `/listings/${listingId}`,
  });

  const canUpdateOffer =
    me?.is_admin === true || me?.id === data?.offers_by_pk?.created_by;

  const title = useMemo(() => {
    if (offerId == null) {
      return t('Create offer');
    }

    return canUpdateOffer ? t('Edit offer') : t('View offer');
  }, [offerId, canUpdateOffer, t]);

  const drawerActions =
    offerId != null
      ? [
          {
            label: t('Delete offer'),
            disabled: !canUpdateOffer,
            onClick: () => {
              if (
                window.confirm(t('Are you sure you want to delete this offer?'))
              ) {
                // Delete team and remove from cache
                deleteOffer({
                  variables: {
                    id: offerId,
                  },
                  update: cache => {
                    cache.evict({
                      id: `offers:${offerId}`,
                    });
                    cache.gc();
                  },
                }).then(() => {
                  // Close drawer
                  handleClose();
                });
              }
            },
            icon: <DeleteForeverIcon />,
          },
        ]
      : undefined;

  return (
    <Drawer
      onClose={handleClose}
      title={title}
      actions={drawerActions}
      maxWidth={breakpoints.values.md}
    >
      {error && (
        <Alert severity="error" sx={{ m: 2 }}>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      )}
      {loading && (
        <LinearProgress
          sx={{
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: 2000,
          }}
        />
      )}
      {data?.offers_by_pk != null && (
        <OfferForm
          offer={data.offers_by_pk}
          listingLink={listingLink}
          readonly={!canUpdateOffer}
          preventListingChange={lotId != null}
          onCancel={isFormDirty => {
            if (!isFormDirty) {
              handleClose();
            }
          }}
          onSubmit={updateData => {
            if (
              data.offers_by_pk?.status !== updateData?.status &&
              updateData?.status === Offers_Status_Enum.Accepted &&
              updateData.lot != null
            ) {
              navigate({
                pathname: `/listings/${updateData.lot.id}/transaction/new/`,
                search: `?lotId=${toGlobalId('Lot', updateData.lot.id)}`,
              });
            }
          }}
        />
      )}
      {offerId == null && (
        <OfferForm
          offer={null}
          listingLink={listingLink}
          prefillListing={lotId ?? searchParams.get('fromListing') ?? undefined}
          initialBuyerId={searchParams.get('buyerId') ?? undefined}
          preventListingChange={lotId != null}
          refetchQueries={refetchQueries}
          onCancel={isFormDirty => {
            if (!isFormDirty) {
              handleClose();
            }
          }}
          onSubmit={updateData => {
            if (
              updateData?.status === Offers_Status_Enum.Accepted &&
              updateData.lot != null
            ) {
              navigate({
                pathname: `/listings/${updateData.lot.id}/transaction/new/`,
                search: `?lotId=${toGlobalId('Lot', updateData.lot.id)}`,
              });
            } else {
              handleClose();
            }
          }}
        />
      )}
    </Drawer>
  );
};
