import { useState } from 'react';

import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Alert, Paper, Typography } from '@mui/material';

import { Markdown } from '../../../src/controls/markdown';
import {
  type ActivityFieldsFragment,
  Activity_Types_Enum_Enum,
} from '../../__generated__/graphql';
import { TimeAgo } from '../TimeAgo';

import { Assignment } from './Activity/Assignment';
import { Call } from './Activity/Call';
import { ActivityIcon } from './ActivityIcon';
import { ActivityTitle } from './ActivityTitle';

export const Activity = ({
  activity,
}: {
  activity: ActivityFieldsFragment;
  refetchFeed?: () => void;
}) => {
  const [showJSON, setShowJSON] = useState(false);

  if (activity.activity_type === Activity_Types_Enum_Enum.Assignment) {
    return <Assignment activity={activity} />;
  }

  if (activity.activity_type === Activity_Types_Enum_Enum.Call) {
    return <Call activity={activity} />;
  }

  return (
    <TimelineItem key={activity?.id} style={{ fontSize: 10 }}>
      <TimelineSeparator>
        <TimelineConnector sx={{ height: '6px', flexGrow: 0 }} />
        <div onClick={() => setShowJSON(!showJSON)}>
          <ActivityIcon activity={activity} />
        </div>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ pr: 0 }}>
        <Typography>
          <ActivityTitle activity={activity} />
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          fontStyle={activity?.done_at ? 'italic' : 'normal'}
          sx={{ mb: 0.5 }}
        >
          <TimeAgo dateString={activity?.created_at} />
        </Typography>

        {(activity.note ||
          activity.subject ||
          activity.email_message?.snippet) && (
          <Paper sx={{ p: 1.5 }} elevation={0} variant="outlined">
            {activity.subject && (
              <Typography
                fontWeight={600}
                fontSize={16}
                style={{
                  maxWidth: 650,
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {[activity.subject, activity.email_message?.subject]
                  .filter(Boolean)
                  .join(' - ')}
              </Typography>
            )}
            {(activity.email_message?.snippet || activity.note) && (
              <Markdown
                children={[activity.email_message?.snippet, activity.note]
                  .filter(Boolean)
                  .join('\n')}
                style={{ fontSize: 15 }}
              />
            )}
          </Paper>
        )}
        {showJSON && (
          <Paper sx={{ p: 1.5 }} elevation={0} variant="outlined">
            <Alert
              severity="info"
              sx={{
                fontSize: 12,
                fontFamily: 'monospace',
                position: 'relative',
                height: 500,
              }}
            >
              <pre
                style={{
                  overflow: 'auto',
                  position: 'absolute',
                  top: 20,
                  left: 40,
                  right: 0,
                  bottom: 0,
                }}
              >
                {JSON.stringify(activity, null, 2)}
              </pre>
            </Alert>
          </Paper>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};
