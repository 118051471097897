import React, { useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert, CircularProgress, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import type { GetPortalQuery } from '../../__generated__/graphql';
import { RaForm } from '../../components/form/RaForm';
import { INSERT_IMAGE_ONE } from '../../components/image-gallery/imageGalleryQueries';
import { useAppData } from '../../providers/AppDataProvider';

import { formDefinition } from './portalFormDefinition';
import { GET_PORTAL, GET_PORTALS, UPSERT_PORTAL } from './portalsQueries';

export const PortalSettings: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const { t } = useLocale();
  const { portalId, teamId } = useParams();
  const { me } = useAppData();
  const [upsertError, setUpsertError] = useState<Error | null>(null);

  const {
    loading,
    data,
    error: queryError,
  } = useQuery(GET_PORTAL, {
    variables: { id: portalId ?? '' },
    skip: portalId == null,
  });

  const isEditing = !!data?.portals_by_pk?.id;

  const shared_portals = data?.shared_portals ?? [];
  const portals_types_enum = data?.portals_types_enum ?? [];

  const [upsertPortal] = useMutation(UPSERT_PORTAL, {
    update: (cache, { data }) => {
      if (data?.insert_portals_one != null) {
        cache.writeQuery<GetPortalQuery>({
          query: GET_PORTAL,
          variables: { id: portalId },
          data: {
            portals_by_pk: data.insert_portals_one,
            shared_portals,
            portals_types_enum,
          },
        });
      }
    },
    refetchQueries: [
      {
        query: GET_PORTALS,
      },
      {
        query: GET_PORTAL,
        variables: { id: portalId },
      },
    ],
  });

  const [insertImageOne] = useMutation(INSERT_IMAGE_ONE);

  const onSubmit = (formData: any) =>
    upsertPortal({
      variables: {
        portal: {
          ...formData,
          id: portalId,
        },
      },
    }).catch(setUpsertError);

  const defaultValues = useMemo(
    () => ({
      created_at: data?.portals_by_pk?.created_at,
      created_by: data?.portals_by_pk?.created_by,
      active: data?.portals_by_pk?.active,
      contract_id: data?.portals_by_pk?.contract_id,
      email: data?.portals_by_pk?.email,
      exact_address_required: data?.portals_by_pk?.exact_address_required,
      exclude_from_published_stats:
        data?.portals_by_pk?.exclude_from_published_stats,
      has_top_premium_listing: data?.portals_by_pk?.has_top_premium_listing,
      idx_protocol: data?.portals_by_pk?.idx_protocol,
      master_portal_id: data?.portals_by_pk?.master_portal_id,
      name: data?.portals_by_pk?.name,
      password: data?.portals_by_pk?.password,
      port: data?.portals_by_pk?.port,
      quota: data?.portals_by_pk?.quota,
      type: data?.portals_by_pk?.type,
      url: data?.portals_by_pk?.url,
      username: data?.portals_by_pk?.username,
      website_url: data?.portals_by_pk?.website_url,
      team_id: data?.portals_by_pk?.team_id ?? teamId,
      logo_image_id: data?.portals_by_pk?.logo?.id ?? null,
    }),
    [data?.portals_by_pk, teamId],
  );

  return (
    <>
      {(queryError || upsertError) && (
        <Alert severity="error" sx={{ m: 2 }}>
          <pre>{JSON.stringify(queryError || upsertError, null, 2)}</pre>
        </Alert>
      )}
      {loading && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress disableShrink />
        </Grid>
      )}
      {data && (
        <RaForm
          formDefinition={formDefinition}
          context={{
            shared_portals,
            portals_types_enum,
            disableTeamId: !!teamId || !me?.is_admin,
            isCreation: data?.portals_by_pk?.id == null,
            insertImageOne,
            readOnly: !me?.is_admin,
          }}
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          submitButtonText={isEditing ? t('Update portal') : t('Add portal')}
          onCancel={isDirty => {
            if (!isDirty) {
              onClose();
            }
          }}
        />
      )}
    </>
  );
};
