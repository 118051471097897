/**
 * @generated SignedSource<<88075d05d39f8ea80b5c0646a1f34fb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LotMandateType = "commission_based" | "fixed_fee";
export type OfferStatus = "accepted" | "pending" | "refused";
export type SubscriptionStatus = "active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "trialing" | "unpaid";
export type UserGender = "female" | "male";
export type UserLanguage = "de" | "en" | "es" | "fr" | "it";
export type UserTitle = "doctor" | "madam" | "professor" | "sir";
import { FragmentRefs } from "relay-runtime";
export type EmailFormDataWrapper_lot$data = {
  readonly __typename: "Lot";
  readonly broker: {
    readonly firstName: string | null;
    readonly gender: UserGender | null;
    readonly id: string;
    readonly language: UserLanguage | null;
    readonly lastName: string | null;
    readonly organisation: {
      readonly formattedAddress: string | null;
      readonly id: string;
      readonly name: string | null;
    } | null;
    readonly primaryEmail: {
      readonly email: string;
      readonly id: string;
    } | null;
    readonly primaryImage: {
      readonly url: string;
    } | null;
    readonly primaryPhoneNumber: {
      readonly formattedNumber: string;
      readonly id: string;
    } | null;
    readonly subscription: {
      readonly status: SubscriptionStatus;
    } | null;
    readonly title: UserTitle | null;
  } | null;
  readonly commissionRate: number | null;
  readonly fixedFee: number | null;
  readonly id: string;
  readonly lead: {
    readonly cmaReports: ReadonlyArray<{
      readonly docTemplate: {
        readonly id: string;
      } | null;
      readonly id: string;
      readonly updatedAt: string;
    }> | null;
    readonly id: string;
  } | null;
  readonly lotReference: string | null;
  readonly mandateType: LotMandateType;
  readonly offers: ReadonlyArray<{
    readonly amount: number;
    readonly createdAt: string;
    readonly status: OfferStatus;
  }> | null;
  readonly primaryPropertyImage: {
    readonly image: {
      readonly url: string;
    };
  } | null;
  readonly product: {
    readonly id: string;
  } | null;
  readonly property: {
    readonly formattedAddress: string | null;
    readonly id: string;
    readonly landSurface: number | null;
    readonly latestAppraisal: {
      readonly realadvisor: {
        readonly max: number | null;
        readonly min: number | null;
        readonly value: number | null;
      } | null;
    } | null;
    readonly livingSurface: number | null;
    readonly locality: string | null;
    readonly municipalityObject: {
      readonly label: string;
    } | null;
    readonly numberOfRooms: number | null;
    readonly postcode: string | null;
    readonly propertyType: {
      readonly label: string | null;
    } | null;
    readonly route: string | null;
    readonly state: string | null;
    readonly streetNumber: string | null;
  };
  readonly rentExtra: number | null;
  readonly rentNet: number | null;
  readonly salePrice: number | null;
  readonly seller: {
    readonly firstName: string | null;
    readonly id: string;
    readonly lastName: string | null;
    readonly organisation: {
      readonly formattedAddress: string | null;
      readonly name: string | null;
    } | null;
    readonly primaryEmail: {
      readonly email: string;
    } | null;
    readonly primaryImage: {
      readonly url: string;
    } | null;
    readonly subscription: {
      readonly status: SubscriptionStatus;
    } | null;
  } | null;
  readonly title: string | null;
  readonly " $fragmentType": "EmailFormDataWrapper_lot";
};
export type EmailFormDataWrapper_lot$key = {
  readonly " $data"?: EmailFormDataWrapper_lot$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmailFormDataWrapper_lot">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formattedAddress",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "primaryImage",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Subscription",
  "kind": "LinkedField",
  "name": "subscription",
  "plural": false,
  "selections": [
    (v10/*: any*/)
  ],
  "storageKey": null
},
v12 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmailFormDataWrapper_lot",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rentNet",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rentExtra",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lotReference",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mandateType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fixedFee",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commissionRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Property",
      "kind": "LinkedField",
      "name": "property",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "route",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postcode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streetNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locality",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PropertyType",
          "kind": "LinkedField",
          "name": "propertyType",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Place",
          "kind": "LinkedField",
          "name": "municipalityObject",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Appraisal",
          "kind": "LinkedField",
          "name": "latestAppraisal",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RealadvisorAppraisal",
              "kind": "LinkedField",
              "name": "realadvisor",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "min",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "max",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfRooms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "livingSurface",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "landSurface",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PropertyImage",
      "kind": "LinkedField",
      "name": "primaryPropertyImage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "broker",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Email",
          "kind": "LinkedField",
          "name": "primaryEmail",
          "plural": false,
          "selections": [
            (v7/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Organisation",
          "kind": "LinkedField",
          "name": "organisation",
          "plural": false,
          "selections": [
            (v9/*: any*/),
            (v2/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        (v11/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gender",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PhoneNumber",
          "kind": "LinkedField",
          "name": "primaryPhoneNumber",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "formattedNumber",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "language",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "seller",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Email",
          "kind": "LinkedField",
          "name": "primaryEmail",
          "plural": false,
          "selections": [
            (v7/*: any*/)
          ],
          "storageKey": null
        },
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Organisation",
          "kind": "LinkedField",
          "name": "organisation",
          "plural": false,
          "selections": [
            (v9/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        (v11/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Offer",
      "kind": "LinkedField",
      "name": "offers",
      "plural": true,
      "selections": [
        (v10/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": (v12/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Lead",
      "kind": "LinkedField",
      "name": "lead",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CmaReport",
          "kind": "LinkedField",
          "name": "cmaReports",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "updatedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "DocTemplate",
              "kind": "LinkedField",
              "name": "docTemplate",
              "plural": false,
              "selections": (v12/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Lot",
  "abstractKey": null
};
})();

(node as any).hash = "fa7f4be445d3f704733c3474faf687a6";

export default node;
