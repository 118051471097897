import { Global, css } from '@emotion/react';
import { ThemeProvider, createTheme } from '@material-ui/core';
import {
  ThemeProvider as ThemeProviderNew,
  createTheme as createThemeNew,
} from '@mui/material/styles';
import type { CSSObject } from '@mui/styled-engine';
import { deDE, enUS, esES, frFR, itIT } from '@mui/x-data-grid-pro/locales';
import { ResponsiveProvider } from 'react-system';

import { type Language, useLocale } from './hooks/locale';

const getMuiLocale = (language: Language) => {
  switch (language) {
    case 'en': {
      return enUS;
    }
    case 'es': {
      return esES;
    }
    case 'de': {
      return deDE;
    }
    case 'it': {
      return itIT;
    }
    case 'fr': {
      return frFR;
    }
    default: {
      return enUS;
    }
  }
};

export const customPalette = {
  white: '#ffffff',
  black: '#000000',

  highText: 'rgba(0, 0, 0, 0.87)',
  mediumText: 'rgba(0, 0, 0, 0.7)',
  disabledText: 'rgba(0, 0, 0, 0.38)',
  sidebar: '#3f4751',
  sidebarSubMenu: '#35393d',
  sidebarActive: '#636b74',
  subMenu: '#F6F7F9',

  blue50: '#F1F5FD',
  blue100: '#DFE8FA',
  blue200: '#C7D7F6',
  blue300: '#A0BDF0',
  blue400: '#739AE7',
  blue500: '#5278DF',
  blue600: '#3454D1',
  blue700: '#344AC1',
  blue800: '#303D9D',
  blue900: '#2B377D',
  blue950: '#1E244D',
  blueA100: '#b4ccff',
  blueA200: '#81aaff',
  blueA400: '#4e87ff',
  blueA700: '#3576ff',
  lightBlue: '#f5f9ff',
  superLightBlue: '#F6F7F9',

  red50: '#FEF3F2',
  red100: '#FEE7E5',
  red200: '#FCD0CF',
  red300: '#F9AAA8',
  red400: '#F57877',
  red500: '#EC474A',
  red600: '#DD3944',
  red700: '#B71928',
  red800: '#991828',
  red900: '#831829',
  red950: '#490810',
  redA100: '#ffe2e3',
  redA200: '#ffafb2',
  redA400: '#ff7c81',
  redA700: '#ff6368',

  green50: '#F4F9F4',
  green100: '#E5F3E7',
  green200: '#CCE6D0',
  green300: '#A4D1AC',
  green400: '#74B47F',
  green500: '#529A5F',
  green600: '#3E7B4A',
  green700: '#33623C',
  green800: '#2C4F32',
  green900: '#26412C',
  green950: '#112215',
  greenA100: '#adffb7',
  greenA200: '#7aff8b',
  greenA400: '#47ff5e',
  greenA700: '#2dff48',

  orange50: '#FFF7ED',
  orange100: '#FFEDD5',
  orange200: '#FED7AA',
  orange300: '#FDBA74',
  orange400: '#FB923C',
  orange500: '#F97316',
  orange600: '#EA580C',
  orange700: '#C2410C',
  orange800: '#9A3412',
  orange900: '#7C2D12',
  orangeA100: '#ffffff',
  orangeA200: '#fffaf6',
  orangeA400: '#ffdcc3',
  orangeA700: '#ffcdaa',

  purple50: '#FAF5FF',
  purple100: '#F3E8FF',
  purple200: '#E9D5FF',
  purple300: '#D8B4FE',
  purple400: '#C084FC',
  purple500: '#A855F7',
  purple600: '#9333EA',
  purple700: '#7E22CE',
  purple800: '#6B21A8',
  purple900: '#581C87',
  purpleA100: '#e3ceff',
  purpleA200: '#c69bff',
  purpleA400: '#a968ff',
  purpleA700: '#9b4eff',

  yellow50: '#FFFBEB',
  yellow100: '#FFF3C6',
  yellow200: '#FFE588',
  yellow300: '#FFD24A',
  yellow400: '#FFBB1A',
  yellow500: '#F99B07',
  yellow600: '#DD7302',
  yellow700: '#B75006',
  yellow800: '#943D0C',
  yellow900: '#7A320D',
  yellow950: '#461902',
  yellowA100: '#ffffff',
  yellowA200: '#fff9eb',
  yellowA400: '#ffe8b8',
  yellowA700: '#ffe09f',

  pink50: '#FDF2F8',
  pink100: '#FCE7F3',
  pink200: '#FBCFE8',
  pink300: '#F9A8D4',
  pink400: '#F472B6',
  pink500: '#EC4899',
  pink600: '#DB2777',
  pink700: '#BE185D',
  pink800: '#9D174D',
  pink900: '#831843',
  pinkA100: '#ffffff',
  pinkA200: '#fff4ff',
  pinkA400: '#fdc1ff',
  pinkA700: '#fda7ff',

  gray50: '#F9FAFB',
  gray100: '#F3F4F6',
  gray200: '#E5E7EB',
  gray300: '#D1D5DB',
  gray400: '#9CA3AF',
  gray500: '#6B7280',
  gray600: '#4B5563',
  gray700: '#374151',
  gray800: '#1F2937',
  gray900: '#111827',
};

export const fontWeight = {
  light: 'light',
  regular: 'normal',
  medium: 500,
  bold: 700,
} as const;

export const borderRadius = {
  small: '4px', // Input fields
  medium: '6px', // Buttons
  large: '10px', // Cards, Paper
};

export const boxShadow = {
  elevation1: '0px 1px 3px rgba(0, 0, 0, 0.12)',
  elevation2: '0px 2px 6px rgba(0, 0, 0, 0.12)',
  elevation3: '0px 3px 10px rgba(0, 0, 0, 0.12)',
  elevation4: '0px 4px 16px rgba(0, 0, 0, 0.12)',
  elevation5: '0px 5px 20px rgba(0, 0, 0, 0.12)',
  elevation6: '0px 6px 24px rgba(0, 0, 0, 0.12)',
  elevation7: '0px 7px 28px rgba(0, 0, 0, 0.12)',
  elevation8: '0px 8px 32px rgba(0, 0, 0, 0.12)',
};

type Props = {
  children: React.ReactNode;
};

const commonTextStyle = {
  marginTop: 0,
  marginBottom: 0,
  color: 'inherit',
  cursor: 'inherit',
};

const size = (px: number): string => px / 16 + 'rem';

declare module '@mui/material/styles' {
  interface Theme {
    text: {
      ellipsis: {
        overflow: string;
        textOverflow: string;
        whiteSpace: string;
      };
      caption: {
        fontSize: string;
        lineHeight: number;
        fontWeight: string;
      } & typeof commonTextStyle;
      font: {
        medium: number;
        bold: number;
      };
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    text?: {
      ellipsis: {
        overflow: string;
        textOverflow: string;
        whiteSpace: string;
      };
      caption: {
        fontSize: string;
        lineHeight: number;
        fontWeight: string;
      } & typeof commonTextStyle;
      font?: {
        medium: number;
        bold: number;
      };
    };
  }

  interface Palette {
    neutral: Palette['primary'];
    white: Palette['primary'];
    sidebar: string;
    sidebarSubMenu: string;
    sidebarActive: string;
    subMenu: string;
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
    white?: PaletteOptions['primary'];
    sidebar?: string;
    sidebarSubMenu?: string;
    sidebarActive?: string;
    subMenu?: string;
  }
}

declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    truncate: (lines?: number) => CSSObject;
  }
}

const muiTheme = createThemeNew({
  text: {
    ellipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    caption: {
      ...commonTextStyle,
      fontWeight: fontWeight.regular,
      fontSize: size(12),
      lineHeight: 1.26,
    },
    font: {
      medium: fontWeight.medium,
      bold: fontWeight.bold,
    },
  },
  palette: {
    primary: {
      light: customPalette.blue400,
      main: customPalette.blue500,
      dark: customPalette.blue600,
      contrastText: '#fff',
    },
    secondary: {
      light: customPalette.red300,
      main: customPalette.red500,
      dark: customPalette.red600,
      contrastText: '#fff',
    },
    success: {
      light: customPalette.green400,
      main: customPalette.green500,
      dark: customPalette.green600,
      contrastText: '#fff',
    },
    text: {
      secondary: customPalette.mediumText,
      disabled: customPalette.disabledText,
    },
    neutral: {
      main: customPalette.gray700,
      contrastText: '#fff',
    },
    white: {
      main: customPalette.white,
      contrastText: customPalette.highText,
    },
    sidebar: customPalette.sidebar,
    sidebarSubMenu: customPalette.sidebarSubMenu,
    sidebarActive: customPalette.sidebarActive,
    subMenu: customPalette.subMenu,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      fontWeight: fontWeight.bold,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        color: 'inherit',
        underline: 'hover',
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'filled',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'filled',
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: customPalette.gray500,
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 1,
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: fontWeight.regular,
        },
        h2: {
          fontWeight: fontWeight.regular,
        },
        h3: {
          fontWeight: fontWeight.regular,
        },
        h4: {
          fontWeight: fontWeight.medium,
        },
        h5: {
          fontWeight: fontWeight.medium,
        },
        h6: {
          fontWeight: fontWeight.medium,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 1,
      },
      styleOverrides: {
        rounded: {
          borderRadius: borderRadius.large,
        },
        elevation1: {
          boxShadow: boxShadow.elevation1,
        },
        elevation2: {
          boxShadow: boxShadow.elevation2,
        },
        elevation3: {
          boxShadow: boxShadow.elevation3,
        },
        elevation4: {
          boxShadow: boxShadow.elevation4,
        },
        elevation5: {
          boxShadow: boxShadow.elevation5,
        },
        elevation6: {
          boxShadow: boxShadow.elevation6,
        },
        elevation7: {
          boxShadow: boxShadow.elevation7,
        },
        elevation8: {
          boxShadow: boxShadow.elevation8,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        rounded: {
          '&.Mui-expanded': {
            borderRadius: borderRadius.large,
          },
          '&:first-of-type': {
            borderTopLeftRadius: borderRadius.large,
            borderTopRightRadius: borderRadius.large,
          },
          '&:last-of-type': {
            borderBottomLeftRadius: borderRadius.large,
            borderBottomRightRadius: borderRadius.large,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          borderRadius: borderRadius.small,
          overflow: 'hidden',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
          },
          '&$focused': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
          },
        },
        underline: {
          '&:before': {
            borderBottom: 'unset',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: customPalette.gray400,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          display: 'flex',
          fontWeight: fontWeight.bold,
          borderRadius: borderRadius.medium,
          '&.MuiButton-contained': {
            boxShadow: boxShadow.elevation1,
          },
          '&.MuiButton-contained.MuiButton-disableElevation': {
            boxShadow: 'none',
          },
        },
        outlinedPrimary: {
          borderColor: customPalette.blue500,
        },
        outlinedSecondary: {
          borderColor: customPalette.red500,
          color: customPalette.red500,
        },
        colorInherit: {
          color: customPalette.mediumText,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            fontWeight: fontWeight.bold,
          },
        },
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      styleOverrides: {
        root: {
          // Name of the rule
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
        },
        outlined: {
          // Name of the rule
          maxWidth: 'calc(100% - 26px)',
        },
        filled: {
          // Name of the rule
          // maxWidth: 'calc(100% - 26px)',
        },
        // Some styling issues with the shrink label
        shrink: ({ ownerState: { size, variant } }: any) => ({
          ...(variant === 'filled' &&
            size === 'small' && {
              transform: 'translate(12px, 4px) scale(0.75) !important',
            }),
          // maxWidth: 'calc(125% - 26px) !important',
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child td': {
            border: 'none',
          },
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        disableShrink: true,
      },
    },
    MuiFormControlLabel: {
      defaultProps: {
        componentsProps: {
          typography: {
            noWrap: true,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          pl: 2,
          '.MuiTabs-scrollButtons.Mui-disabled': {
            opacity: 0.3,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '48px',
          /* SVG icon styles */
          '.MuiSvgIcon-root': {
            fontSize: 18,
          },
          /* Ripple styles */
          '.MuiTouchRipple-root': {
            borderRadius: '7px',
            margin: '6px 1px 7px',
          },
          /* Hover styles */
          '&:hover .MuiTouchRipple-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
  },
  mixins: {
    truncate: (lines = 1) => {
      if (lines < 1) {
        throw Error('truncate acceps minimum 1 line');
      }

      if (lines === 1) {
        return {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        };
      }

      return {
        display: '-webkit-box',
        // !important fixes emotion auto unit
        WebkitLineClamp: `${lines} !important`,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        // fallback for not supported browsers
        maxHeight: '100%',
      };
    },
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
    white: Palette['primary'];
    success: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
    white?: PaletteOptions['primary'];
    success?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    truncate: (lines?: number) => CSSObject;
  }
}

// @babel-ignore-comment-in-output Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
    success: true;
  }
}

// @babel-ignore-comment-in-output Update the Button's color prop options
declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    white: true;
  }
}

const materialUITheme = createTheme({
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      light: customPalette.blue400,
      main: customPalette.blue500,
      dark: customPalette.blue600,
      contrastText: '#fff',
    },
    secondary: {
      light: customPalette.red400,
      main: customPalette.red500,
      dark: customPalette.red600,
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiDivider: {
      vertical: {
        // default height: 100% does not work well in flex
        height: 'auto',
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
      },
      outlined: {
        // Name of the rule
        maxWidth: 'calc(100% - 26px)',
      },
      filled: {
        // Name of the rule
        maxWidth: 'calc(100% - 26px)',
      },
      shrink: {
        maxWidth: 'calc(125% - 26px) !important',
      },
    },
    // @ts-ignore
    MuiFilledInput: muiTheme.components?.MuiFilledInput?.styleOverrides,
    // @ts-ignore overrides does not support ToggleButton because it's from lab
    MuiToggleButton: {
      root: {
        // drop borders and fix jumping first button when selected
        border: '0 !important',
        marginLeft: '0 !important',
      },
    },
    // Reduce horizontal gutters on full screen dialogs on mobile
    MuiDialogTitle: {
      root: {
        '.MuiDialog-paperFullScreen &': {
          padding: '16px 8px',
          '@media (min-width: 600px)': {
            padding: '16px 24px',
          },
        },
      },
    },
    MuiDialogContent: {
      root: {
        '.MuiDialog-paperFullScreen &': {
          padding: '8px',
          '@media (min-width: 600px)': {
            padding: '8px 24px',
          },
        },
      },
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: '16px', // Keep consistent and not 24px
        },
      },
    },
    MuiDialogActions: {
      root: {
        '.MuiDialog-paperFullScreen &': {
          padding: '8px',
        },
        '@media (min-width: 600px)': {
          padding: '16px 24px',
        },
      },
    },
    MuiTouchRipple: {
      child: {
        '.MuiListItem-button &': {
          backgroundColor: customPalette.blue500,
        },
      },
    },
    MuiInputBase: {
      input: {
        height: 'auto',
      },
    },
    MuiCardActions: {
      root: {
        justifyContent: 'flex-end',
        '.MuiCardContent-root + &': {
          paddingTop: 0,
        },
      },
    },
    MuiTab: {
      root: {
        fontWeight: fontWeight.medium,
        '&.Mui-selected': {
          fontWeight: fontWeight.bold,
          color: customPalette.white,
        },
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: fontWeight.medium,
      },
    },
    MuiListSubheader: {
      root: {
        fontWeight: fontWeight.bold,
        color: customPalette.highText,
        backgroundColor: customPalette.gray200,

        lineHeight: '32px',
      },
      sticky: {
        backgroundColor: customPalette.gray200,
      },
    },
    MuiListItemIcon: {
      root: {
        color: customPalette.black,
      },
    },
    MuiInputAdornment: {
      root: {
        color: customPalette.gray500,
      },
    },
    // @ts-ignore
    MuiButton: muiTheme.components?.MuiButton?.styleOverrides,
    // @ts-ignore
    MuiTypography: {
      colorTextSecondary: {
        color: customPalette.mediumText,
      },
      ...muiTheme?.components?.MuiTypography?.styleOverrides,
    },
    // @ts-ignore
    MuiAccordion: muiTheme?.components?.MuiAccordion?.styleOverrides,
    // @ts-ignore
    MuiPaper: muiTheme?.components?.MuiPaper?.styleOverrides,
    MuiTabs: {
      indicator: {
        backgroundColor: customPalette.white,
        height: 4,
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: customPalette.blue500,
        color: customPalette.white,
      },
    },
    MuiAccordionSummary: {
      content: {
        fontWeight: fontWeight.bold,
      },
    },
    MuiAvatar: {
      rounded: {
        borderRadius: borderRadius.medium,
      },
    },
  },
  props: {
    MuiLink: {
      color: 'inherit',
    },
    MuiTextField: {
      variant: 'filled',
      fullWidth: true,
    },
    MuiFormControl: {
      variant: 'filled',
      fullWidth: true,
    },
    MuiFilledInput: {
      fullWidth: true,
    },
    MuiButton: {
      // @ts-ignore types forbid this but works in runtime
      component: 'div',
      // Material-UI v5 dropped support for "default" color in buttons
      // "primary" is the new default now
      color: 'primary',
      disableElevation: true,
    },
    MuiDialog: {
      fullWidth: true,
      PaperProps: {
        elevation: 8,
      },
    },
    MuiDrawer: {
      PaperProps: {
        elevation: 8,
      },
    },
    MuiPaper: {
      elevation: 1,
    },
    MuiCard: {
      elevation: 1,
    },
    MuiAppBar: {
      color: 'default',
    },
  },
});

// css does not accept material ui types
const globalStyles = css`
  body,
  html {
    -webkit-font-smoothing: antialiased;
    height: 100%;
  }

  body {
    margin: 0;
    background-color: ${customPalette.superLightBlue};
    ${materialUITheme.typography.body2 as unknown as string};
    @font-face {
      font-family: 'Roboto';
      src: url(/Roboto-Medium.ttf);
    }
  }

  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  [hidden] {
    display: none !important;
  }

  a {
    color: ${customPalette.highText};
    text-decoration: none;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  .highlightLine {
    background-color: rgba(0, 173, 181, 0.3);
  }

  .translationKey {
    text-decoration: underline;
    text-decoration-style: dotted;
    cursor: pointer;
  }

  .translationKey:hover {
    background-color: rgba(0, 173, 181, 0.2);
  }

  .translationGlyph::before {
    content: '🔤';
    margin-left: 5px;
  }
`;

export const StyleProvider = (props: Props) => {
  const { language } = useLocale();
  const muiThemeNew = createThemeNew(muiTheme, getMuiLocale(language));
  return (
    <>
      <Global styles={globalStyles} />
      <ThemeProvider theme={materialUITheme}>
        <ThemeProviderNew theme={muiThemeNew}>
          <ResponsiveProvider>{props.children}</ResponsiveProvider>
        </ThemeProviderNew>
      </ThemeProvider>
    </>
  );
};
