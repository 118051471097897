import { useCallback, useMemo } from 'react';

import { useFragment as useApolloFragment, useQuery } from '@apollo/client';
import { Box, Skeleton, Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { GOOGLE_MAPS_TOKEN } from '../../../../src/config';
import { useDebouncedHandler } from '../../../../src/hooks/debounce';
import { useLocale } from '../../../../src/hooks/locale';
import { useMap } from '../../../../src/hooks/map';
import { type StepCoverPhotoFragment } from '../../../__generated__/graphql';
import {
  type FormDefinitionType,
  RaForm,
  type RaFormOnChange,
} from '../../../components/form/RaForm';
import {
  DOC_TEMPLATES_CMA_REPORT,
  STEP_COVER_PHOTO_FRAGMENT,
} from '../cmaReportsQueries';
import {
  type CMAReportComponentProps,
  FooterActions,
  useUpdateCmaReport,
} from '../shared';

import CoverPhotoSelector from './CoverPhotoSelector';

const getSatelliteImageUrl = (lat: number, lng: number) => {
  return `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=16&size=400x200&maptype=satellite&markers=color:red|${lat},${lng}&style=feature:poi|element:labels|visibility:off&scale=2&key=${GOOGLE_MAPS_TOKEN}&channel=crm`;
};

type StepCoverForm = {
  contact_id: string;
  broker_id: string;
  language: string;
  doc_template_id: string;
};

const StepCoverPhoto = (props: CMAReportComponentProps) => {
  const { cmaReportId } = props;
  const { t } = useLocale();
  const localImages = useMap<string>();
  const [searchParams, setSearchParams] = useSearchParams();

  const { complete, data } = useApolloFragment({
    fragment: STEP_COVER_PHOTO_FRAGMENT,
    fragmentName: 'StepCoverPhoto',
    from: {
      __typename: 'cma_reports',
      id: cmaReportId,
    },
  });

  const { data: docTemplatesData } = useQuery(DOC_TEMPLATES_CMA_REPORT);

  const [updateCmaReport, updating] = useUpdateCmaReport(
    cmaReportId,
    'page-cover',
  );

  const update = useCallback(
    async (formData?: Partial<StepCoverForm>) => {
      await updateCmaReport({
        contact_id: formData?.contact_id,
        broker_id: formData?.broker_id,
        language: formData?.language,
        doc_template_id: formData?.doc_template_id,
      });
    },
    [updateCmaReport],
  );

  const debouncedUpdate = useDebouncedHandler(300, update);

  const onChangeHandler: RaFormOnChange<StepCoverForm> = useCallback(
    (formData, name) => {
      if (name === 'language' && formData?.language) {
        searchParams.set('language', formData.language);
        setSearchParams(searchParams);
      }
      debouncedUpdate(formData);
    },
    [debouncedUpdate, searchParams, setSearchParams],
  );

  const satelliteImageUrl = useMemo(
    () =>
      getSatelliteImageUrl(
        data?.lead?.property?.lat ?? 0,
        data?.lead?.property?.lng ?? 0,
      ),
    [data?.lead?.property?.lat, data?.lead?.property?.lng],
  );

  const languageItems = useMemo(
    () => [
      { value: 'en', label: t('english') },
      { value: 'fr', label: t('french') },
      { value: 'it', label: t('italian') },
      { value: 'es', label: t('spanish') },
      { value: 'de', label: t('german') },
    ],
    [t],
  );

  const cmaStepCoverFormDefinition: FormDefinitionType<StepCoverForm> =
    useCallback(
      ({ t }) => [
        {
          name: 'broker_id',
          label: t('Report created by'),
          type: 'user',
          gridProps: { sm: 12, md: 12 },
        },
        {
          name: 'contact_id',
          label: t('Report created for'),
          type: 'user',
          gridProps: { md: 12 },
        },
        {
          name: 'language',
          label: t('Language'),
          type: 'select',
          options: () => languageItems ?? [],
          gridProps: { md: 12 },
        },
        {
          name: 'doc_template_id',
          label: t('template'),
          type: 'select',
          options: () =>
            docTemplatesData?.doc_templates
              .filter(template => template.active)
              .map(template => ({
                value: template.id,
                label: template.name,
              })) ?? [],
          gridProps: { md: 12 },
        },
        {
          type: 'custom',
          name: 'cover',
          element: (
            <CoverPhotoSelector
              satelliteImageUrl={satelliteImageUrl}
              data={data as StepCoverPhotoFragment}
              updateCmaReport={updateCmaReport}
              localImages={localImages}
            />
          ),
          gridProps: { md: 12 },
        },
      ],
      [
        data,
        docTemplatesData?.doc_templates,
        languageItems,
        localImages,
        satelliteImageUrl,
        updateCmaReport,
      ],
    );

  const onSubmit = useCallback(
    async (formData: StepCoverForm) => {
      await update(formData);
      props.setStep(props.step + 1);
    },
    [update, props],
  );

  if (!complete) {
    return (
      <Box sx={{ flexGrow: 1, position: 'relative', overflowY: 'auto', p: 2 }}>
        <Stack gap={3}>
          {Array.from({ length: 4 }).map((_, index) => (
            <Stack gap={1} key={`step-cover-skeleton-${index}`}>
              <Skeleton variant="rounded" height={20} width="30%" />
              <Skeleton variant="rounded" height={40} />
            </Stack>
          ))}
          <Skeleton variant="rectangular" height={200} />
        </Stack>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, position: 'relative', overflowY: 'auto' }}>
      <RaForm
        freezeInitialDefaultValues={true}
        formDefinition={cmaStepCoverFormDefinition}
        onSubmit={onSubmit}
        defaultValues={{
          broker_id: data.broker?.id,
          contact_id: data.contact?.id,
          doc_template_id: data.doc_template?.id,
          language: data.language,
        }}
        onChange={onChangeHandler}
        contentScrollable
        actionButtonsComponent={
          <FooterActions<StepCoverForm>
            {...props}
            hideBackButton
            updating={updating}
          />
        }
      />
    </Box>
  );
};

export default StepCoverPhoto;
