import { useState } from 'react';

import { gql, useSuspenseQuery } from '@apollo/client';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { toGlobalId } from '../../../shared/global-id';
import { TimeAgo } from '../../../src/components/TimeAgo';
import { useLocale } from '../../../src/hooks/locale';
import type { AppraisalRequestsCardQuery } from '../../__generated__/graphql';
import { USER_PHONE_NUMBERS_FRAGMENT } from '../../components/contact-card/ContactCard';
import { formatPhoneNumber } from '../../components/phone-input';
import { USER_AVATAR_FRAGMENT, UserAvatar } from '../../components/UserAvatar';

const APPRAISAL_REQUESTS_CARD = gql`
  ${USER_AVATAR_FRAGMENT}
  ${USER_PHONE_NUMBERS_FRAGMENT}
  query AppraisalRequestsCard($id: uuid!) {
    leads_by_pk(id: $id) {
      id
      lead_agents(
        where: { source: { _eq: result_page } }
        order_by: { created_at: asc }
      ) {
        created_at
        user {
          ...UserAvatar
          ...UserPhoneNumbers
          default_team {
            name
          }
        }
      }
    }
  }
`;

export const AppraisalRequestsCard = ({
  leadId,
  defaultExpanded,
}: {
  leadId: string;
  defaultExpanded: boolean;
}) => {
  const { data } = useSuspenseQuery<AppraisalRequestsCardQuery>(
    APPRAISAL_REQUESTS_CARD,
    {
      variables: {
        id: leadId,
      },
    },
  );
  const agents = data.leads_by_pk?.lead_agents ?? [];
  const { t } = useLocale();
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(v => !v)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        {t('On-site appraisal requests ({{requests}})', {
          requests: agents.length ?? 0,
        })}
      </AccordionSummary>
      {agents.length > 0 ? (
        <List>
          {agents.map(({ user: agent, created_at }) => {
            return (
              <ListItem
                key={agent.id}
                secondaryAction={
                  <Typography variant="caption" color="text.secondary">
                    <TimeAgo addSuffix={true}>{created_at}</TimeAgo>
                  </Typography>
                }
              >
                <ListItemAvatar>
                  {agent != null && <UserAvatar user={agent} />}
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Link to={`/v1/users/${toGlobalId('User', agent.id)}`}>
                      <Typography variant="body2" fontWeight={500}>
                        {`${agent.first_name ?? ''} ${agent.last_name ?? ''}`}
                      </Typography>
                    </Link>
                  }
                  secondary={
                    <Stack>
                      <Typography
                        variant="caption"
                        sx={{
                          maxWidth: '60%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {agent.default_team?.name ?? ''}
                      </Typography>
                      <Typography variant="caption">
                        {formatPhoneNumber(
                          agent.phone_numbers[0]?.number ?? '',
                        )}
                      </Typography>
                    </Stack>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      ) : (
        <AccordionDetails>
          <Typography>{t('No on-site appraisal requests')}</Typography>
        </AccordionDetails>
      )}
    </Accordion>
  );
};
