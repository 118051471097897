import { useCallback, useEffect, useRef } from 'react';

import { useFragment as useApolloFragment } from '@apollo/client';
import { Box, Button, Skeleton } from '@mui/material';

import { convertHtmlToMarkdown } from '../../../shared/composer';
import { useDebouncedHandler } from '../../../src/hooks/debounce';
import {
  type FormDefinitionType,
  RaForm,
  type RaFormOnChange,
} from '../../components/form/RaForm';

import { STEP_INTRODUCTION_FRAGMENT } from './cmaReportsQueries';
import {
  type CMAReportComponentProps,
  FooterActions,
  type FooterActionsRef,
  useUpdateCmaReport,
} from './shared';

type StepIntroductionForm = {
  introduction: string;
};

const StepIntroduction = (props: CMAReportComponentProps) => {
  const { cmaReportId, iFrameRef } = props;

  const { data, complete } = useApolloFragment({
    fragment: STEP_INTRODUCTION_FRAGMENT,
    fragmentName: 'StepIntroduction',
    from: {
      __typename: 'cma_reports',
      id: cmaReportId,
    },
  });

  const [updateCmaReport, updating] = useUpdateCmaReport(
    cmaReportId,
    'page-introduction',
  );

  const footerActionsRef =
    useRef<FooterActionsRef<StepIntroductionForm> | null>(null);

  const fillFromTemplate = useCallback(() => {
    const introductionTemplate =
      iFrameRef.current?.contentDocument?.body?.querySelector<HTMLElement>(
        '#introduction-template',
      );

    if (introductionTemplate?.innerHTML) {
      try {
        const markdown = convertHtmlToMarkdown(introductionTemplate.innerHTML);
        footerActionsRef.current?.setValue('introduction', markdown, {
          shouldDirty: true,
        });
      } catch (error) {
        console.error('Error converting HTML to Markdown:', error);
      }
    } else {
      console.warn('Introduction template not found or empty');
    }
  }, [iFrameRef]);

  const stepIntroductionFormDefinition: FormDefinitionType<StepIntroductionForm> =
    useCallback(
      ({ t }) => [
        {
          name: 'introduction-category',
          type: 'category-title',
          label: t('Introduction'),
        },
        {
          name: 'introduction',
          label: t('Introduction'),
          type: 'rich-text',
          gridProps: { md: 12 },
          actions: () => (
            <Button variant="text" size="small" onClick={fillFromTemplate}>
              {t('reset')}
            </Button>
          ),
        },
      ],
      [fillFromTemplate],
    );

  useEffect(() => {
    if (!data?.introduction) {
      fillFromTemplate();
    }
  }, [data, fillFromTemplate]);

  const update = useCallback(
    async (formData?: Partial<StepIntroductionForm>) => {
      await updateCmaReport({
        introduction: formData?.introduction ?? '',
      });
    },
    [updateCmaReport],
  );

  const debouncedUpdate = useDebouncedHandler(300, update);

  const onChangeHandler: RaFormOnChange<StepIntroductionForm> = formData => {
    debouncedUpdate(formData);
  };

  const onSubmit = useCallback(
    async (formData: StepIntroductionForm) => {
      await update(formData);
      props.setStep(props.step + 1);
    },
    [update, props],
  );

  if (!complete) {
    return (
      <Skeleton height={400} sx={{ my: 3, mx: 1 }} variant="rectangular" />
    );
  }

  return (
    <Box sx={{ flexGrow: 1, position: 'relative', overflowY: 'auto' }}>
      <RaForm
        freezeInitialDefaultValues={true}
        formDefinition={stepIntroductionFormDefinition}
        onSubmit={onSubmit}
        defaultValues={{
          introduction: data?.introduction ?? '',
        }}
        onChange={onChangeHandler}
        actionButtonsComponent={
          <FooterActions<StepIntroductionForm>
            {...props}
            ref={footerActionsRef}
            updating={updating}
          />
        }
        contentScrollable
      />
    </Box>
  );
};

export default StepIntroduction;
