import { useCallback, useEffect, useMemo, useState } from 'react';

import { gql, useMutation, useQuery } from '@apollo/client';
import Save from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from '@mui/material';

import { useLocale } from '../../../src/hooks/locale';
import type {
  GetPipelinesQuery,
  GetPipelinesQueryVariables,
  UpdateMultipleLeadsStageMutation,
} from '../../__generated__/graphql';

const GET_PIPELINES = gql`
  query GetPipelines($leadType: lead_type!) {
    pipelines(where: { lead_type: { _eq: $leadType } }) {
      id
      label
      stages {
        id
        label
      }
    }
  }
`;

const UPDATE_MULTIPLE_LEADS_STAGE = gql`
  mutation UpdateMultipleLeadsStage($ids: [uuid!]!, $stageId: uuid!) {
    update_leads(where: { id: { _in: $ids } }, _set: { stage_id: $stageId }) {
      returning {
        id
        stage {
          label
        }
      }
    }
  }
`;

type DialogUpdatePipelineProps = {
  leadsIds: string[];
  pipeline: string;
  initialPipelineId: string | null;
  open: boolean;
  onCancel: () => void;
};

const DialogUpdatePipeline = ({
  leadsIds,
  pipeline,
  open,
  initialPipelineId,
  onCancel,
}: DialogUpdatePipelineProps) => {
  const { t } = useLocale();
  const [selectedPipelineId, setSelectedPipelineId] = useState<string>(
    initialPipelineId ?? '',
  );
  const [selectedStageId, setSelectedStageId] = useState<string>('');

  const { data } = useQuery<GetPipelinesQuery, GetPipelinesQueryVariables>(
    GET_PIPELINES,
    { variables: { leadType: pipeline } },
  );

  const [updateMultipleLeadsStage, { loading }] =
    useMutation<UpdateMultipleLeadsStageMutation>(UPDATE_MULTIPLE_LEADS_STAGE);

  useEffect(() => {
    if (initialPipelineId && !selectedPipelineId) {
      setSelectedPipelineId(initialPipelineId);
    }
  }, [initialPipelineId, selectedPipelineId]);

  const pipelines = useMemo(() => data?.pipelines ?? [], [data]);

  const stages = useMemo(() => {
    let selectedPipeline = null;

    if (pipelines.length === 1) {
      selectedPipeline = pipelines[0];
    } else if (selectedPipelineId) {
      selectedPipeline = pipelines.find(p => p.id === selectedPipelineId);
    }

    return selectedPipeline?.stages ?? [];
  }, [pipelines, selectedPipelineId]);

  const onSubmit = useCallback(async () => {
    if (!selectedStageId) {
      return;
    }

    await updateMultipleLeadsStage({
      variables: {
        ids: leadsIds,
        stageId: selectedStageId,
      },
    });

    setSelectedPipelineId(initialPipelineId ?? '');
    setSelectedStageId('');
    onCancel();
  }, [
    selectedStageId,
    updateMultipleLeadsStage,
    leadsIds,
    initialPipelineId,
    onCancel,
  ]);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      PaperProps={{
        style: {
          width: '100%',
          maxWidth: 400,
          margin: '0 auto',
        },
      }}
    >
      <DialogTitle>
        {pipelines.length > 1
          ? t('Change pipeline and stage')
          : t('Change stage')}
      </DialogTitle>
      <DialogContent>
        {pipelines.length > 1 && (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
              {t('Pipeline')}
            </FormLabel>
            <Select
              fullWidth
              variant="outlined"
              size="small"
              sx={{ background: 'white' }}
              value={selectedPipelineId}
              onChange={e => {
                setSelectedPipelineId(e.target.value as string);
                setSelectedStageId('');
              }}
            >
              {pipelines.map(pipeline => (
                <MenuItem key={pipeline.id} value={pipeline.id}>
                  {pipeline.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl fullWidth>
          <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
            {t('stage')}
          </FormLabel>
          <Select
            fullWidth
            variant="outlined"
            size="small"
            sx={{ background: 'white' }}
            value={selectedStageId}
            onChange={e => setSelectedStageId(e.target.value as string)}
          >
            {stages.map(stage => (
              <MenuItem key={stage.id} value={stage.id}>
                {stage.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ pb: 2, px: 3 }}>
        <Button onClick={onCancel}>{t('cancel')}</Button>
        <LoadingButton
          onClick={onSubmit}
          loadingPosition="start"
          startIcon={<Save />}
          loading={loading}
          color="primary"
          variant="contained"
          disabled={!selectedStageId}
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogUpdatePipeline;
