import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import Sort from '@mui/icons-material/Sort';
import { Box, FormControl, FormControlLabel, Radio } from '@mui/material';

import { Filter } from '../../../../../src/controls/Filters/Filter';
import { useLocale } from '../../../../../src/hooks/locale';
import type { CompositePropertyType } from '../../../../../src/routes/Documents/compose-property-type';
import type { Merged_Listings_Compare_Args } from '../../../../__generated__/graphql';

const SortFilter = ({
  composeValue,
  filters,
  setFilters,
}: {
  composeValue: CompositePropertyType;
  filters: Partial<Merged_Listings_Compare_Args>;
  setFilters: (filters: Partial<Merged_Listings_Compare_Args>) => void;
}) => {
  const { t } = useLocale();

  const sortItems = [
    {
      field: 'id',
      label: t('sortByDate'),
      direction: 'desc',
    },
    {
      field: 'sale_price',
      label: t('sortBySalePrice'),
      direction: 'asc',
    },
    {
      field: 'sale_price',
      label: t('sortBySalePrice'),
      direction: 'desc',
    },
    {
      field: 'computed_price_per_sqm',
      label: t('sortByPricePerMeter'),
      direction: 'asc',
    },
    {
      field: 'computed_price_per_sqm',
      label: t('sortByPricePerMeter'),
      direction: 'desc',
    },
  ];

  if (['COMMERCIAL', 'HOUSE', 'HOUSE_APPT'].includes(composeValue)) {
    sortItems.push(
      {
        field: 'sale_price_per_land_surface',
        label: t('sortByPricePerLandMeter'),
        direction: 'asc',
      },
      {
        field: 'sale_price_per_land_surface',
        label: t('sortByPricePerLandMeter'),
        direction: 'desc',
      },
    );
  }

  const currentSortItem = sortItems.find(
    item =>
      item.field === filters.sort_by && item.direction === filters.sort_dir,
  );
  const label = (
    <Box display="flex" alignItems="center">
      {currentSortItem?.field !== 'created_at' && <Sort sx={{ mr: 1 }} />}
      {currentSortItem?.label ?? ''}
    </Box>
  );

  return (
    <Filter empty={true} label={label} dialogTitle={t('sortBy')}>
      <Box width="280px" p={2}>
        <FormControl>
          {sortItems.map(item => (
            <FormControlLabel
              key={item.label}
              label={
                <Box display="flex">
                  {item.label}
                  {item.direction === 'desc' ? (
                    <ArrowDropDown />
                  ) : (
                    <ArrowDropUp />
                  )}
                </Box>
              }
              checked={
                item.field === filters.sort_by &&
                item.direction === filters.sort_dir
              }
              control={
                <Radio
                  color="primary"
                  onClick={() => {
                    setFilters({
                      sort_by: item.field,
                      sort_dir: item.direction,
                    });
                  }}
                />
              }
            />
          ))}
        </FormControl>
      </Box>
    </Filter>
  );
};

export default SortFilter;
