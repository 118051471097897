/**
 * @generated SignedSource<<71d6ac06b93d07d17e01b969156cfc4b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserGender = "female" | "male";
export type UserLanguage = "de" | "en" | "es" | "fr" | "it";
export type UserTitle = "doctor" | "madam" | "professor" | "sir";
import { FragmentRefs } from "relay-runtime";
export type EmailForm_me$data = {
  readonly emailSignature: string | null;
  readonly firstName: string | null;
  readonly gender: UserGender | null;
  readonly id: string;
  readonly language: UserLanguage | null;
  readonly lastName: string | null;
  readonly organisation: {
    readonly formattedAddress: string | null;
    readonly id: string;
    readonly name: string | null;
  } | null;
  readonly primaryEmail: {
    readonly email: string;
    readonly id: string;
  } | null;
  readonly primaryPhoneNumber: {
    readonly formattedNumber: string;
    readonly id: string;
  } | null;
  readonly title: UserTitle | null;
  readonly " $fragmentType": "EmailForm_me";
};
export type EmailForm_me$key = {
  readonly " $data"?: EmailForm_me$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmailForm_me">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmailForm_me",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Email",
      "kind": "LinkedField",
      "name": "primaryEmail",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PhoneNumber",
      "kind": "LinkedField",
      "name": "primaryPhoneNumber",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formattedNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organisation",
      "kind": "LinkedField",
      "name": "organisation",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formattedAddress",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "language",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailSignature",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "22853f8058d45ab3992748166a403385";

export default node;
