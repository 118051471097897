import { useCallback, useState } from 'react';

import { useFragment as useApolloFragment, useMutation } from '@apollo/client';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { type UseFormSetError } from 'react-hook-form';

import { useDebouncedHandler } from '../../../src/hooks/debounce';
import { useLocale } from '../../../src/hooks/locale';
import { getCurrencyByCountryCode } from '../../../src/locale';
import {
  Cma_Reports_Construction_Base_Enum_Enum,
  type Cma_Reports_Set_Input,
} from '../../__generated__/graphql';
import { Drawer } from '../../components/drawer/Drawer';
import {
  type FormDefinitionType,
  RaForm,
  type RaFormOnChange,
} from '../../components/form/RaForm';
import { PropertyForm } from '../../components/property-form/PropertyForm';
import { UPDATE_PROPERTY } from '../../components/property-form/propertyFormQueries';
import { getCurrencySymbol } from '../../utils/formatting';
import { outOfRange } from '../../utils/validation';

import { STEP_OTHER_VALUATIONS_FRAGMENT } from './cmaReportsQueries';
import {
  type CMAReportComponentProps,
  FooterActions,
  useUpdateCmaReport,
} from './shared';

type StepOtherValuationsForm = {
  include_cuprate_valuation: boolean;
  caprate_estimated_monthly_rent: number | null | undefined;
  caprate_minimum_required_yield: number | null | undefined;
  include_intrinsic_valuation: boolean;
  construction_cost_base: Cma_Reports_Set_Input['construction_cost_base'];
  building_volume: number | null | undefined;
  intrinsic_building_surface: number | null | undefined;
  intrinsic_construction_cost: number | null | undefined;
  construction_year: number | null | undefined;
  renovation_year: number | null | undefined;
  intrinsic_annual_deprecation_rate: number | null | undefined;
  land_surface: number | null | undefined;
  intrinsic_land_value: number | null | undefined;
};

const PROPERTY_FIELDS = [
  'building_volume',
  'construction_year',
  'renovation_year',
  'land_surface',
];

const CURRENT_YEAR = new Date().getFullYear();

const StepOtherValuations = (props: CMAReportComponentProps) => {
  const { t, locale, countryCode } = useLocale();
  const { cmaReportId } = props;
  const [open, setOpen] = useState(false);

  const { data, complete } = useApolloFragment({
    fragment: STEP_OTHER_VALUATIONS_FRAGMENT,
    fragmentName: 'StepOtherValuations',
    from: {
      __typename: 'cma_reports',
      id: cmaReportId,
    },
  });

  const latestAppraisal = data?.lead?.property?.latest_appraisal;

  const [updateCmaReport, updating] = useUpdateCmaReport(
    cmaReportId,
    'page-other-valuations',
  );

  const [updateProperty] = useMutation(UPDATE_PROPERTY);

  const update = useCallback(
    async (formData?: Partial<StepOtherValuationsForm>) => {
      await updateCmaReport({
        include_cuprate_valuation: formData?.include_cuprate_valuation,
        caprate_estimated_monthly_rent:
          formData?.caprate_estimated_monthly_rent,
        caprate_minimum_required_yield:
          formData?.caprate_minimum_required_yield,
        include_intrinsic_valuation: formData?.include_intrinsic_valuation,
        construction_cost_base: formData?.construction_cost_base,
        intrinsic_building_surface: formData?.intrinsic_building_surface,
        intrinsic_construction_cost: formData?.intrinsic_construction_cost,
        intrinsic_annual_deprecation_rate:
          formData?.intrinsic_annual_deprecation_rate,
        intrinsic_land_value: formData?.intrinsic_land_value,
      });
    },
    [updateCmaReport],
  );

  const debouncedUpdate = useDebouncedHandler(300, update);

  const onChangeHandler: RaFormOnChange<StepOtherValuationsForm> = useCallback(
    async (formData, name, { runValidation }) => {
      if (name && PROPERTY_FIELDS.includes(name)) {
        if (runValidation() === false) {
          return;
        }
        const value = formData?.[name as keyof typeof formData];
        await updateProperty({
          variables: {
            id: data?.lead?.property?.id ?? '',
            property: {
              [name]: value,
            },
          },
        });
      }

      debouncedUpdate(formData);
    },
    [updateProperty, data?.lead?.property?.id, debouncedUpdate],
  );

  const stepOtherValuationsFormDefinition = useCallback<
    FormDefinitionType<StepOtherValuationsForm>
  >(
    ({ t }) => [
      {
        type: 'custom',
        name: 'property-details',
        element: (
          <>
            <Typography variant="subtitle2" fontWeight={700}>
              {t('Property')}
            </Typography>
            <Box py={1 / 2}>
              <Button
                endIcon={<ChevronRight />}
                size="small"
                onClick={() => setOpen(true)}
                sx={{ display: 'inline-flex' }}
              >
                {t('editPropertyDetails')}
              </Button>
            </Box>
          </>
        ),
      },
      {
        type: 'category-title',
        label: t('capRateValuation'),
        gridProps: { md: 12 },
        name: 'caprate-valuation',
      },
      {
        name: 'include_cuprate_valuation',
        label: t('includeInReport'),
        type: 'checkbox',
        style: 'switch',
        gridProps: { md: 12 },
      },
      {
        name: 'caprate_estimated_monthly_rent',
        label: t('estimatedMonthlyRent'),
        type: 'number',
        prefix: getCurrencySymbol(
          getCurrencyByCountryCode(countryCode),
          locale,
        ),
        gridProps: { md: 12 },
        min: 0,
        max: 999_999_999,
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_cuprate_valuation,
      },
      {
        name: 'caprate_minimum_required_yield',
        label: t('minimumRequiredYield'),
        type: 'number',
        min: 0,
        max: 100,
        suffix: '%',
        decimalNumbers: 2,
        gridProps: { md: 12 },
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_cuprate_valuation,
      },
      {
        type: 'category-title',
        label: t('intrinsicValuation'),
        gridProps: { md: 12 },
        name: 'intrinsic-valuation',
      },
      {
        name: 'include_intrinsic_valuation',
        label: t('includeInReport'),
        type: 'checkbox',
        style: 'switch',
        gridProps: { md: 12 },
      },
      {
        name: 'construction_cost_base',
        label: t('constructionCostBasedOn'),
        type: 'select',
        options: () => [
          { value: 'volume', label: t('volume') },
          { value: 'surface', label: t('surface') },
        ],
        gridProps: { md: 12 },
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_intrinsic_valuation,
      },
      {
        name: 'building_volume',
        label: t('buildingVolume'),
        type: 'number',
        suffix: 'm³',
        min: 40,
        max: 20_000,
        decimalNumbers: 1,
        gridProps: { md: 12 },
        render: (formData: StepOtherValuationsForm) =>
          formData.construction_cost_base === 'volume',
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_intrinsic_valuation,
      },
      {
        name: 'intrinsic_building_surface',
        label: t('surface'),
        type: 'number',
        suffix: 'm²',
        decimalNumbers: 1,
        min: 0,
        max: 50_000,
        gridProps: { md: 12 },
        render: (formData: StepOtherValuationsForm) =>
          formData.construction_cost_base === 'surface',
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_intrinsic_valuation,
      },
      {
        name: 'intrinsic_construction_cost',
        label: t('constructionCostPerSquareMeter'),
        type: 'number',
        gridProps: { md: 12 },
        prefix: getCurrencySymbol(
          getCurrencyByCountryCode(countryCode),
          locale,
        ),
        render: (formData: StepOtherValuationsForm) =>
          formData.construction_cost_base === 'surface',
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_intrinsic_valuation,
      },
      {
        name: 'intrinsic_construction_cost',
        label: t('constructionCostPerCubicMeter'),
        type: 'number',
        gridProps: { md: 12 },
        prefix: getCurrencySymbol(
          getCurrencyByCountryCode(countryCode),
          locale,
        ),
        render: (formData: StepOtherValuationsForm) =>
          formData.construction_cost_base !== 'surface',
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_intrinsic_valuation,
      },
      {
        name: 'construction_year',
        label: t('Construction year'),
        gridProps: { md: 12 },
        type: 'number',
        disableFormatting: true,
        max: CURRENT_YEAR,
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_intrinsic_valuation,
      },
      {
        name: 'renovation_year',
        label: t('Renovation year'),
        type: 'number',
        disableFormatting: true,
        max: CURRENT_YEAR,
        gridProps: { md: 12 },
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_intrinsic_valuation,
      },
      {
        name: 'intrinsic_annual_deprecation_rate',
        label: t('annualDeprecationRate'),
        type: 'number',
        suffix: '%',
        min: 0,
        max: 100,
        decimalNumbers: 2,
        gridProps: { md: 12 },
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_intrinsic_valuation,
      },
      {
        name: 'land_surface',
        label: t('landSurface'),
        type: 'number',
        min: 0,
        max: 500_000,
        suffix: 'm²',
        gridProps: { md: 12 },
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_intrinsic_valuation,
      },
      {
        name: 'intrinsic_land_value',
        label: t('landValuePerSquareMeter'),
        type: 'number',
        prefix: getCurrencySymbol(
          getCurrencyByCountryCode(countryCode),
          locale,
        ),
        gridProps: { md: 12 },
        disabled: (formData: StepOtherValuationsForm) =>
          !formData.include_intrinsic_valuation,
      },
    ],
    [locale, countryCode],
  );

  const onSubmit = async (formData: StepOtherValuationsForm) => {
    await update(formData);
    props.setStep(props.step + 1);
  };

  return (
    <>
      <Drawer open={open} onClose={() => setOpen(false)} title={t('Property')}>
        <PropertyForm propertyId={data?.lead?.property?.id} />
      </Drawer>
      <Box
        sx={{
          flexGrow: 1,
          position: 'relative',
          overflowY: 'auto',
          p: !complete ? 2 : undefined,
        }}
      >
        {!complete ? (
          <Stack gap={4}>
            <Stack gap={1}>
              <Skeleton variant="rounded" width={'30%'} height={30} />
              <Skeleton variant="rounded" width={'50%'} height={40} />
            </Stack>
            <Stack gap={2}>
              <Skeleton variant="rounded" height={20} />
              <Stack direction={'row'}>
                <Skeleton
                  variant="circular"
                  width={24}
                  height={24}
                  sx={{ mr: 2 }}
                />
                <Skeleton variant="rounded" width={'30%'} height={24} />
              </Stack>
              <Stack gap={3}>
                {Array.from({ length: 2 }).map((_, index) => (
                  <Skeleton
                    variant="rounded"
                    key={`caprate-${index}`}
                    height={50}
                  />
                ))}
              </Stack>
            </Stack>
            <Stack gap={2}>
              <Skeleton variant="rounded" height={20} />
              <Stack direction={'row'}>
                <Skeleton
                  variant="circular"
                  width={24}
                  height={24}
                  sx={{ mr: 2 }}
                />
                <Skeleton variant="rounded" width={'30%'} height={24} />
              </Stack>
              <Stack gap={3}>
                {Array.from({ length: 2 }).map((_, index) => (
                  <Skeleton
                    variant="rounded"
                    key={`intrinsic-${index}`}
                    height={50}
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <RaForm
            freezeInitialDefaultValues={true}
            formDefinition={stepOtherValuationsFormDefinition}
            defaultValues={{
              include_cuprate_valuation:
                data?.include_cuprate_valuation ?? false,
              caprate_estimated_monthly_rent:
                data?.lead?.property?.latest_appraisal?.rent_value,
              caprate_minimum_required_yield: latestAppraisal
                ? ((12 * (latestAppraisal?.rent_value ?? 0)) /
                    (latestAppraisal?.value ?? 0)) *
                  100
                : null,
              include_intrinsic_valuation:
                data?.include_intrinsic_valuation ?? false,
              construction_cost_base:
                data?.construction_cost_base ??
                Cma_Reports_Construction_Base_Enum_Enum.Volume,
              building_volume: data?.lead?.property?.building_volume,
              intrinsic_building_surface: data?.lead?.property
                ? Math.max(
                    data.lead.property.living_surface ?? 0,
                    data.lead.property.usable_surface ?? 0,
                    data.lead.property.basement_surface ?? 0,
                    data.lead.property.commercial_surface ?? 0,
                    data.lead.property.balcony_surface ?? 0,
                    data.lead.property.garden_surface ?? 0,
                    data.lead.property.gross_floor_surface ?? 0,
                    data.lead.property.residential_surface ?? 0,
                    data.lead.property.terrace_surface ?? 0,
                    data.lead.property.weighted_floor_surface ?? 0,
                  )
                : null,
              intrinsic_construction_cost: data?.intrinsic_construction_cost,
              construction_year: data?.lead?.property?.construction_year,
              renovation_year: data?.lead?.property?.renovation_year,
              intrinsic_annual_deprecation_rate:
                data?.intrinsic_annual_deprecation_rate,
              land_surface: data?.lead?.property?.land_surface,
              intrinsic_land_value: data?.intrinsic_land_value,
            }}
            validate={data => {
              const errors: Parameters<
                UseFormSetError<StepOtherValuationsForm>
              >[] = [];

              if (
                data.include_intrinsic_valuation &&
                data.construction_year &&
                data.renovation_year &&
                data.renovation_year < data.construction_year
              ) {
                errors.push([
                  'renovation_year',
                  {
                    message: t(
                      'Renovation year cannot be earlier than construction year',
                    ),
                  },
                ]);
              }

              if (outOfRange(data.building_volume, 40, 20000, true)) {
                errors.push([
                  'building_volume',
                  {
                    message: t('Building volume should be between', {
                      min: 40,
                      max: 20000,
                    }),
                  },
                ]);
              }

              return errors;
            }}
            onSubmit={onSubmit}
            contentScrollable
            onChange={onChangeHandler}
            actionButtonsComponent={
              <FooterActions<StepOtherValuationsForm>
                {...props}
                updating={updating}
              />
            }
          />
        )}
      </Box>
    </>
  );
};

export default StepOtherValuations;
