import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useSearchParams } from 'react-router-dom';

import { useLocale } from '../../../src/hooks/locale';
import { gql } from '../../__generated__';
import { type ListingBuyerLeadsFragment } from '../../__generated__/graphql';
import { CountActionCard, CountActionCardSkeleton } from '../CountActionCard';
import { ListingAccordion } from '../ListingAccordion';

interface ListingBuyerLeadsCardProps {
  buyerLeadsData?: ListingBuyerLeadsFragment | null;
  listingId?: string | null;
  isLoading?: boolean;
  openedDefault?: boolean;
}

export const LISTING_BUYER_LEADS_FRAGMENT = gql(`
  fragment ListingBuyerLeads on lots {
    buyer_leads(
      where: {
        _and: [
          { source: { _in: ["matching", "manual"] } }
          { user: { is_deleted: { _eq: false } } }
        ]
      }
    ) {
      id
      source
    }
    enquiries_aggregate(where: { buyer_lead_id: { _is_null: false } }) {
      aggregate {
        count
      }
    }
  }
`);

export const ListingBuyerLeadsCardSkeleton: React.FC<{
  openedDefault?: boolean;
}> = ({ openedDefault }) => {
  const { t } = useLocale();
  return (
    <ListingAccordion title={t('Buyer leads')} defaultExpanded={openedDefault}>
      <CountActionCardSkeleton
        countLabels={[t('Matches'), t('Enquiries'), t('Other')]}
        actions={[
          {
            label: t('Add buyer lead'),
            icon: <AddCircleOutlineOutlinedIcon />,
          },
          {
            label: t('View buyer leads'),
            icon: <VisibilityOutlinedIcon />,
          },
        ]}
      />
    </ListingAccordion>
  );
};

export const ListingBuyerLeadsCard: React.FC<ListingBuyerLeadsCardProps> = ({
  buyerLeadsData,
  openedDefault = false,
  listingId,
  isLoading = false,
}) => {
  const { t } = useLocale();
  const [searchParams] = useSearchParams();

  const matchingLeadsCount = buyerLeadsData?.buyer_leads.filter(
    ({ source }) => source === 'matching',
  ).length;
  const manualLeadsCount = buyerLeadsData?.buyer_leads.filter(
    ({ source }) => source === 'manual',
  ).length;

  return (
    <ListingAccordion title={t('Buyer leads')} defaultExpanded={openedDefault}>
      <CountActionCard
        isLoading={isLoading}
        countElements={[
          { label: t('Matches'), value: matchingLeadsCount },
          {
            label: t('Enquiries'),
            value: buyerLeadsData?.enquiries_aggregate.aggregate?.count,
          },
          { label: t('Other'), value: manualLeadsCount },
        ]}
        actions={[
          {
            label: t('Add buyer lead'),
            icon: <AddCircleOutlineOutlinedIcon />,
            link:
              listingId != null
                ? {
                    to: `/listings/${listingId}/buyers`,
                    search: searchParams.toString(),
                  }
                : undefined,
          },
          {
            label: t('View buyer leads'),
            icon: <VisibilityOutlinedIcon />,
            link:
              listingId != null
                ? {
                    to: `/listings/${listingId}/buyers`,
                    search: searchParams.toString(),
                  }
                : undefined,
          },
        ]}
      />
    </ListingAccordion>
  );
};
