import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Box, ButtonBase, LinearProgress, Typography } from '@mui/material';
import { useFileDialog } from '@realadvisor/hooks';

import { FullScreenDropZone } from '../../shared/dropzone';
import { useLocale } from '../../src/hooks/locale';

export const UploadImageCard: React.FC<{
  onClick: () => void;
  noMinHeight?: boolean;
  canDrop?: boolean;
  isLoading?: boolean;
  fullHeight?: boolean;
  error?: Error;
}> = ({
  onClick,
  noMinHeight = false,
  canDrop = true,
  isLoading = false,
  fullHeight = false,
  error,
}) => {
  const { t } = useLocale();
  return (
    <ButtonBase
      onClick={onClick}
      focusRipple={true}
      sx={theme => ({
        position: 'relative',
        border: `2px dashed ${
          error != null ? theme.palette.error.main : theme.palette.divider
        }`,
        borderRadius: 1,
        minHeight: noMinHeight ? 0 : '180px',
        height: fullHeight ? '100%' : undefined,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
      })}
    >
      {isLoading && (
        <Typography variant="h6" color="text.disabled">
          {t('Uploading...')}
        </Typography>
      )}
      {isLoading ? (
        <LinearProgress sx={{ width: '100%' }} />
      ) : (
        <>
          <CloudUploadOutlinedIcon
            sx={{
              fontSize: 48,
              color: error != null ? 'error.main' : 'text.disabled',
            }}
          />
          {error != null ? (
            <>
              <Typography variant="h6" color="error.main">
                {error.message}
              </Typography>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  bgcolor: 'rgba(255, 255, 255, 0.5)',
                  p: 1,
                }}
              >
                <pre style={{ fontSize: '0.9em' }}>
                  {JSON.stringify(error, null, 2)}
                </pre>
              </Box>
            </>
          ) : (
            <Typography variant="h6" color="text.disabled">
              {canDrop ? t('Drag or click to upload') : t('Click to upload')}
            </Typography>
          )}
        </>
      )}
    </ButtonBase>
  );
};

type UploadFileCardProps = {
  multiple?: boolean;
  onSelect: (files: File[]) => void;
  noMinHeight?: boolean;
} & (
  | {
      accept?: never;
      acceptLabel?: never;
    }
  | {
      accept: string;
      acceptLabel?: string;
    }
) &
  (
    | {
        allowDrop: false;
        disableDrop?: never;
      }
    | {
        allowDrop?: true;
        disableDrop?: boolean;
      }
  );

export const UploadFileCard: React.FC<UploadFileCardProps> = ({
  onSelect,
  accept,
  multiple = true,
  acceptLabel,
  noMinHeight = false,
  allowDrop = true,
  disableDrop = false,
}) => {
  const openFileDialog = useFileDialog({
    accept,
    onChange: onSelect,
    multiple,
  });

  return (
    <>
      {allowDrop && (
        <FullScreenDropZone
          onDrop={onSelect}
          accept={accept}
          acceptLabel={acceptLabel}
          disabled={disableDrop}
        />
      )}

      <UploadImageCard
        onClick={openFileDialog}
        canDrop={allowDrop}
        noMinHeight={noMinHeight}
      />
    </>
  );
};
