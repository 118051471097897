/**
 * @generated SignedSource<<89d456ff9d1fcb600eaff67cdd1d7615>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedEmailForm_root$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EmailFormDataWrapper_root">;
  readonly " $fragmentType": "FeedEmailForm_root";
};
export type FeedEmailForm_root$key = {
  readonly " $data"?: FeedEmailForm_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedEmailForm_root">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedEmailForm_root",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmailFormDataWrapper_root"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "5fad1efcb0550e2d1c6dc0d633e2016d";

export default node;
