import {
  getAnotherAppOrigin,
  getDeploymentByOrigin,
} from '@realadvisor/host-resolver';

const origin = window.location.origin;

// Try to detect if the app is running in a production environment
// so we could deploy the exact same Docker container with this
// static website to both production and dev/test environments.
export const isProduction: boolean =
  getDeploymentByOrigin(origin) === 'production';

export const webOrigin: string = getAnotherAppOrigin(origin, 'ch');
export const webOriginFr: string = getAnotherAppOrigin(origin, 'fr');
export const webOriginEs: string = getAnotherAppOrigin(origin, 'es');
export const webOriginDe: string = getAnotherAppOrigin(origin, 'de');
export const webOriginIt: string = getAnotherAppOrigin(origin, 'it');
export const webOriginGb: string = getAnotherAppOrigin(origin, 'gb');

export const auth_origin: string = getAnotherAppOrigin(origin, 'auth');
export const api_origin: string = getAnotherAppOrigin(origin, 'api');
export const hasura_origin: string = getAnotherAppOrigin(origin, 'hasura');
export const docs_origin: string = getAnotherAppOrigin(origin, 'docs');
export const email_service_origin: string = getAnotherAppOrigin(
  origin,
  'email_service',
);
export const scrapers_origin: string = isProduction
  ? 'https://hasura-scrapers-fqs3j3myvq-ew.a.run.app'
  : 'https://hasura-scrapers-ldpycpultq-ew.a.run.app';

// https://developers.facebook.com/apps/
export const facebookAppID: string = isProduction
  ? '1991177194472702'
  : '532033510522153';

export const googleClientId: string = isProduction
  ? '528868664444-gstsrjdd0fct0tb93j6o1mk239t0ubs0.apps.googleusercontent.com'
  : '1010967891658-ltm3n6ssndvj05i69hf71lt7dnmql0e9.apps.googleusercontent.com';

// To generate a new key go to https://www.google.com/recaptcha/admin/create
// You'll have to register a new site there
// Select reCAPTCHA type: v3
// Domains for prod key: realadvisor.ch, realadvisor.com
// Note that all previous recaptcha data will be lost in this case
// If you update this key, you also must update API secret and same key in aggregator
export const reCaptchaKeyV3: string = isProduction
  ? '6LcKjboUAAAAABji16i3iteLW3uAv8L9CPFSent5'
  : '6LeXU7sUAAAAAMTelLnLq_k0ZLe394mfcaqeixG7';
// We use visible captcha (v2) when v3 returns an error
export const reCaptchaKeyV2: string = isProduction
  ? '6LfcHr8UAAAAAHMcj7JrsddaXIfJs2i3jhVtALP0'
  : '6Lfqq70UAAAAAItZCZHflkXo7tmcN7IXdjjD3gB2';

export const MAPBOX_TOKEN = `pk.eyJ1Ijoic3Bpbmd3dW4iLCJhIjoiY2l3NHpjaWkzMDAwejJ0cnMyMHI0empsYiJ9._rYClg-PkZKrwdA2F7ucOw`;

// Keys have access to Google Maps JavaScript/Static/Place APIs.
// You can change this here: https://console.cloud.google.com/apis/credentials/
// Domains for prod key: realadvisor.ch/*, *.realadvisor.ch/*, *.realadvisor.com/*, realadvisor.io/*, *.realadvisor.io/*,
// Domains for dev key: localhost, *.realadvisor.ch/*, *.realadvisor.com/*, *.realadvisor.io/*,
export const GOOGLE_MAPS_TOKEN: string = isProduction
  ? `AIzaSyBX9Jv2PzHK5M8U10Mya9UbooH17uAdMfM` // RealAdvisor
  : `AIzaSyAiXjRcHWIG06Za14C4NyDyu9uK_P94LYA`; // RealAdvisor DEV

export const SENTRY_DSN =
  'https://ddcfee303db7c06f1bcf5c2b2c1d9be2@o4506361032343552.ingest.sentry.io/4506361038635008';
