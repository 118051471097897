import { Box, Card } from '@mui/material';

import type { SetHook } from '../../../../src/hooks/set';

import type { ListingAutomaticType } from './ListingAutomatic';
import ListingCardInfo from './ListingCardInfo';
import type { ListingManualType } from './ListingManual';
import { SelectableCard } from './SelectableCard';

type ListingGridBaseProps = {
  listings: ListingAutomaticType[] | ListingManualType[];
};

type ListingGridReadonlyProps = ListingGridBaseProps & {
  readonly: true;
  onSelect?: never;
  selection?: never;
};

type ListingGridEditableProps = ListingGridBaseProps & {
  readonly?: false;
  onSelect: (id: number) => void;
  selection: SetHook<number>;
};

type ListingGridProps = ListingGridReadonlyProps | ListingGridEditableProps;

const ListingGrid = ({
  listings,
  selection,
  onSelect,
  readonly,
}: ListingGridProps) => {
  return (
    <Box
      sx={{
        padding: { xs: 2, md: 3 },
        display: 'grid',
        gap: { xs: 2, sm: 3 },
        justifyContent: 'center',
        gridTemplateColumns: {
          xs: 'repeat(1, minmax(0, 400px))',
          sm: 'repeat(auto-fill, minmax(250px, 1fr))',
          md: 'repeat(auto-fill, minmax(280px, 1fr))',
          xl: 'repeat(auto-fill, minmax(300px, 1fr))',
        },
      }}
    >
      {readonly
        ? listings.map(listing => (
            <Card
              sx={{
                cursor: 'pointer',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ListingCardInfo listing={listing} variant="vertical" />
            </Card>
          ))
        : listings.map(listing => (
            <SelectableCard
              key={listing.id}
              selected={selection.has(listing.id)}
              onChange={() => onSelect(listing.id)}
            >
              <ListingCardInfo listing={listing} variant="vertical" />
            </SelectableCard>
          ))}
    </Box>
  );
};

export default ListingGrid;
