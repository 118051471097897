import { type MouseEventHandler, useState } from 'react';

import { type ApolloError } from '@apollo/client';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import { Image } from '@realadvisor/image';

import { toGlobalId } from '../../../shared/global-id';
import { useLocale } from '../../../src/hooks/locale';
import {
  getCurrencyByCountryCode,
  getDefaultLocationOnMap,
} from '../../../src/locale';
import { getStaticMapLink } from '../../../src/utils/static-map';
import { gql } from '../../__generated__';
import type { LeadCardFragment } from '../../__generated__/graphql';
import { useAppData } from '../../providers/AppDataProvider';
import { userCanViewLeadDetails } from '../../utils/listingPermissions';
import { ConditionalLinkWrapper } from '../ConditionalLinkWrapper';
import { LeadSelect } from '../LeadsSelect';
import { MutationErrorModal } from '../MutationErrorModal';

import { FinancingLeadDetail } from './FinancingLeadDetail';
import { SalesLeadDetail } from './SalesLeadDetail';

export const LEAD_CARD_FRAGMENT = gql(`
  fragment LeadCard on leads {
    id
    ...LeadQualificationFunnel
    ...LeadUpdatedTimeAgo
    ...FiancingLeadDetail
    ...SalesLeadDetail
    claimed_by
    created_by
    stage {
      id
      pipeline {
        id
        lead_type
      }
    }
    property {
      id
      lat
      lng
      country_code
    }
  }
`);

export enum ELeadCardDisplayMode {
  NORMAL,
  COMPACT,
}

interface LeadCardProps {
  lead: LeadCardFragment;
  showMap?: boolean;
  showAddress?: boolean;
  controls?: React.ReactNode;
  bottomButton?: React.ReactNode;
  openOnNewTab?: boolean;
  hasLink?: boolean;
  customCreationDate?: string;
  displayMode?: ELeadCardDisplayMode;
  legacyLinks?: boolean;
  onLeadChanged: (
    leadId: string | null,
    onError: (err: ApolloError) => void,
  ) => Promise<any>;
}

export const LeadCard: React.FC<React.PropsWithChildren<LeadCardProps>> = ({
  lead,
  hasLink = true,
  openOnNewTab = false,
  legacyLinks = true,
  onLeadChanged,
  children,
  ...restProps
}) => {
  const { t } = useLocale();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [updateError, setUpdateError] = useState<null | ApolloError>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  if (!lead) {
    return null;
  }

  const isMenuOpened = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const linkBase =
    lead.stage?.pipeline?.lead_type === 'financing' ? 'financing' : 'leads';
  const linkToLead = legacyLinks
    ? `/${linkBase}/${toGlobalId('Lead', lead.id)}`
    : `/${linkBase}/${lead.id}`;

  return (
    <>
      <Menu
        open={isMenuOpened}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
      >
        <MenuItem
          onClick={() => {
            setIsEditing(true);
            handleCloseMenu();
          }}
        >
          {t('Edit')}
        </MenuItem>
        <MenuItem
          onClick={async () => {
            setIsDeleting(true);
            handleCloseMenu();
            await onLeadChanged(null, setUpdateError);
            setIsDeleting(false);
          }}
        >
          {t('Remove')}
        </MenuItem>
      </Menu>
      <MutationErrorModal
        error={updateError}
        onClose={() => setUpdateError(null)}
      />

      {isEditing ? (
        <Stack direction="row" spacing={1}>
          <LeadSelect
            placeholder={t('Select a lead')}
            leadId={lead.id}
            sx={{ flexGrow: 1 }}
            autoFocus
            disabled={isUpdating}
            onChange={async lead => {
              if (lead == null) {
                return;
              }

              setIsUpdating(true);
              await onLeadChanged(lead.id, setUpdateError);
              setIsUpdating(false);
              setIsEditing(false);
            }}
          />
          <Button
            variant="outlined"
            onClick={() => setIsEditing(false)}
            disabled={isUpdating}
            size="small"
          >
            {t('Cancel')}
          </Button>

          <Backdrop
            open={isUpdating}
            sx={{
              zIndex: 1,
              position: 'absolute',
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
            }}
          >
            <CircularProgress disableShrink />
          </Backdrop>
        </Stack>
      ) : (
        <ConditionalLinkWrapper
          link={
            hasLink
              ? {
                  to: linkToLead,
                  target: openOnNewTab ? '_blank' : '_self',
                }
              : undefined
          }
        >
          <LeadSnippet
            lead={lead}
            {...restProps}
            isDeleting={isDeleting}
            onMoreActionsClicked={handleOpenMenu}
          >
            {children}
          </LeadSnippet>
        </ConditionalLinkWrapper>
      )}
    </>
  );
};

const LeadSnippet: React.FC<
  React.PropsWithChildren<
    Omit<
      LeadCardProps,
      'hasLink' | 'openOnNewTab' | 'legacyLinks' | 'onLeadChanged'
    > & {
      isDeleting?: boolean;
      onMoreActionsClicked: MouseEventHandler<HTMLButtonElement>;
    }
  >
> = ({
  lead,
  showMap = true,
  showAddress = true,
  controls = null,
  bottomButton = null,
  customCreationDate = null,
  displayMode = ELeadCardDisplayMode.NORMAL,
  isDeleting,
  onMoreActionsClicked,
  children,
}) => {
  const { me } = useAppData();
  const { countryCode } = useLocale();

  const { property } = lead;
  const { lat: defaultLat, lng: defaultLng } =
    getDefaultLocationOnMap(countryCode);
  const lat = property?.lat ?? defaultLat;
  const lng = property?.lng ?? defaultLng;
  const currency = getCurrencyByCountryCode(property?.country_code ?? 'CH');
  const canViewLeadDetails = userCanViewLeadDetails(me, lead);
  const isCompact = displayMode === ELeadCardDisplayMode.COMPACT;

  return (
    <Stack spacing={2}>
      <Card>
        <CardContent
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            p: 0,
            height: isCompact ? '90px' : 'auto',
            pb: '0 !important',
          }}
        >
          <Backdrop
            open={isDeleting ?? false}
            sx={{
              zIndex: 1,
              position: 'absolute',
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
            }}
          >
            <CircularProgress disableShrink />
          </Backdrop>

          {showMap && (
            <Box
              flexGrow={0}
              flexShrink={0}
              sx={{
                height: isCompact ? '100%' : 'auto',
                width: isCompact ? '90px' : ['100%', '140px'],
              }}
            >
              <Image
                css={{
                  display: isCompact ? 'flex' : ['flex', 'none'],
                  flexFlow: 'column',
                  flex: '1 1 0',
                }}
                loading="lazy"
                src={getStaticMapLink({
                  lat,
                  lng,
                  zoom: 10,
                  width: 128,
                  height: 128,
                  markers: canViewLeadDetails ? [{ lat, lng }] : [],
                })}
              />
              {!isCompact && (
                <Image
                  css={{
                    display: ['none', 'flex'],
                    flexFlow: 'column',
                    aspectRatio: ['auto', '1 / 1'],
                    flex: '1 1 0',
                  }}
                  loading="lazy"
                  src={getStaticMapLink({
                    lat,
                    lng,
                    zoom: 10,
                    width: 256,
                    height: 256,
                    style: 'satellite',
                    scale: 2,
                    markers: canViewLeadDetails ? [{ lat, lng }] : [],
                  })}
                />
              )}
            </Box>
          )}
          {lead.stage?.pipeline?.lead_type === 'sales' && (
            <SalesLeadDetail
              isCompact={isCompact}
              lead={lead}
              canViewLeadDetails={canViewLeadDetails}
              customCreationDate={customCreationDate}
              currency={currency}
              showAddress={showAddress}
              bottomButton={bottomButton}
              controls={controls}
            />
          )}
          {lead.stage?.pipeline?.lead_type === 'financing' && (
            <FinancingLeadDetail
              isCompact={isCompact}
              lead={lead}
              currency={currency}
              showAddress={showAddress}
              bottomButton={bottomButton}
            />
          )}
          <IconButton onClick={onMoreActionsClicked}>
            <MoreVertIcon />
          </IconButton>
        </CardContent>
      </Card>
      {children}
    </Stack>
  );
};
