/**
 * @generated SignedSource<<d0b3e002e20dd458b224c1764e13bab9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedEmailForm_lead$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EmailFormDataWrapper_lead">;
  readonly " $fragmentType": "FeedEmailForm_lead";
};
export type FeedEmailForm_lead$key = {
  readonly " $data"?: FeedEmailForm_lead$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedEmailForm_lead">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedEmailForm_lead",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmailFormDataWrapper_lead"
    }
  ],
  "type": "Lead",
  "abstractKey": null
};

(node as any).hash = "68d817c86634d593b1324fdc5198e688";

export default node;
