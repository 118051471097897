import { useState } from 'react';

import { gql, useMutation } from '@apollo/client';
import AssignmentIndOutlined from '@mui/icons-material/AssignmentIndOutlined';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Alert, Button, Paper, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { useLocale } from '../../../../src/hooks/locale';
import type {
  ActivityFieldsFragment,
  ClaimLeadMutation,
  ClaimLeadMutationVariables,
  PassLeadMutation,
  PassLeadMutationVariables,
} from '../../../__generated__/graphql';
import { TimeAgo } from '../../TimeAgo';

const CLAIM_LEAD_MUTATION = gql`
  mutation ClaimLead($lead_id: uuid!, $user_id: uuid!) {
    update_activities(
      where: {
        lead_id: { _eq: $lead_id }
        assigned_to: { _eq: $user_id }
        activity_type: { _eq: assignment }
      }
      _set: { done: true, done_at: "now()", success: true }
    ) {
      affected_rows
    }
    update_lead_agents(
      where: { lead_id: { _eq: $lead_id }, user_id: { _eq: $user_id } }
      _set: { claimed_at: "now()" }
    ) {
      affected_rows
    }
    insert_activities_one(
      object: {
        lead_id: $lead_id
        activity_type: call
        due_at: "now()"
        done: false
        assigned_to: $user_id
      }
    ) {
      id
    }
  }
`;

const PASS_LEAD_MUTATION = gql`
  mutation PassLead($lead_id: uuid!, $user_id: uuid!) {
    update_activities(
      where: {
        lead_id: { _eq: $lead_id }
        assigned_to: { _eq: $user_id }
        activity_type: { _eq: assignment }
      }
      _set: { done: true, done_at: "now()", success: false }
    ) {
      affected_rows
    }
    update_lead_agents(
      where: { lead_id: { _eq: $lead_id }, user_id: { _eq: $user_id } }
      _set: { passed_at: "now()" }
    ) {
      affected_rows
    }
  }
`;

export const Assignment = ({
  activity,
}: {
  activity: ActivityFieldsFragment;
}) => {
  const [showJSON, setShowJSON] = useState(false);
  const [claimLead] = useMutation<
    ClaimLeadMutation,
    ClaimLeadMutationVariables
  >(CLAIM_LEAD_MUTATION, { refetchQueries: ['LeadFeed'] });
  const [passLead] = useMutation<PassLeadMutation, PassLeadMutationVariables>(
    PASS_LEAD_MUTATION,
    { refetchQueries: ['LeadFeed', 'GetLeadAgents'] },
  );
  const { t } = useLocale();
  return (
    <TimelineItem key={activity?.id} style={{ fontSize: 10 }}>
      <TimelineSeparator>
        <TimelineConnector sx={{ height: '6px', flexGrow: 0 }} />
        <div onClick={() => setShowJSON(!showJSON)}>
          <AssignmentIndOutlined
            color={
              activity.done === true && activity.success === true
                ? 'success'
                : activity.done === true && activity.success === false
                ? 'error'
                : 'primary'
            }
          />
        </div>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ pr: 0 }}>
        <Typography>
          <Link to={`/users/${activity.creator?.id}`}>
            <strong>{activity.creator?.full_name}</strong>
          </Link>{' '}
          {t('assigned a lead to')}{' '}
          <Link to={`/users/${activity.assignee?.id}`}>
            <strong>{activity.assignee?.full_name}</strong>
          </Link>
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
          <TimeAgo dateString={activity?.created_at} />
        </Typography>
        {activity.done === false && (
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() =>
                claimLead({
                  variables: {
                    lead_id: activity.lead_id ?? '',
                    user_id: activity.assignee?.id ?? '',
                  },
                })
              }
              disableElevation
            >
              {t('Claim')}
            </Button>
            <Button
              variant="outlined"
              color="error"
              size="small"
              disableElevation
              onClick={() =>
                passLead({
                  variables: {
                    lead_id: activity.lead_id ?? '',
                    user_id: activity.assignee?.id ?? '',
                  },
                })
              }
            >
              {t('Pass')}
            </Button>
            <Button
              variant="outlined"
              color="neutral"
              size="small"
              // TODO: add reassign logic
              disabled
              disableElevation
            >
              {t('Reassign')}
            </Button>
          </Stack>
        )}
        {activity.done === true && activity.success === true && (
          <Typography>{t('Lead accepted')}</Typography>
        )}
        {activity.done === true && activity.success === false && (
          <Typography>{t('Lead passed')}</Typography>
        )}
        {showJSON && (
          <Paper sx={{ p: 1.5 }} elevation={0} variant="outlined">
            <Alert
              severity="info"
              sx={{
                fontSize: 12,
                fontFamily: 'monospace',
                position: 'relative',
                height: 500,
              }}
            >
              <pre
                style={{
                  overflow: 'auto',
                  position: 'absolute',
                  top: 20,
                  left: 40,
                  right: 0,
                  bottom: 0,
                }}
              >
                {JSON.stringify(activity, null, 2)}
              </pre>
            </Alert>
          </Paper>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};
