import { gql } from '../../__generated__';

export const PORTAL_FRAGMENT = gql(`
  fragment PortalFragment on portals {
    __typename
    id
    created_at
    updated_at
    created_by

    active
    contract_id
    email
    exact_address_required
    exclude_from_published_stats
    has_top_premium_listing
    idx_protocol
    master_portal_id
    name
    port
    quota
    type
    url
    website_url
    team_id
    logo {
      id
      url
    }

    portal_listings_aggregate(where: { live: { _eq: true } }) {
      aggregate {
        count
      }
    }
  }
`);

export const GET_PORTALS = gql(`
  query GetPortals(
    $where: portals_bool_exp!
    $order_by: [portals_order_by!]!
    $limit: Int!
    $offset: Int!
  ) {
    portals(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
      ...PortalFragment
    }
  }
`);

export const GET_PORTALS_COUNT = gql(`
  query GetPortalsCount($where: portals_bool_exp!) {
    portals_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`);

export const GET_PORTAL = gql(`
  query GetPortal($id: uuid!) {
    portals_by_pk(id: $id) {
      id
      ...PortalFragment
      username
      password
    }
    shared_portals {
      id
      name
    }
    portals_types_enum {
      value
    }
  }
`);

export const UPDATE_PORTAL = gql(`
  mutation UpdatePortal($id: uuid!, $portal: portals_set_input!) {
    update_portals_by_pk(pk_columns: { id: $id }, _set: $portal) {
      ...PortalFragment
    }
  }
`);

export const UPSERT_PORTAL = gql(`
  mutation UpsertPortal($portal: portals_insert_input!) {
    insert_portals_one(
      object: $portal
      on_conflict: {
        constraint: real_estate_portals_pkey
        update_columns: [
          active
          contract_id
          email
          exact_address_required
          exclude_from_published_stats
          has_top_premium_listing
          idx_protocol
          master_portal_id
          name
          password
          port
          quota
          type
          url
          username
          website_url
          team_id
          logo_image_id
        ]
      }
    ) {
      id
      ...PortalFragment
    }
  }
`);

export const DELETE_PORTAL = gql(`
  mutation DeletePortal($id: uuid!) {
    delete_portals_by_pk(id: $id) {
      id
    }
  }
`);
