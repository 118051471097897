// @flow

import * as React from 'react';

import { Button, ButtonGroup, Menu, MenuItem } from '@material-ui/core';

import { TemplateViewerDialog } from '../controls/template-viewer-dialog';
import { ArrowDropDown } from '../icons/arrow-drop-down';

type DocTemplate = {|
  id: string,
  name: string,
  isDefault: boolean,
  active: boolean,
  docTemplatesTenants: ?Array<{|
    tenantId: ?string,
    isDefault: ?boolean,
  |}>,
|};

type Props = {|
  docTemplates: DocTemplate[],
  documentId: string,
  disabled: boolean,
  fullWidth?: boolean,
  label: string,
  size?: 'small' | 'medium' | 'large',
|};

export function TemplatesListButtonGroup({
  docTemplates,
  documentId,
  disabled,
  fullWidth,
  label,
  size,
}: Props): React.Node {
  const [templateId, setTemplateId] = React.useState(null);
  const anchorRef = React.useRef(null);
  const [anchor, setAnchor] = React.useState<null | HTMLButtonElement>(null);

  const shownTemplates = docTemplates.filter(template => template.active);
  const defaultDocTemplate =
    docTemplates.find(template => template.isDefault) ?? docTemplates[0];

  return (
    <>
      <ButtonGroup
        css={fullWidth === true && [{ display: 'flex' }]}
        color="inherit"
        variant="outlined"
        ref={anchorRef}
        aria-label="split button"
        disabled={disabled}
      >
        <Button
          css={fullWidth === true && [{ flexGrow: 1 }]}
          variant="outlined"
          size={size}
          title={defaultDocTemplate.name}
          onClick={() => setTemplateId(defaultDocTemplate.id)}
        >
          {label}
        </Button>
        <Button
          size="small"
          css={{ paddingLeft: 0, paddingRight: 0 }}
          onClick={() => setAnchor(anchorRef.current)}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Menu
        anchorEl={anchor}
        open={anchor != null}
        onClick={() => setAnchor(null)}
        onClose={() => setAnchor(null)}
      >
        {shownTemplates.map(item => (
          <MenuItem key={item.id} onClick={() => setTemplateId(item.id)}>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
      <TemplateViewerDialog
        templateId={templateId ?? ''}
        documentId={documentId}
        open={templateId != null}
        onClose={() => setTemplateId(null)}
      />
    </>
  );
}
