/**
 * @generated SignedSource<<980e7c39b8d957e5971e0fefd2d3d336>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LeadStatus = "active" | "lost" | "won";
import { FragmentRefs } from "relay-runtime";
export type leadControls_lead$data = {
  readonly completed: boolean;
  readonly id: string;
  readonly lot: {
    readonly id: string;
  } | null;
  readonly stage: {
    readonly id: string;
  } | null;
  readonly status: LeadStatus;
  readonly " $fragmentSpreads": FragmentRefs<"leadDeleteDialog_lead">;
  readonly " $fragmentType": "leadControls_lead";
};
export type leadControls_lead$key = {
  readonly " $data"?: leadControls_lead$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadControls_lead">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadControls_lead",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadDeleteDialog_lead"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LeadStage",
      "kind": "LinkedField",
      "name": "stage",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Lot",
      "kind": "LinkedField",
      "name": "lot",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};
})();

(node as any).hash = "90edbd6c6d7e5af63d29f17d42112908";

export default node;
