/**
 * @generated SignedSource<<be6dbfb602612e56b726c1a86bb245d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type leadControls_root$data = {
  readonly me: {
    readonly isAdmin: boolean | null;
    readonly modules: ReadonlyArray<string>;
  } | null;
  readonly pipelines: ReadonlyArray<{
    readonly stages: ReadonlyArray<{
      readonly id: string;
      readonly name: string | null;
    }>;
  }>;
  readonly " $fragmentType": "leadControls_root";
};
export type leadControls_root$key = {
  readonly " $data"?: leadControls_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadControls_root">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadControls_root",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAdmin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "modules",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "name",
          "value": "brokerage"
        }
      ],
      "concreteType": "Pipeline",
      "kind": "LinkedField",
      "name": "pipelines",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LeadStage",
          "kind": "LinkedField",
          "name": "stages",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "pipelines(name:\"brokerage\")"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "8910c4ce6d7da47ce666c0ab2c4fde52";

export default node;
