import {
  type SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { PeopleOutline } from '@mui/icons-material';
import {
  Autocomplete,
  type AutocompleteInputChangeReason,
  Checkbox,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  debounce,
} from '@mui/material';

import { useLocale } from '../../../../src/hooks/locale';
import {
  type GetTeamsByIdsQuery,
  type GetTeamsByIdsQueryVariables,
  type TeamFilterFragment,
  type TeamFilterSearchTeamsQuery,
} from '../../../__generated__/graphql';
import { useFiltersSearchParams } from '../useFiltersSearchParams';
import { useSchema } from '../useSchema';

import { FilterChip } from './FilterChip';
import type { QuickFilterProps } from './QuickFilters';
import { AutocompletePaper, AutocompleteTextField } from './QuickFilters';

const TEAM_FILTER_FRAGMENT = gql`
  fragment TeamFilter on teams {
    id
    name
  }
`;

const SEARCH_TEAMS = gql`
  ${TEAM_FILTER_FRAGMENT}
  query TeamFilterSearchTeams($where: teams_bool_exp!) {
    teams(where: $where, limit: 20, order_by: { name: asc }) {
      id
      ...TeamFilter
    }
  }
`;

const GET_DEFAULT_TEAMS = gql`
  ${TEAM_FILTER_FRAGMENT}
  query GetTeamsByIds($ids: [uuid!]!) {
    selected_teams: teams(where: { id: { _in: $ids } }) {
      id
      ...TeamFilter
    }

    initial_teams: teams(
      where: { id: { _nin: $ids } }
      limit: 20
      order_by: { name: asc }
    ) {
      id
      ...TeamFilter
    }
  }
`;

interface UserMultiSelectProps {
  onClose?: () => void;
  onChange: (value: string[]) => void;
  defaultValue: string[];
}

export const TeamMultiSelect = ({
  onChange,
  onClose,
  defaultValue,
}: UserMultiSelectProps) => {
  const { t } = useLocale();
  const [options, setOptions] = useState<TeamFilterFragment[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<TeamFilterFragment[]>(
    [],
  );

  const [searchTeams, { loading: searchLoading }] =
    useLazyQuery<TeamFilterSearchTeamsQuery>(SEARCH_TEAMS);

  const { data, loading: defaultLoading } = useQuery<
    GetTeamsByIdsQuery,
    GetTeamsByIdsQueryVariables
  >(GET_DEFAULT_TEAMS, {
    variables: { ids: defaultValue },
  });

  const updateOptions = useMemo(
    () =>
      (newValue: TeamFilterFragment[], initialTeams?: TeamFilterFragment[]) => {
        const combined = [...newValue, ...(initialTeams ?? [])];
        const uniqueCombined = combined.filter(
          (team, idx, arr) => arr.findIndex(t => t.id === team.id) === idx,
        );
        setOptions(uniqueCombined);
      },
    [],
  );

  useEffect(() => {
    if (data && options.length === 0 && !inputValue) {
      setSelectedOptions(data.selected_teams);
      updateOptions(data.selected_teams, data.initial_teams);
    }
  }, [data, inputValue, options.length, updateOptions]);

  const debouncedSearch = useMemo(
    () =>
      debounce(async value => {
        const { data: searchData } = await searchTeams({
          variables: {
            where: {
              name: { _ilike: `%${value}%` },
              id: { _nin: defaultValue },
            },
          },
        });
        if (!searchData?.teams?.length) {
          setOptions([]);
        } else {
          setOptions(searchData?.teams ?? []);
        }
      }, 275),
    [defaultValue, searchTeams],
  );

  const handleSearch = useCallback(
    (searchValue: string) => {
      if (searchValue) {
        debouncedSearch(searchValue);
      } else {
        updateOptions(data?.selected_teams ?? [], data?.initial_teams);
      }
    },
    [debouncedSearch, data, updateOptions],
  );

  const handleToggleOptions = useCallback(
    (_: any, newValue: TeamFilterFragment[]) => {
      setSelectedOptions(newValue);
      onChange(newValue.map(v => v.id));
    },
    [onChange],
  );

  const handleInputChange = useCallback(
    (
      _: SyntheticEvent<Element, Event>,
      newValue: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      if (reason !== 'input') {
        return;
      }

      setInputValue(newValue);
      handleSearch(newValue);
    },
    [handleSearch],
  );

  const loading = searchLoading || defaultLoading;

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      openOnFocus
      open
      disablePortal
      disableClearable
      size="small"
      clearOnBlur={false}
      value={selectedOptions}
      loading={loading}
      onClose={onClose}
      sx={{ width: 350 }}
      options={options}
      getOptionLabel={option => option.name}
      filterOptions={x => x}
      PaperComponent={AutocompletePaper}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={handleToggleOptions}
      renderInput={params => (
        <AutocompleteTextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <ListItem {...props} key={option.id} dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={selected}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={option.name} />
        </ListItem>
      )}
      noOptionsText={t('No options')}
    />
  );
};

export const TeamFilter = ({
  label,
  path,
  where,
  addWhereClause,
  deleteWhereClause,
  queryParamsScope,
  disabled,
}: QuickFilterProps) => {
  const [, setFiltersParams] = useFiltersSearchParams(queryParamsScope);
  const { getValueFromPath } = useSchema();
  const defaultValue = getValueFromPath(path, where)?.id?._in ?? [];

  const handleChange = (value: string[]) => {
    let newWhere = deleteWhereClause(where, path, true);
    if (value.length > 0) {
      newWhere = addWhereClause(newWhere, [...path, 'id', '_in'], value);
    }
    setFiltersParams(newWhere);
  };
  const handleDelete =
    defaultValue?.length > 0 ? () => handleChange([]) : undefined;

  return (
    <FilterChip
      label={label}
      icon={<PeopleOutline />}
      onDelete={handleDelete}
      disabled={disabled?.(where) ?? false}
      renderFilter={({ handleClose }) => (
        <TeamMultiSelect
          onClose={handleClose}
          onChange={handleChange}
          defaultValue={defaultValue}
        />
      )}
    />
  );
};
