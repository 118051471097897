/**
 * @generated SignedSource<<69addd8c12061176f701684279c36eb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LeadDetailsLatestAppraisalCard_root$data = {
  readonly docTemplates: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly active: boolean;
        readonly docTemplatesTenants: ReadonlyArray<{
          readonly isDefault: boolean | null;
          readonly tenantId: string | null;
        } | null> | null;
        readonly id: string;
        readonly name: string;
      };
    } | null> | null;
  } | null;
  readonly tenantSettings: {
    readonly activateModelDetails: boolean;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"appraisalGraph_tenantSettings">;
  } | null;
  readonly " $fragmentType": "LeadDetailsLatestAppraisalCard_root";
};
export type LeadDetailsLatestAppraisalCard_root$key = {
  readonly " $data"?: LeadDetailsLatestAppraisalCard_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"LeadDetailsLatestAppraisalCard_root">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LeadDetailsLatestAppraisalCard_root",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenantSettings",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "appraisalGraph_tenantSettings"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activateModelDetails",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "type_in": [
              "appraisal_report"
            ]
          }
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 100
        }
      ],
      "concreteType": "DocTemplateConnection",
      "kind": "LinkedField",
      "name": "docTemplates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DocTemplateEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DocTemplate",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "active",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DocTemplatesTenants",
                  "kind": "LinkedField",
                  "name": "docTemplatesTenants",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "tenantId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isDefault",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "docTemplates(filters:{\"type_in\":[\"appraisal_report\"]},first:100)"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "03d6ca57ee878e939cce71862f437e71";

export default node;
