import * as React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Form, type FormikErrors, useFormik } from '@realadvisor/form';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';
import { useParams } from 'react-router-dom';
import { Box, Flex } from 'react-system';

import { Checkbox } from '../controls/checkbox';
import {
  DrawerBottomToolbar,
  DrawerContent,
  DrawerHeader,
  DrawerLayer,
} from '../controls/drawer';
import { SquareInput } from '../controls/meter-input';
import { PriceInput } from '../controls/price-input';
import { ProgressButton } from '../controls/progress-button';
import { Radio } from '../controls/radio';
import { RangeInput } from '../controls/range-input';
import { RoomsFilterInput } from '../controls/rooms-filter-input';
import { ScrollToError } from '../controls/scroll-to-error';
import { useLocale } from '../hooks/locale';

import type { propertySearchDrawerDeleteMutation } from './__generated__/propertySearchDrawerDeleteMutation.graphql';
import type {
  OfferType,
  PropertyMainType,
  propertySearchDrawerQuery,
  propertySearchDrawerQuery$data,
} from './__generated__/propertySearchDrawerQuery.graphql';
import type {
  propertySearchDrawerTypesQuery,
  propertySearchDrawerTypesQuery$data,
} from './__generated__/propertySearchDrawerTypesQuery.graphql';
import type { propertySearchDrawerUpsertMutation } from './__generated__/propertySearchDrawerUpsertMutation.graphql';
import { PlacePicker } from './place-picker';
import { type User, UserInput } from './user-input';
import { UserSummary } from './user-summary';

type PropertyType = NonNullable<
  NonNullable<propertySearchDrawerTypesQuery$data>['propertyTypes']
>[number];

type PropertySearch = NonNullable<
  propertySearchDrawerQuery$data['propertySearch']
>;

graphql`
  fragment propertySearchDrawer_propertySearch on PropertySearch {
    id
    user {
      id
      ...userInput_user @relay(mask: false)
      ...userSummary_user
    }
    places {
      ...placePicker_place @relay(mask: false)
    }
    offerType
    salePriceLte
    salePriceGte
    monthlyRentLte
    monthlyRentGte
    propertyUsage
    propertyStatuses
    numberOfRoomsGte
    numberOfRoomsLte
    numberOfBedroomsGte
    numberOfBedroomsLte
    numberOfBathroomsGte
    numberOfBathroomsLte
    livingSurfaceGte
    livingSurfaceLte
    builtSurfaceGte
    builtSurfaceLte
    landSurfaceGte
    landSurfaceLte
    propertyTypes {
      id
      label
      mainType
    }
    propertyMainTypeIn
    hasSwimmingPool
    hasLift
    hasParking
    hasGarden
    hasBalcony
    hasView
    notification
    activateAggregator
    comment
    source
    canEditAlerts
  }
`;

const number_of_string = (string: string) => {
  const parsed = Number.parseFloat(string);
  if (Number.isNaN(parsed)) {
    return null;
  }
  return parsed;
};

const string_of_number = (number: number | null) => {
  if (number == null) {
    return '';
  }
  return String(number);
};

const PropertySearchForm = ({
  tenantCoordinates,
  predefinedUser,
  propertySearch,
  propertyTypes,
  onCreate,
  onDelete,
}: {
  tenantCoordinates?: { lat: number; lng: number; z: number };
  predefinedUser: User | null;
  propertySearch: PropertySearch | null;
  propertyTypes: readonly PropertyType[];
  onCreate: ((propertySearchId: string) => void) | null;
  onDelete: (() => void) | null;
}) => {
  const { t } = useLocale();
  const [upsertPropertySearch, upserting] =
    useMutation<propertySearchDrawerUpsertMutation>(
      graphql`
        mutation propertySearchDrawerUpsertMutation(
          $input: UpsertPropertySearchInput!
        ) {
          upsertPropertySearch(input: $input) {
            propertySearchEdge {
              node {
                ...propertySearchDrawer_propertySearch @relay(mask: false)
              }
            }
          }
        }
      `,
    );
  const [deletePropertySearch] =
    useMutation<propertySearchDrawerDeleteMutation>(
      graphql`
        mutation propertySearchDrawerDeleteMutation(
          $input: DeletePropertySearchInput!
        ) {
          deletePropertySearch(input: $input) {
            deletedPropertySearchId
          }
        }
      `,
    );
  const [deletingDialog, setDeletingDialog] = React.useState(false);

  const propertyMainTypes: readonly PropertyMainType[] =
    propertySearch?.propertyMainTypeIn ?? [];

  const defaultUser = predefinedUser ?? propertySearch?.user ?? null;

  type State = {
    user: User | null;
    places: NonNullable<PropertySearch['places']>;
    offerType: OfferType | null;
    salePriceGte: string | null;
    salePriceLte: string | null;
    monthlyRentGte: string | null;
    monthlyRentLte: string | null;
    propertyUsage: PropertySearch['propertyUsage'];
    propertyStatuses: NonNullable<PropertySearch['propertyStatuses']>;
    numberOfRoomsGte: number | null;
    numberOfRoomsLte: number | null;
    numberOfBedroomsGte: number | null;
    numberOfBedroomsLte: number | null;
    numberOfBathroomsGte: number | null;
    numberOfBathroomsLte: number | null;
    livingSurfaceLte: string | null;
    livingSurfaceGte: string | null;
    builtSurfaceLte: string | null;
    builtSurfaceGte: string | null;
    landSurfaceLte: string | null;
    landSurfaceGte: string | null;
    propertyTypes: string[];
    propertyMainTypes: NonNullable<PropertySearch['propertyMainTypeIn']>;
    hasSwimmingPool: boolean;
    hasLift: boolean;
    hasParking: boolean;
    hasGarden: boolean;
    hasBalcony: boolean;
    hasView: boolean;
    notification: PropertySearch['notification'];
    activateAggregator: boolean;
    comment: string;
    source: PropertySearch['source'];
  };

  const formik = useFormik<State>({
    initialValues: {
      user: defaultUser,
      places: propertySearch?.places ?? [],
      offerType: propertySearch?.offerType ?? null,
      salePriceGte: string_of_number(propertySearch?.salePriceGte ?? null),
      salePriceLte: string_of_number(propertySearch?.salePriceLte ?? null),
      monthlyRentGte: string_of_number(propertySearch?.monthlyRentGte ?? null),
      monthlyRentLte: string_of_number(propertySearch?.monthlyRentLte ?? null),
      propertyUsage: propertySearch?.propertyUsage,
      propertyStatuses: propertySearch?.propertyStatuses ?? [],
      numberOfRoomsGte: propertySearch?.numberOfRoomsGte ?? null,
      numberOfRoomsLte: propertySearch?.numberOfRoomsLte ?? null,
      numberOfBedroomsGte: propertySearch?.numberOfBedroomsGte ?? null,
      numberOfBedroomsLte: propertySearch?.numberOfBedroomsLte ?? null,
      numberOfBathroomsGte: propertySearch?.numberOfBathroomsGte ?? null,
      numberOfBathroomsLte: propertySearch?.numberOfBathroomsLte ?? null,
      livingSurfaceLte: string_of_number(
        propertySearch?.livingSurfaceLte ?? null,
      ),
      livingSurfaceGte: string_of_number(
        propertySearch?.livingSurfaceGte ?? null,
      ),
      builtSurfaceLte: string_of_number(
        propertySearch?.builtSurfaceLte ?? null,
      ),
      builtSurfaceGte: string_of_number(
        propertySearch?.builtSurfaceGte ?? null,
      ),
      landSurfaceLte: string_of_number(propertySearch?.landSurfaceLte ?? null),
      landSurfaceGte: string_of_number(propertySearch?.landSurfaceGte ?? null),
      propertyTypes: (propertySearch?.propertyTypes ?? []).map(item => item.id),
      propertyMainTypes,
      hasSwimmingPool: propertySearch?.hasSwimmingPool === true,
      hasLift: propertySearch?.hasLift === true,
      hasParking: propertySearch?.hasParking === true,
      hasGarden: propertySearch?.hasGarden === true,
      hasBalcony: propertySearch?.hasBalcony === true,
      hasView: propertySearch?.hasView === true,
      notification: propertySearch?.notification ?? 'never',
      activateAggregator: propertySearch?.activateAggregator ?? false,
      comment: propertySearch?.comment ?? '',
      source: propertySearch?.source ?? 'crm',
    },
    validate: (values: State) => {
      const errors: FormikErrors<State> = {};

      if (values.user == null) {
        errors.user = t('userIsRequiredError');
      }

      if (values.places.length === 0) {
        errors.places = t('atLeastOnePlaceRequiredError');
      }

      if (values.offerType == null) {
        errors.offerType = t('buyOrRentRequiredError');
      }

      if (
        values.offerType === 'buy' &&
        values.salePriceGte?.length === 0 &&
        values.salePriceLte?.length === 0
      ) {
        errors.salePriceLte = t('purchaseBudgetRequiredError');
      }

      if (
        values.offerType === 'rent' &&
        values.monthlyRentGte?.length === 0 &&
        values.monthlyRentLte?.length === 0
      ) {
        errors.monthlyRentLte = t('rentBudgetRequiredError');
      }

      return errors;
    },

    onSubmit: values => {
      upsertPropertySearch({
        variables: {
          input: {
            propertySearch: {
              id: propertySearch?.id,
              userId: values.user?.id ?? null,
              places: values.places.map(place => place.objectId),
              offerType: values.offerType,

              salePriceGte: number_of_string(values.salePriceGte ?? ''),
              salePriceLte: number_of_string(values.salePriceLte ?? ''),
              monthlyRentGte: number_of_string(values.monthlyRentGte ?? ''),
              monthlyRentLte: number_of_string(values.monthlyRentLte ?? ''),
              propertyUsage: values.propertyUsage,
              propertyStatuses: values.propertyStatuses,
              numberOfRoomsGte: values.numberOfRoomsGte,
              numberOfRoomsLte: values.numberOfRoomsLte,
              numberOfBedroomsGte: values.numberOfBedroomsGte,
              numberOfBedroomsLte:
                // room picker returns value + 0.5, which fits float value for numberOfRooms only
                values.numberOfBedroomsLte != null
                  ? Math.floor(values.numberOfBedroomsLte)
                  : null,
              numberOfBathroomsGte: values.numberOfBathroomsGte,
              numberOfBathroomsLte:
                values.numberOfBathroomsLte != null
                  ? Math.floor(values.numberOfBathroomsLte)
                  : null,
              livingSurfaceLte: number_of_string(values.livingSurfaceLte ?? ''),
              livingSurfaceGte: number_of_string(values.livingSurfaceGte ?? ''),
              builtSurfaceLte: number_of_string(values.builtSurfaceLte ?? ''),
              builtSurfaceGte: number_of_string(values.builtSurfaceGte ?? ''),
              landSurfaceLte: number_of_string(values.landSurfaceLte ?? ''),
              landSurfaceGte: number_of_string(values.landSurfaceGte ?? ''),
              propertyTypes: values.propertyTypes,
              propertyMainTypeIn: values.propertyMainTypes,
              hasSwimmingPool: values.hasSwimmingPool,
              hasLift: values.hasLift,
              hasParking: values.hasParking,
              hasGarden: values.hasGarden,
              hasBalcony: values.hasBalcony,
              hasView: values.hasView,
              notification: values.notification,
              activateAggregator: values.activateAggregator,
              comment: values.comment,
              source: values.source,
            },
          },
        },
        onCompleted: data => {
          if (data.upsertPropertySearch?.propertySearchEdge?.node) {
            onCreate?.(data.upsertPropertySearch.propertySearchEdge.node.id);
          }
          formik.resetForm();
        },
      });
    },
  });

  const { values, errors, setValues } = formik;

  const propertyMainTypesObj = React.useMemo(() => {
    const obj = {} as Record<PropertyMainType, PropertyType[]>;
    for (const type of propertyTypes) {
      const mainType = (type.mainType ?? 'OTHER') as PropertyMainType;
      if (!obj[mainType]) {
        obj[mainType] = [];
      }
      obj[mainType].push(type);
    }
    return obj;
  }, [propertyTypes]);

  return (
    <Form onSubmit={formik.submitForm}>
      <ScrollToError errors={formik.errors} />
      <Box px={propertySearch?.user != null ? 2 : 3} my={3}>
        {propertySearch?.user != null ? (
          <UserSummary user={propertySearch?.user} showTitle={true} />
        ) : (
          <FormControl
            disabled={predefinedUser != null}
            error={errors.user != null}
            required={true}
          >
            <InputLabel>{t('contact')}</InputLabel>
            <UserInput
              value={values.user}
              clearable={defaultUser == null}
              onChange={user => setValues({ user })}
            />
            {errors.user != null && (
              <FormHelperText>{errors.user}</FormHelperText>
            )}
          </FormControl>
        )}
      </Box>

      <Box px={3} mb={3}>
        {tenantCoordinates && (
          <PlacePicker
            required={true}
            mapHeight={500}
            error={errors.places != null}
            helperText={errors.places ?? null}
            value={values.places}
            onChange={places => setValues({ places })}
            initialCenter={{
              lat: tenantCoordinates.lat,
              lng: tenantCoordinates.lng,
            }}
            initialZoom={tenantCoordinates.z}
          />
        )}
      </Box>

      <Box px={3}>
        <Flex flexWrap="wrap">
          <Box width={[1, 1 / 2]} mb={[1, 0]} pr={[0, 2]}>
            <FormLabel required={true}>{t('Type')}</FormLabel>
            <Radio
              checked={values.offerType === 'buy'}
              onChange={() =>
                setValues({
                  offerType: 'buy',
                  salePriceGte: '',
                  salePriceLte: '',
                  monthlyRentGte: '',
                  monthlyRentLte: '',
                })
              }
            >
              {t('buy')}
            </Radio>
            <Radio
              checked={values.offerType === 'rent'}
              onChange={() =>
                setValues({
                  offerType: 'rent',
                  salePriceGte: '',
                  salePriceLte: '',
                  monthlyRentGte: '',
                  monthlyRentLte: '',
                })
              }
            >
              {t('rent')}
            </Radio>
            {errors.offerType != null && (
              <FormHelperText error={errors.offerType != null}>
                {errors.offerType}
              </FormHelperText>
            )}
          </Box>
        </Flex>
      </Box>

      {values.offerType != null && (
        <>
          <Flex flexWrap="wrap">
            {values.offerType === 'buy' && (
              <Box width={[1, 1, 1 / 2]} p={3}>
                <FormLabel required={true}>{t('salePrice')}</FormLabel>
                <RangeInput
                  min={10_000}
                  max={20_000_000}
                  step={10_000}
                  valueGte={values.salePriceGte ?? ''}
                  valueLte={values.salePriceLte ?? ''}
                  onChange={({ valueGte, valueLte }) => {
                    setValues({
                      salePriceGte: valueGte,
                      salePriceLte: valueLte,
                    });
                  }}
                  error={errors.salePriceLte}
                  renderInput={props => (
                    <PriceInput
                      onChange={props.onChange}
                      value={props.value}
                      onBlur={props.onBlur}
                      decimalScale={0}
                    />
                  )}
                />
              </Box>
            )}
            {values.offerType === 'rent' && (
              <Box width={[1, 1 / 2]} p={3}>
                <FormLabel required={true}>{t('monthlyRent')}</FormLabel>
                <RangeInput
                  min={100}
                  max={100_000}
                  step={100}
                  valueGte={values.monthlyRentGte ?? ''}
                  valueLte={values.monthlyRentLte ?? ''}
                  onChange={({ valueGte, valueLte }) => {
                    setValues({
                      monthlyRentGte: valueGte,
                      monthlyRentLte: valueLte,
                    });
                  }}
                  error={errors.monthlyRentLte}
                  renderInput={props => (
                    <PriceInput
                      onChange={props.onChange}
                      value={props.value}
                      onBlur={props.onBlur}
                      decimalScale={0}
                    />
                  )}
                />
              </Box>
            )}
          </Flex>
          <Flex flexWrap="wrap">
            <Box width={[1, 1 / 2]} p={3}>
              <FormLabel>{t('propertyUsage')}</FormLabel>
              <Radio
                checked={values.propertyUsage === 'primary'}
                onChange={() => setValues({ propertyUsage: 'primary' })}
              >
                {t('primary')}
              </Radio>
              <Radio
                checked={values.propertyUsage === 'secondary'}
                onChange={() => setValues({ propertyUsage: 'secondary' })}
              >
                {t('secondary')}
              </Radio>
              <Radio
                checked={values.propertyUsage === 'investment'}
                onChange={() => setValues({ propertyUsage: 'investment' })}
              >
                {t('investment')}
              </Radio>
            </Box>

            <Box width={[1, 1 / 2]} p={3}>
              <FormLabel>{t('newOrExisting')}</FormLabel>
              <Box width={1 / 2}>
                <Checkbox
                  checked={values.propertyStatuses.includes('new')}
                  onChange={checked =>
                    setValues({
                      propertyStatuses: checked
                        ? [...values.propertyStatuses, 'new']
                        : values.propertyStatuses.filter(d => d !== 'new'),
                    })
                  }
                >
                  {t('newDevelopment')}
                </Checkbox>
              </Box>
              <Box width={1 / 2}>
                <Checkbox
                  checked={values.propertyStatuses.includes('existing')}
                  onChange={checked =>
                    setValues({
                      propertyStatuses: checked
                        ? [...values.propertyStatuses, 'existing']
                        : values.propertyStatuses.filter(d => d !== 'existing'),
                    })
                  }
                >
                  {t('existingProperty')}
                </Checkbox>
              </Box>
            </Box>
          </Flex>

          <Box p={2}>
            <Box p={2}>
              <FormLabel>{t('roomsAndSurfaces')}</FormLabel>
            </Box>
            <Flex flexWrap="wrap">
              <Box width={[1, 1, 1 / 2]}>
                <Box p={2}>
                  <FormControl variant="filled">
                    <FormLabel>{t('numberOfRooms')}</FormLabel>
                    <RoomsFilterInput
                      valueGte={values.numberOfRoomsGte ?? null}
                      valueLte={values.numberOfRoomsLte ?? null}
                      onChange={({ valueGte, valueLte }) =>
                        setValues({
                          numberOfRoomsGte: valueGte,
                          numberOfRoomsLte: valueLte,
                        })
                      }
                    />
                  </FormControl>
                </Box>
                <Box p={2}>
                  <FormControl variant="filled">
                    <FormLabel>{t('Number of bathrooms')}</FormLabel>
                    <RoomsFilterInput
                      valueGte={values.numberOfBathroomsGte ?? null}
                      valueLte={values.numberOfBathroomsLte ?? null}
                      onChange={({ valueGte, valueLte }) =>
                        setValues({
                          numberOfBathroomsGte: valueGte,
                          numberOfBathroomsLte: valueLte,
                        })
                      }
                    />
                  </FormControl>
                </Box>
                <Box p={2}>
                  <FormControl variant="filled">
                    <FormLabel>{t('numberOfBedrooms')}</FormLabel>
                    <RoomsFilterInput
                      valueGte={values.numberOfBedroomsGte ?? null}
                      valueLte={values.numberOfBedroomsLte ?? null}
                      onChange={({ valueGte, valueLte }) =>
                        setValues({
                          numberOfBedroomsGte: valueGte,
                          numberOfBedroomsLte: valueLte,
                        })
                      }
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box width={[1, 1, 1 / 2]}>
                <Box p={2}>
                  <FormLabel>{t('livingSurface')}</FormLabel>
                  <RangeInput
                    min={10}
                    max={1000}
                    step={5}
                    valueGte={values.livingSurfaceGte ?? ''}
                    valueLte={values.livingSurfaceLte ?? ''}
                    onChange={({ valueGte, valueLte }) => {
                      setValues({
                        livingSurfaceGte: valueGte,
                        livingSurfaceLte: valueLte,
                      });
                    }}
                    renderInput={props => (
                      <SquareInput
                        decimalScale={0}
                        onChange={props.onChange}
                        value={props.value}
                        onBlur={props.onBlur}
                      />
                    )}
                  />
                  {errors.livingSurfaceGte != null && (
                    <FormHelperText>{errors.livingSurfaceGte}</FormHelperText>
                  )}
                </Box>
                <Box p={2}>
                  <FormLabel>{t('builtSurface')}</FormLabel>
                  <RangeInput
                    min={10}
                    max={1000}
                    step={5}
                    valueGte={values.builtSurfaceGte ?? ''}
                    valueLte={values.builtSurfaceLte ?? ''}
                    onChange={({ valueGte, valueLte }) => {
                      setValues({
                        builtSurfaceGte: valueGte,
                        builtSurfaceLte: valueLte,
                      });
                    }}
                    renderInput={props => (
                      <SquareInput
                        decimalScale={0}
                        onChange={props.onChange}
                        value={props.value}
                        onBlur={props.onBlur}
                      />
                    )}
                  />
                </Box>
                <Box p={2}>
                  <FormLabel>{t('landSurface')}</FormLabel>
                  <RangeInput
                    min={10}
                    max={10000}
                    step={10}
                    valueGte={values.landSurfaceGte ?? ''}
                    valueLte={values.landSurfaceLte ?? ''}
                    onChange={({ valueGte, valueLte }) => {
                      setValues({
                        landSurfaceGte: valueGte,
                        landSurfaceLte: valueLte,
                      });
                    }}
                    renderInput={props => (
                      <SquareInput
                        decimalScale={0}
                        onChange={props.onChange}
                        value={props.value}
                        onBlur={props.onBlur}
                      />
                    )}
                  />
                  {errors.landSurfaceGte != null && (
                    <FormHelperText>{errors.landSurfaceGte}</FormHelperText>
                  )}
                </Box>
              </Box>
            </Flex>
          </Box>

          <Box p={2}>
            <Box p={2}>
              <FormLabel>{t('propertyTypes')}</FormLabel>
            </Box>
            <Flex flexWrap="wrap">
              {(Object.keys(propertyMainTypesObj) as PropertyMainType[]).map(
                mainType => {
                  const items = propertyMainTypesObj[mainType];
                  const mainTypeLabel =
                    {
                      HOUSE: t('house'),
                      APPT: t('apartment'),
                      PROP: t('Land'),
                      PARK: t('parking'),
                      INDUS: t('commercial'),
                      GASTRO: t('hotellerie'),
                      AGRI: t('agriculturalProperty'),
                      SECONDARY: t('secondary'),
                      GARDEN: t('Garden'),
                      COMMERCIAL: t('commercial'),
                      BUILDING: t('building'),
                      ROOM: t('room'),
                      HOUSE_APPT: t('houseApartment'),
                      OTHER: t('other'),
                    }[mainType] ?? mainType;
                  return (
                    <Box key={mainType} width={[1, 1 / 2, 1 / 3]} p={2}>
                      <Checkbox
                        key={mainType}
                        checked={
                          values.propertyMainTypes.includes(mainType) ||
                          items.every(i => values.propertyTypes.includes(i.id))
                        }
                        onChange={checked =>
                          setValues({
                            propertyMainTypes: checked
                              ? // preserve id order/same on api side
                                [...values.propertyMainTypes, mainType].sort()
                              : values.propertyMainTypes.filter(
                                  d => d !== mainType,
                                ),
                            // select/diselect all subtypes
                            propertyTypes: [
                              ...values.propertyTypes.filter(
                                id => !items.some(a => a.id === id),
                              ),
                              ...(checked ? items.map(i => i.id) : []),
                            ],
                          })
                        }
                      >
                        {mainTypeLabel}
                      </Checkbox>
                      {(values.propertyMainTypes.includes(mainType) ||
                        items.some(i =>
                          values.propertyTypes.includes(i.id),
                        )) && (
                        <Box ml={4}>
                          {items.map(item => (
                            <Checkbox
                              key={item.id}
                              checked={values.propertyTypes.includes(item.id)}
                              onChange={checked => {
                                // - toggle current nested checkbox
                                // - toggle parent checkbox to true/false if all/none checked
                                const newPropertyTypes = checked
                                  ? // preserve id order/same on api side
                                    [...values.propertyTypes, item.id].sort()
                                  : values.propertyTypes.filter(
                                      d => d !== item.id,
                                    );
                                let newPropertyMainTypes =
                                  values.propertyMainTypes;
                                if (newPropertyTypes.length === items.length) {
                                  newPropertyMainTypes = [
                                    ...newPropertyMainTypes,
                                    mainType,
                                  ];
                                  newPropertyMainTypes = Array.from(
                                    new Set(newPropertyMainTypes),
                                  );
                                }
                                if (
                                  items.every(
                                    i => !newPropertyTypes.includes(i.id),
                                  )
                                ) {
                                  newPropertyMainTypes =
                                    newPropertyMainTypes.filter(
                                      t => t !== mainType,
                                    );
                                }

                                setValues({
                                  propertyTypes: newPropertyTypes,
                                  propertyMainTypes: newPropertyMainTypes,
                                });
                              }}
                            >
                              {item.label}
                            </Checkbox>
                          ))}
                        </Box>
                      )}
                    </Box>
                  );
                },
              )}
            </Flex>
          </Box>

          <Box p={3}>
            <FormLabel>{t('features')}</FormLabel>
            <Flex flexWrap="wrap">
              <Box width={1 / 2}>
                <Checkbox
                  checked={values.hasSwimmingPool}
                  onChange={hasSwimmingPool => setValues({ hasSwimmingPool })}
                >
                  {t('Swimming pool')}
                </Checkbox>
              </Box>

              <Box width={1 / 2}>
                <Checkbox
                  checked={values.hasLift}
                  onChange={hasLift => setValues({ hasLift })}
                >
                  {t('hasLift')}
                </Checkbox>
              </Box>

              <Box width={1 / 2}>
                <Checkbox
                  checked={values.hasParking}
                  onChange={hasParking => setValues({ hasParking })}
                >
                  {t('hasParking')}
                </Checkbox>
              </Box>

              <Box width={1 / 2}>
                <Checkbox
                  checked={values.hasGarden}
                  onChange={hasGarden => setValues({ hasGarden })}
                >
                  {t('hasGarden')}
                </Checkbox>
              </Box>

              <Box width={1 / 2}>
                <Checkbox
                  checked={values.hasBalcony}
                  onChange={hasBalcony => setValues({ hasBalcony })}
                >
                  {t('hasBalcony')}
                </Checkbox>
              </Box>

              <Box width={1 / 2}>
                <Checkbox
                  checked={values.hasView}
                  onChange={hasView => setValues({ hasView })}
                >
                  {t('Unobstructed view')}
                </Checkbox>
              </Box>
            </Flex>
          </Box>
        </>
      )}

      {(propertySearch?.canEditAlerts ?? true) === true && (
        <>
          <Box px={3} py={2}>
            <TextField
              select={true}
              variant="filled"
              label={t('notification')}
              value={values.notification}
              onChange={event =>
                setValues({ notification: event.target.value as any })
              }
            >
              <MenuItem value="daily">{t('daily')}</MenuItem>
              <MenuItem value="weekly">{t('weekly')}</MenuItem>
              <MenuItem value="never">{t('never')}</MenuItem>
            </TextField>
          </Box>

          <Box px={3} py={2}>
            <Checkbox
              checked={values.activateAggregator}
              onChange={activateAggregator => setValues({ activateAggregator })}
            >
              {t('activateAggregator')}
            </Checkbox>
          </Box>
        </>
      )}

      <Box p={3}>
        <TextField
          variant="filled"
          multiline={true}
          label={t('comments')}
          value={values.comment}
          onChange={event => setValues({ comment: event.target.value })}
        />
      </Box>

      <Dialog open={deletingDialog} onClose={() => setDeletingDialog(false)}>
        <DialogTitle>{t('youSureToDeleteSearch')}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setDeletingDialog(false)}>
            {t('cancel')}
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              if (propertySearch && propertySearch.id != null) {
                deletePropertySearch({
                  variables: {
                    input: { id: propertySearch.id },
                  },
                  onCompleted: () => onDelete?.(),
                });
              }
            }}
          >
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>

      <DrawerBottomToolbar>
        {propertySearch != null && (
          <Box mr="auto">
            <Button color="secondary" onClick={() => setDeletingDialog(true)}>
              {t('delete')}
            </Button>
          </Box>
        )}
        <Button
          disabled={!formik.changed}
          color="primary"
          onClick={formik.resetForm}
        >
          {t('cancel')}
        </Button>
        <ProgressButton
          disabled={!formik.changed || !formik.valid}
          loading={upserting}
          onClick={formik.submitForm}
        >
          {propertySearch?.id == null ? t('create') : t('save')}
        </ProgressButton>
      </DrawerBottomToolbar>
    </Form>
  );
};

const PropertySearchCreateForm = ({
  predefinedUser,
  onCreate,
}: {
  predefinedUser: User | null;
  onCreate: ((propertySearchId: string) => void) | null;
}) => {
  const data = useLazyLoadQuery<propertySearchDrawerTypesQuery>(
    graphql`
      query propertySearchDrawerTypesQuery {
        tenantSettings {
          defaultMapCoordinates {
            lng
            lat
            z
          }
        }
        propertyTypes {
          id
          label
          mainType
        }
      }
    `,
    {},
  );

  return (
    <PropertySearchForm
      tenantCoordinates={data.tenantSettings?.defaultMapCoordinates}
      predefinedUser={predefinedUser}
      propertySearch={null}
      propertyTypes={data.propertyTypes ?? []}
      onCreate={onCreate}
      onDelete={null}
    />
  );
};

const PropertySearchEditForm = ({
  propertySearchId,
  predefinedUser,
  onDelete,
}: {
  propertySearchId: string;
  predefinedUser: User | null;
  onDelete: (() => void) | null;
}) => {
  const data = useLazyLoadQuery<propertySearchDrawerQuery>(
    graphql`
      query propertySearchDrawerQuery($id: ID!) {
        tenantSettings {
          defaultMapCoordinates {
            lng
            lat
            z
          }
        }
        propertySearch: node(id: $id) {
          ...propertySearchDrawer_propertySearch @relay(mask: false)
        }

        propertyTypes {
          id
          label
          mainType
        }
      }
    `,
    { id: propertySearchId },
  );
  return (
    <PropertySearchForm
      tenantCoordinates={data.tenantSettings?.defaultMapCoordinates}
      predefinedUser={predefinedUser}
      propertySearch={data.propertySearch}
      propertyTypes={data.propertyTypes ?? []}
      onCreate={null}
      onDelete={onDelete}
    />
  );
};

type PropertySearchDrawerProps = {
  propertySearchId?: string | null;
  predefinedUser?: User | null;
  open: boolean;
  onClose: () => void;
  onCreate?: (propertySearchId: string) => void;
  onDelete?: () => void;
};

export const PropertySearchDrawer = (props: PropertySearchDrawerProps) => {
  const { t } = useLocale();
  const { propertySearchId } = props;
  const { propertySearchId: paramsId } = useParams();
  const id = propertySearchId ?? paramsId ?? null;

  return (
    <DrawerLayer
      width={['100vw', 'calc(100vw - 100px)', 960]}
      open={props.open}
      onClose={props.onClose}
    >
      <DrawerHeader onClose={props.onClose}>
        {id == null ? t('createPropertySearch') : t('editSearchPanel')}
      </DrawerHeader>
      <DrawerContent>
        <React.Suspense fallback={null}>
          {id == null ? (
            <PropertySearchCreateForm
              predefinedUser={props.predefinedUser ?? null}
              onCreate={props.onCreate ?? null}
            />
          ) : (
            <PropertySearchEditForm
              propertySearchId={id}
              predefinedUser={props.predefinedUser ?? null}
              onDelete={props.onDelete ?? null}
            />
          )}
        </React.Suspense>
      </DrawerContent>
    </DrawerLayer>
  );
};
