import { useCallback, useMemo } from 'react';

import { useFragment as useApolloFragment } from '@apollo/client';
import { Box, Skeleton, Stack } from '@mui/material';

import { useDebouncedHandler } from '../../../src/hooks/debounce';
import { useLocale } from '../../../src/hooks/locale';
import { getCurrencyByCountryCode } from '../../../src/locale';
import {
  type FormDefinitionType,
  RaForm,
  type RaFormOnChange,
} from '../../components/form/RaForm';
import { getCurrencySymbol } from '../../utils/formatting';

import { STEP_POTENTIAL_BUYERS_FRAGMENT } from './cmaReportsQueries';
import {
  type CMAReportComponentProps,
  FooterActions,
  useUpdateCmaReport,
} from './shared';

type StepPotentialBuyersForm = {
  max_budget: number | null;
  min_budget: number | null;
  include_potential_buyers: boolean;
};

const StepPotentialBuyers = (props: CMAReportComponentProps) => {
  const { cmaReportId } = props;
  const { locale, countryCode } = useLocale();

  const { data, complete } = useApolloFragment({
    fragment: STEP_POTENTIAL_BUYERS_FRAGMENT,
    fragmentName: 'StepPotentialBuyers',
    from: {
      __typename: 'cma_reports',
      id: cmaReportId,
    },
  });

  const [updateCmaReport, updating] = useUpdateCmaReport(
    cmaReportId,
    'page-potential-buyers',
  );

  const initialMinBudget = useMemo(() => {
    if (data?.min_budget != null) {
      return data.min_budget === 0 ? null : data.min_budget;
    }

    if (
      data?.suggested_market_value != null &&
      data.suggested_market_value !== 0
    ) {
      return data.suggested_market_value * 0.95;
    }
    return data?.lead?.property?.latest_appraisal?.min ?? null;
  }, [
    data?.lead?.property?.latest_appraisal?.min,
    data.min_budget,
    data.suggested_market_value,
  ]);

  const initialMaxBudget = useMemo(() => {
    if (data?.max_budget != null) {
      return data.max_budget === 999_999_999 ? null : data.max_budget;
    }
    if (
      data?.suggested_market_value != null &&
      data.suggested_market_value !== 0
    ) {
      return data.suggested_market_value * 1.05;
    }
    return data?.lead?.property?.latest_appraisal?.max ?? null;
  }, [
    data?.max_budget,
    data?.suggested_market_value,
    data?.lead?.property?.latest_appraisal?.max,
  ]);

  const update = useCallback(
    async (formData?: Partial<StepPotentialBuyersForm>) => {
      await updateCmaReport({
        include_potential_buyers: formData?.include_potential_buyers,
        min_budget: formData?.min_budget,
        max_budget: formData?.max_budget,
      });
    },
    [updateCmaReport],
  );

  const debouncedUpdate = useDebouncedHandler(300, update);

  const onChangeHandler: RaFormOnChange<StepPotentialBuyersForm> = formData => {
    debouncedUpdate(formData);
  };

  const onSubmit = useCallback(
    async (formData: StepPotentialBuyersForm) => {
      await update(formData);
      props.setStep(props.step + 1);
    },
    [update, props],
  );

  const stepPotentialBuyersFormDefinition: FormDefinitionType<StepPotentialBuyersForm> =
    useCallback(
      ({ t }) => [
        {
          name: 'include_potential_buyers',
          type: 'checkbox',
          style: 'switch',
          label: t('includeInReport'),
          gridProps: { md: 12 },
        },
        {
          name: 'min_budget',
          type: 'number',
          prefix: getCurrencySymbol(
            getCurrencyByCountryCode(countryCode),
            locale,
          ),
          label: t('minimumBudget'),
          gridProps: { md: 12 },
          min: 0,
          max: 999_999_999,
        },
        {
          name: 'max_budget',
          type: 'number',
          prefix: getCurrencySymbol(
            getCurrencyByCountryCode(countryCode),
            locale,
          ),
          label: t('maximumBudget'),
          gridProps: { md: 12 },
          min: 0,
          max: 999_999_999,
        },
      ],
      [countryCode, locale],
    );

  return (
    <Box
      flexGrow={1}
      position="relative"
      sx={{
        overflowY: 'auto',
        p: !complete ? 2 : undefined,
      }}
    >
      {!complete ? (
        <Stack gap={5}>
          <Stack direction={'row'}>
            <Skeleton
              variant="circular"
              width={24}
              height={24}
              sx={{ mr: 2 }}
            />
            <Skeleton variant="rounded" width={'30%'} height={24} />
          </Stack>
          <Stack gap={4}>
            {Array.from({ length: 2 }).map((_, index) => (
              <Stack key={`budget-${index}`} gap={1}>
                <Skeleton variant="rounded" height={30} width={'40%'} />
                <Skeleton variant="rounded" height={40} />
              </Stack>
            ))}
          </Stack>
        </Stack>
      ) : (
        <RaForm
          freezeInitialDefaultValues={true}
          formDefinition={stepPotentialBuyersFormDefinition}
          onSubmit={onSubmit}
          contentScrollable
          defaultValues={{
            include_potential_buyers: data?.include_potential_buyers,
            min_budget: initialMinBudget,
            max_budget: initialMaxBudget,
          }}
          onChange={onChangeHandler}
          actionButtonsComponent={
            <FooterActions<StepPotentialBuyersForm>
              {...props}
              updating={updating}
            />
          }
        />
      )}
    </Box>
  );
};

export default StepPotentialBuyers;
