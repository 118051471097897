import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { HomeWorkOutlined } from '@mui/icons-material';
import {
  type AutocompleteProps,
  Avatar,
  Checkbox,
  type InputProps,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  debounce,
  useTheme,
} from '@mui/material';
import { Image } from '@realadvisor/image';

import { useLocale } from '../../src/hooks/locale';
import type {
  LotSelectFragment,
  SearchLotsQuery,
} from '../__generated__/graphql';
import { formatAddress } from '../utils/formatting';

import { RaAutoComplete } from './data-grid/RaAutoComplete';

const LOT_SELECT_FRAGMENT = gql`
  fragment LotSelect on lots {
    id
    title
    property {
      id
      route
      street_number
      postcode
      locality
      country_code
      property_images(order_by: { order_nr: asc }, limit: 1) {
        order_nr
        image {
          id
          url
        }
      }
    }
    broker {
      id
      full_name
    }
  }
`;

export const SEARCH_LOTS = gql`
  ${LOT_SELECT_FRAGMENT}
  query SearchLots($q: String!) {
    search_lots_tsv(
      args: { search_text: $q }
      limit: 10
      order_by: { created_at: desc }
    ) {
      id
      ...LotSelect
    }
  }
`;

const GET_DEFAULT_LOT = gql`
  ${LOT_SELECT_FRAGMENT}
  query GetDefaultLot($id: uuid!) {
    lots_by_pk(id: $id) {
      id
      ...LotSelect
    }
  }
`;

interface LotSelectProps
  extends Omit<
    AutocompleteProps<LotSelectFragment, false, false, false>,
    'renderInput' | 'options' | 'onChange'
  > {
  lotId?: string;
  onChange?: (lotId: string | null) => void;
  InputProps?: InputProps;
}

export const LotSelectItem: React.FC<{
  lot: LotSelectFragment;
  props: React.HTMLAttributes<HTMLLIElement>;
  selected?: boolean;
}> = ({ lot, props, selected }) => {
  const colors = useTheme().palette;

  return (
    <ListItem
      {...props}
      key={lot.id}
      style={{
        padding: '1px 10px',
      }}
    >
      <ListItemIcon>
        {selected != null && (
          <Checkbox
            edge="start"
            checked={selected}
            tabIndex={-1}
            disableRipple
          />
        )}
        <ListItemAvatar>
          <Avatar variant="rounded" sx={{ width: 50, height: 50 }}>
            {lot?.property?.property_images &&
            lot?.property?.property_images.length > 0 ? (
              <Image
                src={lot?.property?.property_images[0]?.image.url ?? ''}
                options={{ w: 256, f: 'jpg' }}
                objectFit="cover"
              />
            ) : (
              <HomeWorkOutlined
                style={{ fontSize: 32, color: colors.divider, margin: 8 }}
              />
            )}
          </Avatar>
        </ListItemAvatar>
      </ListItemIcon>
      <ListItemText
        sx={theme => theme.text.ellipsis}
        primary={[formatAddress(lot.property, ', '), lot.title]
          .filter(Boolean)
          .join(' | ')}
        secondary={lot?.broker?.full_name}
      />
    </ListItem>
  );
};

export const LotSelect = ({
  onChange,
  lotId,
  autoFocus = false,
  disabled = false,
  sx,
  InputProps,
}: LotSelectProps) => {
  const { t } = useLocale();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = useState<LotSelectFragment[]>([]);
  const prevLotId = React.useRef<string | undefined>(lotId);
  const [selectedValue, setSelectedValue] = useState<LotSelectFragment | null>(
    null,
  );

  const { data: defaultLot } = useQuery(GET_DEFAULT_LOT, {
    skip: !lotId,
    variables: { id: lotId },
  });

  useEffect(() => {
    if (defaultLot) {
      setSelectedValue(defaultLot.lots_by_pk);
    }
  }, [defaultLot]);

  if (prevLotId.current !== lotId) {
    prevLotId.current = lotId;

    if (lotId == null) {
      setSelectedValue(null);
    }
  }

  const [searchLots, { loading, error }] =
    useLazyQuery<SearchLotsQuery>(SEARCH_LOTS);

  const debouncedSearch = useMemo(
    () =>
      debounce(value => {
        searchLots({
          variables: {
            q: value,
          },
        }).then(response => {
          setOpen(true);
          setOptions(response.data?.search_lots_tsv ?? []);
        });
      }, 275),
    [searchLots],
  );

  return (
    <RaAutoComplete<LotSelectFragment>
      sx={sx}
      InputProps={{
        ...InputProps,
        placeholder: InputProps?.placeholder ?? t('Search listings'),
      }}
      open={open}
      setOpen={setOpen}
      options={options}
      setOptions={setOptions}
      loading={loading}
      value={selectedValue}
      error={error != null}
      autoFocus={autoFocus}
      debouncedSearch={debouncedSearch}
      onChange={(_event, value) => {
        setSelectedValue(value);
        onChange?.(value?.id ?? null);
      }}
      disabled={disabled}
      getOptionLabel={option => formatAddress(option.property, ', ')}
      renderOption={(props, option) => (
        <LotSelectItem lot={option} props={props} />
      )}
    />
  );
};
