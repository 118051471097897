import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Alert, Box, Button, Stack, Typography } from '@mui/material';
import type {
  GridColDef,
  GridRowSelectionModel,
  GridRowsProp,
} from '@mui/x-data-grid-premium';
import { gridClasses } from '@mui/x-data-grid-premium';
import { endOfDay, formatDistanceStrict, isBefore, isToday } from 'date-fns';
import {
  Route,
  Routes,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { AssignmentInd } from '../../../icons/assignment-ind';
import { AssignmentTurnedIn } from '../../../icons/assignment-turned-in';
import { MeetingRoom } from '../../../icons/meeting-room';
import { NoMeetingRoom } from '../../../icons/no-meeting-room';
import { NoteOutline } from '../../../icons/note-outline';
import { Phone } from '../../../icons/phone';
import { PhoneMissed } from '../../../icons/phone-missed';
import { RoundWarning } from '../../../icons/round-warning';
import { ListToolbar } from '../../../list-toolbar/ListToolbar';
import { fromGlobalId, toGlobalId } from '../../../shared/global-id';
import { useLocale } from '../../../src/hooks/locale';
import { useTheme } from '../../../src/hooks/theme';
import { useLeadsParams } from '../../../src/routes/Leads/LeadsFilters';
import { LeadCreateDrawer } from '../../../src/shared/lead-create-drawer';
import {
  getHorizonLabel,
  translateAppraisalReason,
} from '../../../src/utils/lead-labels';
import { formatNumberByPrecision } from '../../../src/utils/number-format';
import {
  type GetLeadsCountQuery,
  type GetLeadsCountQueryVariables,
  type GetLeadsQuery,
  type GetLeadsQueryVariables,
  type GetTenantSettingsQuery,
  type GetTenantSettingsQueryVariables,
  type Leads_Order_By,
} from '../../__generated__/graphql';
import { RaDataGrid } from '../../components/data-grid/RaDataGrid';
import { UserInfo } from '../../components/data-grid/UserInfo';
import { SmartListTitle } from '../../components/SmartListTitle';
import { TimeAgo } from '../../components/TimeAgo';
import { useAppData } from '../../providers/AppDataProvider';
import { getAppraisalPerception } from '../../utils/appraisal';
import { prepareWhereClauseQuery } from '../../utils/parseWhereClause';
import { GET_TENANT_SETTINGS } from '../settings/tenant-settings/tenantSettingsQueries';

import DialogAssignBroker from './DialogAssignBroker';
import DialogUpdatePipeline from './DialogUpdatePipeline';
import DialogUpdateStatus from './DialogUpdateStatus';
import { LeadDrawer } from './LeadDrawer';
import { GET_LEADS, GET_LEADS_COUNT } from './leadsQueries';
import LegacyLeadDrawer from './LegacyLeadDrawer';

type Lead = NonNullable<GetLeadsQuery['leads'][number]>;

const ActivityCell = ({
  node,
  type,
}: {
  node: Lead;
  type: 'next' | 'latest';
}) => {
  const { t, dateLocale } = useLocale();
  const { colors, text } = useTheme();
  let activity = node?.next_activity?.[0];
  if (type === 'latest') {
    activity = node?.last_activity?.[0];
  }
  if (type === 'next' && activity == null) {
    return (
      <Stack flexDirection="row" alignItems="center">
        <RoundWarning size={18} fill={colors.error} css={{ marginRight: 4 }} />
        {t('No activity')}
      </Stack>
    );
  }
  if (type === 'latest' && activity == null) {
    const { created_at, requalified_at } = node;
    const date = requalified_at
      ? new Date(requalified_at)
      : new Date(created_at);
    const distance = formatDistanceStrict(date, Date.now(), {
      locale: dateLocale,
      addSuffix: true,
    });
    return (
      <div>
        <div>{requalified_at ? t('Lead requalified') : t('Lead created')}</div>
        <div css={{ color: colors.grey700 }}>{distance}</div>
      </div>
    );
  }
  let overdue = false;
  if (
    type === 'next' &&
    activity.activity_type !== 'note' &&
    activity.due_at != null
  ) {
    overdue = new Date(activity.due_at) < new Date();
  }

  let due_at = null;

  if (activity.done_at != null) {
    if (isToday(new Date(activity.done_at))) {
      due_at = t('Today');
    } else if (activity.done_at != null) {
      due_at = (
        <div css={{ color: overdue ? colors.errorText : colors.grey700 }}>
          {formatDistanceStrict(
            endOfDay(new Date(activity.done_at)),
            Date.now(),
            {
              locale: dateLocale,
              addSuffix: true,
            },
          )}
        </div>
      );
    }
  } else if (activity.activity_type !== 'note' && activity.start_date != null) {
    if (isToday(new Date(activity.start_date))) {
      due_at = t('Today');
    } else if (activity.start_date != null) {
      due_at = (
        <div css={{ color: overdue ? colors.errorText : colors.grey700 }}>
          {formatDistanceStrict(
            endOfDay(new Date(activity.start_date)),
            Date.now(),
            {
              locale: dateLocale,
              addSuffix: true,
            },
          )}
        </div>
      );
    }
  } else if (activity.activity_type !== 'note' && activity.due_at != null) {
    due_at = (
      <div css={{ color: overdue ? colors.errorText : colors.grey700 }}>
        {formatDistanceStrict(new Date(activity.due_at), Date.now(), {
          locale: dateLocale,
          addSuffix: true,
        })}
      </div>
    );
  } else if (activity.created_at != null) {
    if (isToday(new Date(activity.created_at))) {
      due_at = t('Today');
    } else if (activity.created_at != null) {
      due_at = (
        <div css={{ color: overdue ? colors.errorText : colors.grey700 }}>
          {formatDistanceStrict(
            endOfDay(new Date(activity.created_at)),
            Date.now(),
            {
              locale: dateLocale,
              addSuffix: true,
            },
          )}
        </div>
      );
    }
  }

  const overdueStyles = overdue ? { color: colors.errorText } : null;

  if (activity.activity_type === 'task') {
    const { subject } = activity;
    return (
      <Box css={overdueStyles} title={subject ?? ''}>
        <Stack flexDirection="row" alignItems="center">
          <AssignmentTurnedIn
            fill={overdue ? colors.error : colors.mediumText}
            size={18}
            css={{ marginRight: 4 }}
          />
          <div css={[text.truncate(1), text.ellipsis]}>
            {subject == null || subject.trim() === '' ? t('task') : subject}
          </div>
        </Stack>
        {due_at}
      </Box>
    );
  }

  if (activity.activity_type === 'call') {
    const { note, success, done } = activity;
    const Icon = success === false && done === true ? PhoneMissed : Phone;
    return (
      <div css={overdueStyles} title={note ?? ''}>
        <Stack flexDirection="row" alignItems="center">
          <Icon
            size={18}
            fill={overdue ? colors.error : colors.mediumText}
            css={{ marginRight: 4 }}
          />
          <div css={[text.truncate(1), text.ellipsis]}>
            {note == null || note.trim() === '' ? t('call') : note}
          </div>
        </Stack>
        {due_at}
      </div>
    );
  }

  if (activity.activity_type === 'visit') {
    const { note, success, done } = activity;
    const Icon =
      success === false && done === true ? NoMeetingRoom : MeetingRoom;
    return (
      <div css={overdueStyles}>
        <Stack flexDirection="row" alignItems="center" css={text.ellipsis}>
          <Icon
            size={18}
            fill={overdue ? colors.error : colors.mediumText}
            css={{ marginRight: 4 }}
          />
          <div css={[text.truncate(1), text.ellipsis]}>
            {note == null || note.trim() === '' ? t('visit') : note}
          </div>
        </Stack>
        {due_at}
      </div>
    );
  }

  if (activity.activity_type === 'note') {
    const { note } = activity;
    return (
      <div css={overdueStyles}>
        <Stack flexDirection="row" alignItems="center" css={text.ellipsis}>
          <NoteOutline
            size={18}
            fill={overdue ? colors.error : colors.mediumText}
            css={{ marginRight: 4 }}
          />
          <div css={[text.truncate(1), text.ellipsis]}>
            {note == null || note.trim() === '' ? t('note') : note}
          </div>
        </Stack>
        {due_at}
      </div>
    );
  }

  if (activity.activity_type === 'assignment') {
    return (
      <div css={overdueStyles}>
        <Stack flexDirection="row" alignItems="center">
          <AssignmentInd
            size={18}
            fill={overdue ? colors.error : colors.mediumText}
            css={{ marginRight: 4 }}
          />
          {type === 'latest' ? t('leadClaimed') : t('claimLead')}
        </Stack>
        {due_at}
      </div>
    );
  }

  return null;
};

const useColumns = () => {
  const { t, locale } = useLocale();
  const { text, textColor } = useTheme();
  const { me } = useAppData();

  const formatAppraisalValues = (
    realadvisor: NonNullable<NonNullable<Lead['property']>>['latest_appraisal'],
  ) => {
    if (!realadvisor || realadvisor.min == null || realadvisor.max == null) {
      return '-';
    }

    let min = realadvisor.min / 1000;
    let max = realadvisor.max / 1000;
    let minUnit = 'k';
    let maxUnit = 'k';
    let minString = '';
    let maxString = '';

    if (min < 1000) {
      minString = min.toLocaleString(locale, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    }

    if (max < 1000) {
      maxString = max.toLocaleString(locale, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    }

    if (min >= 1000) {
      min = min / 1000;
      minString = min.toLocaleString(locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      minUnit = 'm';
    }

    if (max >= 1000) {
      max = max / 1000;
      maxString = max.toLocaleString(locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      maxUnit = 'm';
    }

    return `${minString}${minUnit} - ${maxString}${maxUnit}`;
  };

  const columns: GridColDef<Lead>[] = [
    {
      headerName: t('Date'),
      width: 100,
      field: 'requalified_or_created_at',
      display: 'flex',
      renderCell: ({ row }) =>
        me?.is_admin ? (
          <div>
            {row.requalified_at && (
              <div>
                ♺&nbsp;
                <TimeAgo dateString={row.requalified_at} />
              </div>
            )}
            <div>
              <TimeAgo dateString={row.created_at} />
            </div>
          </div>
        ) : (
          <TimeAgo dateString={row.requalified_at ?? row.created_at} />
        ),
    },
    {
      headerName: t('Contact'),
      width: 220,
      field: 'contact',
      sortable: false,
      renderCell: ({ row }) =>
        row.contact && (
          <UserInfo
            user={row.contact}
            anonymize={
              !me?.is_admin && row.created_by !== me?.id && !row.claimed_by
            }
          />
        ),
    },
    {
      headerName: t('Address'),
      width: 220,
      field: 'property_address',
      display: 'flex',
      sortable: false,
      renderCell: ({ row }) => (
        <Stack direction="column">
          {(me?.is_admin || row.created_by === me?.id || row.claimed_by) && (
            <div>{[row.property?.route, ' ', row.property?.street_number]}</div>
          )}
          <div css={[text.caption, textColor('mediumText')]}>
            {[row.property?.postcode, ' ', row.property?.locality]}
          </div>
        </Stack>
      ),
    },
    {
      headerName: t('value'),
      width: 135,
      field: 'appraisal_perceived_value,property.latest_appraisal.value',
      display: 'flex',
      sortable: false,
      renderCell: ({ row }) => (
        <Stack direction="row" alignItems="center" gap={1}>
          <div css={{ fontSize: 22 }}>
            {getAppraisalPerception(
              row.appraisal_perception,
              row.appraisal_perceived_value,
              row.property?.latest_appraisal?.max,
              row.property?.latest_appraisal?.min,
            )}
          </div>
          <Stack>
            <div>
              {formatAppraisalValues(row?.property?.latest_appraisal ?? null)}
            </div>
            <div css={[text.caption, textColor('mediumText')]}>
              {formatNumberByPrecision(
                row?.appraisal_perceived_value ?? null,
                locale,
              )}
            </div>
          </Stack>
        </Stack>
      ),
    },
    {
      headerName: t('intention'),
      width: 150,
      field: 'appraisal_reason',
      display: 'flex',
      sortable: false,
      renderCell: ({ row }) => {
        const horizonLabel = getHorizonLabel(t, {
          appraisalReason: row.appraisal_reason,
          saleHorizon: row.sale_horizon,
          buyHorizon: row.buy_horizon,
        });

        return (
          <div>
            <div>
              {row.appraisal_reason != null
                ? translateAppraisalReason(t, row.appraisal_reason)
                : '-'}
            </div>
            {horizonLabel != null && (
              <div css={[text.caption, textColor('mediumText')]}>
                {horizonLabel}
              </div>
            )}
          </div>
        );
      },
    },
    {
      headerName: t('propertyType'),
      width: 140,
      field: 'property_type',
      display: 'flex',
      sortable: false,
      renderCell: ({ row }) => {
        const { main_type, label } = row.property?.property_type ?? {};
        if (main_type == null || label == null) {
          return null;
        }
        const mainTypeLabel =
          {
            HOUSE: t('house'),
            APPT: t('apartment'),
            PROP: t('Land'),
            PARK: t('parking'),
            INDUS: t('commercial'),
            GASTRO: t('hotellerie'),
            AGRI: t('agriculturalProperty'),
            SECONDARY: t('secondary'),
            GARDEN: t('Garden'),
          }[main_type] ?? main_type;
        return (
          <Stack>
            <div>{mainTypeLabel}</div>
            <div css={[text.caption, textColor('mediumText')]}>{label}</div>
          </Stack>
        );
      },
    },
    {
      headerName: t('surfaces'),
      width: 100,
      field: 'property',
      display: 'flex',
      sortable: false,
      renderCell: ({ row }) => {
        const livingSurface = row.property?.living_surface;
        const builtSurface = row.property?.built_surface;
        const landSurface = row.property?.land_surface;
        return (
          <div css={{ whiteSpace: 'nowrap' }}>
            <div>
              {livingSurface != null
                ? livingSurface.toLocaleString(locale) + ' m²'
                : builtSurface != null
                ? builtSurface.toLocaleString(locale) + ' m²'
                : '-'}
            </div>
            <div css={[text.caption, textColor('mediumText')]}>
              {landSurface != null
                ? landSurface.toLocaleString(locale) + ' m²'
                : '-'}
            </div>
          </div>
        );
      },
    },
    {
      headerName: t('stage'),
      field: 'stage',
      width: 240,
      display: 'flex',
      renderCell: ({ row }) => (
        <div>
          <div>{row?.stage?.label || '-'}</div>
          <Typography
            variant="caption"
            color={
              row.status === 'active'
                ? 'info.main'
                : row.status === 'won'
                ? 'success.main'
                : row.status === 'lost'
                ? 'error.main'
                : 'neutral.main'
            }
          >
            {row.leads_status?.label ? row.leads_status.label : row.status}
          </Typography>
        </div>
      ),
      sortable: false,
    },
    {
      headerName: t('nextActivity'),
      width: 200,
      field: 'next_activity_aggregate.min.due_at',
      display: 'flex',
      renderCell: ({ row }) => <ActivityCell node={row} type="next" />,
    },
    {
      headerName: t('latestActivity'),
      width: 200,
      field: 'last_activity_aggregate.max.consolidated_done_at',
      display: 'flex',
      renderCell: ({ row }) => <ActivityCell node={row} type="latest" />,
    },
    {
      headerName: t('broker'),
      width: 200,
      field: 'broker',
      display: 'flex',
      renderCell: ({ row }) => row.broker && <UserInfo user={row.broker} />,
      sortable: false,
    },
    {
      headerName: t('Source'),
      width: 150,
      field: 'source.label',
      valueGetter: (_, row) => row?.source?.label || '-',
      display: 'flex',
      sortable: false,
    },
  ];

  return columns;
};

// Optimize order by query for admins
const optimizeOrderByQuery = (
  order_by: Leads_Order_By[],
  is_admin: boolean,
): Leads_Order_By[] => {
  // replace  { next_activity_aggregate: { min: { due_at: $1 } }}  by { next_activity_due_at: $1 }
  // replace  { last_activity_aggregate: { max { consolidated_done_at: $1 } }}  by { last_activity_done_at: $1 }
  if (is_admin) {
    return order_by.map(order => {
      if (order.next_activity_aggregate?.min?.due_at) {
        return {
          next_activity_due_at: order.next_activity_aggregate.min.due_at,
        };
      }
      if (order.last_activity_aggregate?.max?.consolidated_done_at) {
        return {
          last_activity_done_at:
            order.last_activity_aggregate.max.consolidated_done_at,
        };
      }
      return order;
    });
  }
  return order_by;
};

export const Leads = () => {
  const { t } = useLocale();
  const [searchParams] = useSearchParams();
  const editMode = searchParams.get('edit') === 'true';
  const refresh = searchParams.get('refresh') === 'true';
  const navigate = useNavigate();
  const { colors } = useTheme();
  const { me } = useAppData();
  const isAdmin = me?.is_admin;

  const [assignBrokerDialog, setAssignBrokerDialog] = useState(false);
  const [pipelineDialog, setPipelineDialog] = useState(false);
  const [statusDialog, setStatusDialog] = useState(false);

  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const handleSelectionChange = useCallback(
    (newSelection: GridRowSelectionModel) => {
      setSelectedRows(newSelection);
    },
    [],
  );

  useEffect(() => {
    if (selectedRows.length > 0 && !editMode) {
      setSelectedRows([]);
    }
  }, [selectedRows, editMode]);

  // We get the led teams of the user to filter the leads
  // TODO: Can be removed once we fully migrate to non-exclusive leads
  const myUserId = me?.id;
  const myLedUsersIds = useMemo(
    () =>
      me?.user_ids_in_led_teams.flatMap(item =>
        item.team.teams_users.map(user => user.user_id),
      ) || [],
    [me],
  );

  const where: GetLeadsQueryVariables['where'] = useMemo(
    () => ({
      // Here we manually exclude the leads from visibility (lead_agents)
      // TODO: Remove where clause once we fully migrate to non-exclusive leads
      _and: [
        prepareWhereClauseQuery(JSON.parse(searchParams.get('where') || '{}')),
        // Can be removed once we fully migrate to non-exclusive leads
        !isAdmin
          ? {
              // Performance hack to run next permission on a smaller set of leads
              _or: [
                {
                  created_by: {
                    _in: [myUserId, ...myLedUsersIds],
                  },
                },
                {
                  broker_id: {
                    _in: [myUserId, ...myLedUsersIds],
                  },
                },
              ],
              // Client side permission.
              // TODO: remove once we fully migrate to non-exclusive leads
              _not: {
                _and: [
                  { lead_agents: {} },
                  {
                    _and: [
                      {
                        broker_id: {
                          _nin: [...myLedUsersIds, myUserId],
                        },
                      },
                      {
                        created_by: {
                          _nin: [...myLedUsersIds, myUserId],
                        },
                      },
                    ],
                  },
                ],
              },
            }
          : {},
      ],
    }),
    [searchParams, isAdmin, myUserId, myLedUsersIds],
  );

  const order_by: Leads_Order_By[] = JSON.parse(
    searchParams.get('order_by') || '[{ "requalified_or_created_at": "desc" }]',
  );

  const limit = parseInt(searchParams.get('limit') ?? '100');
  const offset = parseInt(searchParams.get('offset') ?? '0');

  const legacyMatch = useMatch('/leads/*');

  const leadLink = (id: string) =>
    legacyMatch
      ? {
          pathname: `/leads/${toGlobalId('Lead', id)}`,
          search: searchParams.toString(),
        }
      : {
          pathname: `/v2/leads/${id}`,
          search: searchParams.toString(),
        };

  const { data, loading, error, refetch } = useQuery<
    GetLeadsQuery,
    GetLeadsQueryVariables
  >(GET_LEADS, {
    variables: {
      limit,
      offset,
      where,
      order_by: optimizeOrderByQuery(order_by, isAdmin === true),
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: countData, refetch: refetchCountData } = useQuery<
    GetLeadsCountQuery,
    GetLeadsCountQueryVariables
  >(GET_LEADS_COUNT, {
    variables: {
      where,
    },
  });

  const { data: tenantSettings } = useQuery<
    GetTenantSettingsQuery,
    GetTenantSettingsQueryVariables
  >(GET_TENANT_SETTINGS, {
    variables: {
      id: me?.tenant_id ?? '',
    },
  });

  // refetch data and count when the refresh param is set to true, used on lead drawer closed in some cases
  if (refresh) {
    searchParams.delete('refresh');
    refetchCountData();
    refetch();
    navigate({
      pathname: '.',
      search: searchParams.toString(),
    });
  }

  const columns = useColumns();
  const leads: GridRowsProp<Lead> = data?.leads ?? [];
  const [params] = useLeadsParams();

  const pipelineId =
    params.pipelineId_eq ??
    tenantSettings?.tenants_by_pk?.default_pipeline_id ??
    null;

  return (
    <>
      <SmartListTitle tableName="leads" defaultTitle={t('Leads')} />
      <ListToolbar
        tableName="leads"
        newLink={{
          pathname: 'new',
          search: searchParams.toString(),
        }}
        refetch={refetch}
        quickFilters={[
          {
            label: t('Date'),
            path: ['leads_bool_exp', 'requalified_or_created_at'],
          },
          {
            label: t('Source'),
            path: ['leads_bool_exp', 'source', 'name', '_in'],
            displayedColumn: 'label',
            filter: {
              path: ['source', 'type', '_in'],
              value: 'lead_source_types',
            },
          },
          {
            label: t('Status'),
            path: ['leads_bool_exp', 'status', '_in'],
          },
          {
            label: 'Completed',
            path: ['leads_bool_exp', 'completed'],
          },
          {
            label: t('Relation'),
            path: ['leads_bool_exp', 'relationship', '_in'],
          },
          {
            label: t('Appraisal Reason'),
            path: ['leads_bool_exp', 'appraisal_reason', '_in'],
          },
          {
            label: t('Sale Horizon'),
            path: ['leads_bool_exp', 'sale_horizon', '_in'],
          },
          {
            label: t('Contact'),
            path: ['leads_bool_exp', 'contact'],
          },
          {
            label: t('Broker'),
            path: ['leads_bool_exp', 'broker'],
          },
          {
            label: t('Team'),
            path: ['leads_bool_exp', 'broker', 'teams_users', 'team'],
          },
          {
            label: t('Location'),
            path: ['leads_bool_exp', 'property', 'places'],
          },
          {
            label: 'Value',
            path: ['leads_bool_exp', 'property', 'latest_appraisal', 'value'],
          },
          {
            label: t('Mandate Probability'),
            path: ['leads_bool_exp', 'mandate_probability'],
          },
          {
            label: t('Predicted Listing Date'),
            path: ['leads_bool_exp', 'predicted_listing_date'],
          },
        ]}
        tableFiltersTables={[
          {
            name: 'leads',
            label: 'Leads',
            relationshipPath: null,
            fields: { table: 'leads' },
          },
          {
            name: 'activities',
            label: 'Activities',
            relationshipPath: 'activities',
            fields: { table: 'activities' },
          },
          {
            name: 'properties',
            label: 'Property',
            relationshipPath: 'property',
            fields: { table: 'properties' },
          },
          {
            name: 'broker',
            label: 'Broker',
            relationshipPath: 'broker',
            fields: {
              table: 'users',
              whitelistedColumns: ['membership_type', 'account_manager.id'],
            },
          },
          {
            name: 'lots',
            label: 'Lots',
            relationshipPath: 'lots',
            fields: { table: 'lots' },
          },
          {
            name: 'property_transactions',
            label: 'Transactions',
            relationshipPath: 'lots.property_transactions',
            fields: { table: 'property_transactions' },
          },
          {
            name: 'lead_agents',
            label: 'Lead agents',
            relationshipPath: 'lead_agents',
            fields: { table: 'lead_agents' },
          },
        ]}
        bulkActions={
          <>
            <Button
              disableElevation
              disabled={selectedRows.length === 0}
              onClick={() => {
                setAssignBrokerDialog(true);
              }}
            >
              {t('Assign to broker')}
            </Button>
            <Button
              disableElevation
              disabled={selectedRows.length === 0}
              onClick={() => {
                setStatusDialog(true);
              }}
            >
              {t('Change status')}
            </Button>
            <Button
              disableElevation
              disabled={selectedRows.length === 0}
              onClick={() => {
                setPipelineDialog(true);
              }}
            >
              {t('Change stage')}
            </Button>
            <DialogAssignBroker
              leadsIds={selectedRows as string[]}
              open={assignBrokerDialog}
              onCancel={() => setAssignBrokerDialog(false)}
            />
            <DialogUpdateStatus
              leadsIds={selectedRows as string[]}
              open={statusDialog}
              onCancel={() => setStatusDialog(false)}
            />
            <DialogUpdatePipeline
              leadsIds={selectedRows as string[]}
              pipeline="sales"
              initialPipelineId={pipelineId}
              open={pipelineDialog}
              onCancel={() => setPipelineDialog(false)}
            />
          </>
        }
      />
      {error && (
        <Alert severity="error" sx={{ m: 2 }}>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      )}
      <RaDataGrid
        loading={loading}
        rows={leads}
        columns={columns}
        rowCount={countData?.leads_aggregate.aggregate?.count ?? 0}
        onRowClick={({ id }, e) => {
          if (e.metaKey || e.ctrlKey) {
            window.open(leadLink(id as string).pathname, '_blank');
            return;
          }
          navigate(leadLink(id as string));
        }}
        disableColumnSelector={false}
        disableColumnFilter
        checkboxSelection={editMode}
        rowSelectionModel={selectedRows}
        disableRowSelectionOnClick
        onRowSelectionModelChange={handleSelectionChange}
        sortingMode="server"
        slotProps={{
          columnsManagement: {
            getTogglableColumns: () => columns.map(column => column.field),
            toggleAllMode: 'filteredOnly',
          },
          toolbar: {
            csvOptions: {
              disableToolbarButton: true,
            },
            printOptions: {
              disableToolbarButton: true,
            },
          },
        }}
        getRowClassName={({ row }) => {
          return row.next_activity?.[0]?.due_at &&
            isBefore(new Date(row.next_activity?.[0]?.due_at), Date.now())
            ? 'overdue'
            : '';
        }}
        sx={{
          [`& .${gridClasses.row}.overdue`]: {
            backgroundColor: colors.red200,
          },
        }}
      />
      <Suspense fallback={null}>
        <Routes>
          <Route
            path="new/*"
            element={
              <LeadCreateDrawer
                open
                onClose={() =>
                  navigate({
                    pathname: '.',
                    search: searchParams.toString(),
                  })
                }
                onCreate={leadId => {
                  const id = legacyMatch ? leadId : fromGlobalId(leadId);
                  navigate({
                    pathname: `./${id}`,
                    search: searchParams.toString(),
                  });
                }}
              />
            }
          />
          <Route
            path=":leadId/*"
            element={legacyMatch ? <LegacyLeadDrawer /> : <LeadDrawer />}
          />
        </Routes>
      </Suspense>
    </>
  );
};

export default Leads;
