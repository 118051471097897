/**
 * @generated SignedSource<<2b9100fd6fe75106ea831013973a6343>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConnectEmailFormQuery$variables = {};
export type ConnectEmailFormQuery$data = {
  readonly me: {
    readonly nylasAccount: {
      readonly __typename: "NylasAccount";
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"EmailForm_me">;
  } | null;
};
export type ConnectEmailFormQuery = {
  response: ConnectEmailFormQuery$data;
  variables: ConnectEmailFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConnectEmailFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EmailForm_me"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NylasAccount",
            "kind": "LinkedField",
            "name": "nylasAccount",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ConnectEmailFormQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gender",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Email",
            "kind": "LinkedField",
            "name": "primaryEmail",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PhoneNumber",
            "kind": "LinkedField",
            "name": "primaryPhoneNumber",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "formattedNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "organisation",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "formattedAddress",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "language",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emailSignature",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NylasAccount",
            "kind": "LinkedField",
            "name": "nylasAccount",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9b78b303f8e8074baa2be040beb8e194",
    "id": null,
    "metadata": {},
    "name": "ConnectEmailFormQuery",
    "operationKind": "query",
    "text": "query ConnectEmailFormQuery {\n  me {\n    ...EmailForm_me\n    nylasAccount {\n      __typename\n      id\n    }\n    id\n  }\n}\n\nfragment EmailForm_me on User {\n  id\n  firstName\n  lastName\n  gender\n  primaryEmail {\n    id\n    email\n  }\n  primaryPhoneNumber {\n    id\n    formattedNumber\n  }\n  organisation {\n    id\n    name\n    formattedAddress\n  }\n  title\n  language\n  emailSignature\n}\n"
  }
};
})();

(node as any).hash = "7e42c3e670dcd51bad60c4b2400bb8e8";

export default node;
