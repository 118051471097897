/**
 * @generated SignedSource<<d173f3d265e27b5349d4ac96ccbb91d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type leadLotCard_lead$data = {
  readonly id: string;
  readonly lot: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "leadLotCard_lead";
};
export type leadLotCard_lead$key = {
  readonly " $data"?: leadLotCard_lead$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadLotCard_lead">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadLotCard_lead",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Lot",
      "kind": "LinkedField",
      "name": "lot",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};
})();

(node as any).hash = "a54451c879f63f926565731ebf177606";

export default node;
