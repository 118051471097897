import * as React from 'react';

import { fetchApiQuery } from '../../networking';
import { email_service_origin } from '../config';
import { openWindow } from '../utils/openWindow';
import { getTenantSlugStageAndSubdomainFromHostname } from '../utils/tenant';

type Callback = (error: Error | null) => void;
type Hook = (onComplete?: Callback) => void;

type RouteParams = {
  mountNode: HTMLElement;
};

const postData = async (pathname: string) => {
  const request: RequestInit = {
    method: 'GET',
    credentials: 'include',
  };
  return fetchApiQuery(`${email_service_origin}${pathname}`, request);
};

export const nylasLoginReturnRoute = ({ mountNode }: RouteParams): boolean => {
  const url = new URL(location.href);
  const state = new URL(location.href).searchParams.get('state') ?? '';
  if (state === '') {
    return false;
  }

  const stateUrl = new URL(state);
  if (url.hostname !== stateUrl.hostname) {
    url.hostname = stateUrl.hostname;
    location.replace(url.href);
    return true;
  }

  //if slug is present, strip it from the hostname
  const { slug, stage } = getTenantSlugStageAndSubdomainFromHostname(
    location.hostname,
  );
  if (slug != null) {
    if (stage != null) {
      url.hostname = `${stage}.${url.hostname.split('.').slice(1).join('.')}`;
    } else {
      url.hostname = url.hostname.replace(slug + '.', '');
    }
  }

  const code = url.searchParams.get('code');
  url.pathname = '/api/nylas/login/return';
  url.searchParams.delete('state');
  url.searchParams.delete('code');

  const redirectUrl = new URL(url.toString());
  redirectUrl.pathname = '/nylas/login/return';

  url.searchParams.set('redirect', redirectUrl.toString());
  url.searchParams.set('code', code ?? '');

  if (location.pathname === `/nylas/login/return`) {
    postData(`/api/nylas/login/return?${url.searchParams.toString()}`)
      .then(response => {
        if (response.status === 401) {
          location.reload();
        } else {
          return response.json().then(data => {
            const state = new URL(location.href).searchParams.get('state');
            if (state != null) {
              const openerOrigin = new URL(state).origin;
              if (data.error == null) {
                window.opener?.postMessage('login:success', openerOrigin);
              } else {
                window.opener?.postMessage(
                  `login:error:${data.error}`,
                  openerOrigin,
                );
              }
            }
          });
        }
      })
      .catch(error => {
        console.error('error', error);
        mountNode.textContent = error.message;
      });
    return true;
  } else {
    return false;
  }
};

export const useEmailClientConnect = (): Hook => {
  const mounted = React.useRef(false);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  const connectEmailClient = (onComplete?: (error: Error | null) => void) => {
    const currentUrl = new URL(location.href);
    const { slug, stage } = getTenantSlugStageAndSubdomainFromHostname(
      location.hostname,
    );

    //if slug is present, strip it from the hostname
    if (slug != null) {
      if (stage != null) {
        currentUrl.hostname = `${stage}.${currentUrl.hostname
          .split('.')
          .slice(1)
          .join('.')}`;
      } else {
        currentUrl.hostname = currentUrl.hostname.replace(slug + '.', '');
      }
    }

    const redirectUrl = new URL('/nylas/login/return', currentUrl.origin);
    const url = new URL('/api/nylas/login', email_service_origin); //TODO: make this dynamic
    url.searchParams.set('return', location.href);
    url.searchParams.set('redirect', redirectUrl.toString());
    openWindow(url.toString(), {
      onPostMessage({ data }) {
        if (mounted.current && onComplete) {
          if (data === 'login:success') {
            onComplete(null);
            return Promise.resolve();
          }
          if (String(data).startsWith('login:error:')) {
            onComplete(Error(String(data).substr(12)));
            return Promise.resolve();
          }
        }
      },
    });
  };
  return connectEmailClient;
};
