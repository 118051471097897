import { useState } from 'react';

import {
  AddIcCallOutlined,
  CallOutlined,
  PhoneInTalkOutlined,
  PhoneMissedOutlined,
} from '@mui/icons-material';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Alert, Button, Paper, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { useLocale } from '../../../../src/hooks/locale';
import type { ActivityFieldsFragment } from '../../../__generated__/graphql';
import { TimeAgo } from '../../TimeAgo';

export const Call = ({ activity }: { activity: ActivityFieldsFragment }) => {
  const [showJSON, setShowJSON] = useState(false);

  const { t } = useLocale();

  const ActivityIcon =
    activity.done && activity.success
      ? PhoneInTalkOutlined
      : activity.done && !activity.success
      ? PhoneMissedOutlined
      : activity.done === false
      ? AddIcCallOutlined
      : CallOutlined;

  const ActivityTitle =
    activity.done && activity.success
      ? t('called the lead')
      : activity.done && !activity.success
      ? t('tried to call the lead')
      : activity.done === false &&
        activity.creator?.id === activity.assignee?.id
      ? t('planned a call for {{assignee}}', {
          assignee: activity.assignee?.full_name ?? '',
        })
      : t('planned a call with the lead');

  return (
    <TimelineItem key={activity?.id} style={{ fontSize: 10 }}>
      <TimelineSeparator>
        <TimelineConnector sx={{ height: '6px', flexGrow: 0 }} />
        <div onClick={() => setShowJSON(!showJSON)}>
          <ActivityIcon
            color={
              activity.done === true && activity.success === true
                ? 'success'
                : activity.done === true && activity.success === false
                ? 'error'
                : 'primary'
            }
          />
        </div>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ pr: 0 }}>
        <Typography>
          <Link to={`/users/${activity.creator?.id}`}>
            <strong>{activity.creator?.full_name}</strong>
          </Link>{' '}
          {ActivityTitle}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          fontStyle={activity?.done_at ? 'italic' : 'normal'}
          sx={{ mb: 0.5 }}
        >
          <TimeAgo dateString={activity?.created_at} />
        </Typography>
        {activity.done === false && (
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {}}
              disableElevation
            >
              {t('Reached')}
            </Button>
            <Button
              variant="outlined"
              color="error"
              size="small"
              disableElevation
            >
              {t('Not reached')}
            </Button>
          </Stack>
        )}
        {showJSON && (
          <Paper sx={{ p: 1.5 }} elevation={0} variant="outlined">
            <Alert
              severity="info"
              sx={{
                fontSize: 12,
                fontFamily: 'monospace',
                position: 'relative',
                height: 500,
              }}
            >
              <pre
                style={{
                  overflow: 'auto',
                  position: 'absolute',
                  top: 20,
                  left: 40,
                  right: 0,
                  bottom: 0,
                }}
              >
                {JSON.stringify(activity, null, 2)}
              </pre>
            </Alert>
          </Paper>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};
