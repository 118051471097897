import { Search } from '@mui/icons-material';
import type {
  AutocompleteProps,
  AutocompleteRenderInputParams,
  PaperProps,
} from '@mui/material';
import { Autocomplete, Paper, TextField } from '@mui/material';
import type { IntrospectionType } from 'graphql';

import { useLocale } from '../../../../src/hooks/locale';
import { useScopedSearchParams } from '../../../utils/navigation';
import type { AddWhereClause, DeleteWhereClause } from '../Filters';
import { useSchema } from '../useSchema';

import { BooleanFilter } from './BooleanFilter';
import { DateFilter } from './DateFilter';
import type { EnumFilterProps } from './EnumFilter';
import { EnumFilter } from './EnumFilter';
import { FilterChip } from './FilterChip';
import { ListingFilter } from './ListingFilter';
import { NumberFilter } from './NumberFilter';
import { PlaceFilter } from './PlaceFilter';
import { StringFilter } from './StringFilter';
import { TeamFilter } from './TeamFilter';
import { UserFilter } from './UserFilter';

const TextFieldPaper = (props: PaperProps) => {
  return (
    <Paper
      {...props}
      elevation={3}
      sx={{
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        padding: 1,
        background: 'white',
      }}
    />
  );
};

export const AutocompleteTextField = (props: AutocompleteRenderInputParams) => {
  const { t } = useLocale();

  return (
    <TextFieldPaper>
      <TextField
        {...props}
        placeholder={t('Search')}
        autoFocus
        variant="outlined"
        {...props}
        InputProps={{
          ...props.InputProps,
          startAdornment: <Search />,
        }}
      />
    </TextFieldPaper>
  );
};

export const AutocompletePaper = (props: PaperProps) => {
  return (
    <Paper
      {...props}
      elevation={3}
      sx={{
        marginX: -1,
        marginTop: 1,
        borderTop: '1px solid #ccc',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    />
  );
};

export type AutocompleteItem = {
  value: string | number | boolean;
  label: string;
};

export const FilterAutocomplete = <T extends AutocompleteItem>(
  props: AutocompleteProps<T, true, true, false>,
) => {
  return (
    <Autocomplete
      {...props}
      open
      openOnFocus
      disablePortal
      disableCloseOnSelect
      multiple
      size="small"
      clearIcon={null}
      sx={{ minWidth: 150 }}
      PaperComponent={AutocompletePaper}
      renderOption={
        props.renderOption ??
        ((props, option, { index }) => (
          <li {...props} key={`option-${option.value}-${index}`}>
            {option.label}
          </li>
        ))
      }
    />
  );
};

export interface QuickFiltersProps {
  path: string[];
  label: string;
  displayedColumn?: string;
  disabled?: (where: {}) => boolean;
  filter?: {
    path: string[];
    value: Parameters<AddWhereClause>[2];
  };
  onChange?: (value: any, whereClause: {}) => void | {};
  addWhereClause: AddWhereClause;
  deleteWhereClause: DeleteWhereClause;
  queryParamsScope?: string;
}

export interface QuickFilterProps extends QuickFiltersProps {
  getValueFromPath: (path: string[], where: any) => any;
  getTypeFromPath: (path: string[]) => IntrospectionType | undefined;
  where: any;
  type: IntrospectionType;
  isNullable: boolean;
}

export const QuickFilter = ({
  path,
  label,
  displayedColumn,
  filter,
  addWhereClause,
  deleteWhereClause,
  onChange,
  queryParamsScope,
  disabled,
}: QuickFiltersProps) => {
  const { getTypeFromPath, getValueFromPath, isNullable } = useSchema();
  const [searchParams] = useScopedSearchParams(queryParamsScope);
  const where = JSON.parse(searchParams.get('where') || '{}');
  const [, ...wherePath] = path;
  const defaultValue = wherePath.reduce((p, c) => (p && p[c]) || null, where);

  const type = getTypeFromPath(path);
  if (!type) {
    // Schema still loading
    return null;
  }

  const baseProps: QuickFilterProps & { defaultValue: any } = {
    label,
    addWhereClause,
    deleteWhereClause,
    getValueFromPath,
    getTypeFromPath,
    defaultValue,
    type,
    path,
    where,
    displayedColumn,
    filter,
    onChange,
    queryParamsScope,
    disabled,
    isNullable: isNullable(path),
  };

  if (type.kind === 'INPUT_OBJECT' && type.name === 'Boolean_comparison_exp') {
    return <BooleanFilter {...baseProps} />;
  }
  if (
    type.kind === 'INPUT_OBJECT' &&
    ['timestamptz_comparison_exp', 'date_comparison_exp'].includes(type.name)
  ) {
    return <DateFilter {...baseProps} />;
  }
  if (
    type.kind === 'INPUT_OBJECT' &&
    [
      'float8_comparison_exp',
      'Int_comparison_exp',
      'Float_comparison_exp',
    ].includes(type.name)
  ) {
    return <NumberFilter {...baseProps} />;
  }
  if (type.kind === 'INPUT_OBJECT' && type.name === 'places_bool_exp') {
    return <PlaceFilter {...baseProps} />;
  }
  if (type.kind === 'INPUT_OBJECT' && type.name === 'users_bool_exp') {
    return <UserFilter {...baseProps} />;
  }
  if (type.kind === 'INPUT_OBJECT' && type.name === 'teams_bool_exp') {
    return <TeamFilter {...baseProps} />;
  }
  if (type.kind === 'INPUT_OBJECT' && type.name === 'lots_bool_exp') {
    return <ListingFilter {...baseProps} />;
  }
  if (type.kind === 'SCALAR') {
    return <StringFilter {...baseProps} />;
  }
  if (type.kind === 'ENUM') {
    return <EnumFilter {...(baseProps as EnumFilterProps)} />;
  }
  return (
    <FilterChip
      label={label}
      renderFilter={() => (
        <Paper
          sx={{
            p: 2,
            maxWidth: 500,
            maxHeight: 500,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            rowGap: 1,
          }}
        >
          <pre>{JSON.stringify({ path, type }, null, 2)}</pre>
        </Paper>
      )}
    />
  );
};
