import * as React from 'react';

import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import { Box, Flex } from 'react-system';

import { ConnectEmailButton } from '../ConnectEmailButton';

import type { ConnectEmailForm_forwardMessage$key } from './__generated__/ConnectEmailForm_forwardMessage.graphql';
import type { ConnectEmailForm_replyToMessage$key } from './__generated__/ConnectEmailForm_replyToMessage.graphql';
import type { ConnectEmailFormQuery } from './__generated__/ConnectEmailFormQuery.graphql';
import { EmailForm, type EmailFormProps } from './EmailForm';

type Props = {
  EmailFormProps?: Omit<
    EmailFormProps,
    'parentId' | 'replyToMessage' | 'forwardMessage' | 'me' | 'refetch'
  >;
  replyToMessage?: null | ConnectEmailForm_replyToMessage$key;
  forwardMessage?: null | ConnectEmailForm_forwardMessage$key;
};

export const ConnectEmailForm = (props: Props) => {
  const [fetchKey, retry] = React.useReducer(d => d + 1, 0);

  const replyToMessage = useFragment(
    graphql`
      fragment ConnectEmailForm_replyToMessage on EmailMessage {
        ...EmailForm_replyToMessage
      }
    `,
    props.replyToMessage ?? null,
  );
  const forwardMessage = useFragment(
    graphql`
      fragment ConnectEmailForm_forwardMessage on EmailMessage {
        ...EmailForm_forwardMessage
      }
    `,
    props.forwardMessage ?? null,
  );
  const { EmailFormProps } = props;

  const { me } = useLazyLoadQuery<ConnectEmailFormQuery>(
    graphql`
      query ConnectEmailFormQuery {
        me {
          ...EmailForm_me
          nylasAccount {
            __typename
          }
        }
      }
    `,
    {},
    { fetchKey, fetchPolicy: 'store-and-network' },
  );

  return me?.nylasAccount ? (
    <EmailForm
      parentId={null}
      isDialog={EmailFormProps?.isDialog ?? false}
      {...EmailFormProps}
      replyToMessage={replyToMessage}
      forwardMessage={forwardMessage}
      me={me}
      refetch={retry}
    />
  ) : (
    <Flex px={2}>
      <Box
        css={{
          margin: 'auto',
        }}
      >
        <ConnectEmailButton onSuccess={retry} onError={retry} />
      </Box>
    </Flex>
  );
};
