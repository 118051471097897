import { useState } from 'react';

import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  useTheme,
} from '@mui/material';
import { type Control, Controller, useFormContext } from 'react-hook-form';
import { useSystem } from 'react-system';

import {
  type LotSnippetData,
  RichEditor,
  createEmptyValue,
} from '../../../src/components/EmailForm/RichEditor';
import { type RichEditorValue } from '../../../src/components/EmailForm/RichEditorValue';
import { useLocale } from '../../../src/hooks/locale';

type RaRichEditorSuggestionsProps = {
  control: Control<any>;
  required?: boolean;
  name: string;
  label: string;
  singleLine?: boolean;
  suggestions?: { name: string; id: string }[];
  resetKey?: string;
  listing?: LotSnippetData;
};

const RaRichEditorField: React.FC<
  Pick<
    RaRichEditorSuggestionsProps,
    'singleLine' | 'suggestions' | 'required' | 'name' | 'listing'
  > & {
    onChange: (event: RichEditorValue) => void;
  }
> = ({ onChange, singleLine, suggestions, required, name, listing }) => {
  const { media } = useSystem();
  // Using getValues() here instead of passing down the value from the Controller will prevent rerendering from the parent when the value change. We just need the current value on reset, so when the `resetKey` changes.
  const { getValues } = useFormContext<{
    [key: string]: RichEditorValue | undefined;
  }>();

  // HACK: This is a workaround for the RichEditor suggestions to work with react-hook-form. Otherwise, the suggestions do not load. It seems that react-hook-form changes the RichEditorValue in a way that prevents the suggestions.
  const [value, setValue] = useState<RichEditorValue>(
    getValues(name) ?? createEmptyValue(),
  );

  return (
    <RichEditor
      value={value}
      required={required}
      lot={listing}
      onChange={(v: RichEditorValue) => {
        const newEditorState = v.getEditorState();
        const oldEditorState = value.getEditorState();

        setValue(v);

        if (
          newEditorState.getCurrentContent() !==
            oldEditorState.getCurrentContent() ||
          newEditorState.getBlockTree() !== oldEditorState.getBlockTree()
        ) {
          onChange(v);
        }
      }}
      singleLine={singleLine}
      css={media({
        ...(!singleLine
          ? {
              '.public-DraftEditor-content': {
                minHeight: '300px',
                height: ['auto', 'calc(100% - 47px)'], // Minus the toolbar
                maxHeight: ['calc(100vh - 400px)', '100%'],
                overflow: 'auto',
                padding: '16px 8px',
              },
              '.DraftEditor-root, .DraftEditor-editorContainer, .RichEditor-editorContainer':
                {
                  height: '100%',
                },
              '.RichEditor-editorContainer': {
                backgroundColor: 'white',
              },
            }
          : {
              '.RichEditor-editorContainer': {
                fontSize: '16px',
                padding: '8px 14px',
                height: '1.4375em',
                boxSizing: 'content-box',
                backgroundColor: 'white',
              },
            }),
        '.MuiPaper-root': {
          overflow: 'auto',
          overflowX: 'hidden',
        },
      })}
      variant="filled"
      suggestions={suggestions}
    />
  );
};

export const RaRichEditorSuggestions: React.FC<
  RaRichEditorSuggestionsProps
> = ({
  name,
  singleLine = false,
  label,
  required = false,
  control,
  suggestions,
  resetKey,
  listing,
}) => {
  const { t } = useLocale();
  const { shape, palette } = useTheme();

  return (
    <Controller<{ [key: string]: RichEditorValue | undefined }>
      name={name}
      control={control}
      rules={{ required: required ? t('This field is required') : undefined }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <FormControl fullWidth error={error != null}>
          {label != null && (
            <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
              {label}
            </FormLabel>
          )}
          <Box
            sx={{
              position: 'relative',
              '&:hover .RichEditor-MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.87)',
              },
              '&:focus-within .RichEditor-MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px',
                borderColor: palette.primary.main,
              },
              ...(error != null
                ? {
                    '.RichEditor-MuiOutlinedInput-notchedOutline, &:focus-within .RichEditor-MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: palette.error.main,
                      },
                  }
                : {}),
            }}
          >
            <RaRichEditorField
              required={required}
              onChange={onChange}
              singleLine={singleLine}
              suggestions={suggestions}
              name={name}
              key={resetKey}
              listing={listing}
            />
            <Box
              component="fieldset"
              className="RichEditor-MuiOutlinedInput-notchedOutline"
              sx={{
                position: 'absolute',
                inset: 0,
                margin: 0,
                padding: '0 8px',
                pointerEvents: 'none',
                borderRadius: `${shape.borderRadius}px`,
                border: '1px solid #9CA3AF',
                overflow: 'hidden',
              }}
            />
          </Box>
          {error != null && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
