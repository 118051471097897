/**
 * @generated SignedSource<<94e60586d4ce257a67dd24acd0588589>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmailFormDataWrapperRefetchQuery$variables = {};
export type EmailFormDataWrapperRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EmailFormDataWrapper_root">;
};
export type EmailFormDataWrapperRefetchQuery = {
  response: EmailFormDataWrapperRefetchQuery$data;
  variables: EmailFormDataWrapperRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailFormDataWrapperRefetchQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "EmailFormDataWrapper_root"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EmailFormDataWrapperRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gender",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Email",
            "kind": "LinkedField",
            "name": "primaryEmail",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PhoneNumber",
            "kind": "LinkedField",
            "name": "primaryPhoneNumber",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "formattedNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "organisation",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "formattedAddress",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "language",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emailSignature",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NylasAccount",
            "kind": "LinkedField",
            "name": "nylasAccount",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 100
          }
        ],
        "concreteType": "DocTemplateConnection",
        "kind": "LinkedField",
        "name": "docTemplates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DocTemplateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DocTemplate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "active",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DocTemplatesTenants",
                    "kind": "LinkedField",
                    "name": "docTemplatesTenants",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tenantId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isDefault",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "docTemplates(first:100)"
      }
    ]
  },
  "params": {
    "cacheID": "704a56d4411c8c9a8a2fe4cfd15cfc30",
    "id": null,
    "metadata": {},
    "name": "EmailFormDataWrapperRefetchQuery",
    "operationKind": "query",
    "text": "query EmailFormDataWrapperRefetchQuery {\n  ...EmailFormDataWrapper_root\n}\n\nfragment EmailFormDataWrapper_root on Query {\n  me {\n    ...EmailForm_me\n    id\n    nylasAccount {\n      id\n    }\n  }\n  docTemplates(first: 100) {\n    ...useGetDefaultRelayTemplate\n  }\n}\n\nfragment EmailForm_me on User {\n  id\n  firstName\n  lastName\n  gender\n  primaryEmail {\n    id\n    email\n  }\n  primaryPhoneNumber {\n    id\n    formattedNumber\n  }\n  organisation {\n    id\n    name\n    formattedAddress\n  }\n  title\n  language\n  emailSignature\n}\n\nfragment useGetDefaultRelayTemplate on DocTemplateConnection {\n  edges {\n    node {\n      id\n      type\n      active\n      docTemplatesTenants {\n        tenantId\n        isDefault\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "46fb8117222dc133e6955cbc87134551";

export default node;
