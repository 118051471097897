import { green, red } from '@material-ui/core/colors';
import { Stack, Typography } from '@mui/material';

import { useLocale } from '../../src/hooks/locale';
import { gql } from '../__generated__';
import type { LotCardInfo_LotFragment } from '../__generated__/graphql';
import { Tag } from '../components/Tag';
import { formatAddress } from '../utils/formatting';
import { getListingPrices } from '../utils/propertyTemplateFunctions';

export const LOT_CARD_INFO_FRAGMENT = gql(`
  fragment LotCardInfo_lot on lots {
    id
    title
    currency
    sale_price
    rent_net
    rent_extra
    offer_type
    price_unit
    status
    hide_price_on_portals
    is_paid
    enquiries_aggregate {
      aggregate {
        count
      }
    }
    matching_buyer_leads: buyer_leads_aggregate(where: { status: { _eq: "matched" } }) {
      aggregate {
        count
      }
    }
    offers_aggregate {
      aggregate {
        count
      }
    }
    property {
      id
      country_code
      route
      street_number
      postcode
      locality
      living_surface
      built_surface
      weighted_floor_surface
      computed_surface
      latest_appraisal {
        id
        value
      }
    }
  }
`);

type Props = {
  lot: LotCardInfo_LotFragment;
  showTitle?: boolean;
  showInlineLabels?: boolean;
};

export const getLotStatusTagVariant = (status: string) => {
  switch (status) {
    case 'available':
    case 'available_coming_soon':
    case 'available_off_market':
      return 'info';
    case 'reserved':
      return 'warning';
    case 'won':
      return 'success';
    case 'lost_sold_someone':
    case 'lost_sold_owner':
    case 'abandoned':
      return 'error';
    case 'draft':
    case 'paused':
    default:
      return 'neutral';
  }
};

export const LotCardInfo = (props: Props) => {
  const { t, locale } = useLocale();
  const { lot } = props;

  const prices = getListingPrices(lot, t, locale);

  const statusLabel = (() => {
    switch (lot.status) {
      case 'available':
        return t('available');
      case 'available_coming_soon':
        return t('availableComingSoon');
      case 'available_off_market':
        return t('availableOffMarket');
      case 'reserved':
        return t('reserved');
      case 'won':
        return t('won');
      case 'lost_sold_someone':
        return t('lostSoldBySomeone');
      case 'lost_sold_owner':
        return t('lostSoldByOwner');
      case 'abandoned':
        return t('lostAbandoned');
      case 'draft':
        return t('draft');
      case 'paused':
        return t('paused');
      default:
        return lot.status;
    }
  })();

  return (
    <Stack direction="column" flexGrow={1}>
      <Typography variant="caption" noWrap>
        {formatAddress(lot.property, ', ')}
      </Typography>

      {props.showTitle != null && (
        <Typography variant="body1" noWrap>
          {lot.title != null && lot.title !== '' ? lot.title : '-'}
        </Typography>
      )}

      <Stack alignItems="center" direction="row">
        <Typography variant="h6" noWrap fontWeight={700}>
          {prices.primary != null ? (
            lot.hide_price_on_portals === true ? (
              <s>{prices.primary}</s>
            ) : (
              prices.primary
            )
          ) : (
            t('priceOnRequest')
          )}
        </Typography>

        {prices.appraisalDiff != null && (
          <Typography
            pl={1}
            variant="subtitle2"
            fontWeight={700}
            color={prices.appraisalDiff > 0 ? green[700] : red[700]}
          >
            {prices.appraisalDiff > 0 ? '+' : ''}
            {prices.appraisalDiff.toLocaleString(locale, {
              maximumFractionDigits: 1,
              minimumFractionDigits: 1,
            })}
            %
          </Typography>
        )}
      </Stack>

      <Typography mb={1} variant="body2">
        {prices.secondary ?? '-'}
      </Typography>
      {props.showInlineLabels != null && (
        <Stack direction="row" gap={1} flexWrap="wrap">
          <Tag status={getLotStatusTagVariant(lot.status)}>{statusLabel}</Tag>
          {(lot?.enquiries_aggregate.aggregate?.count ?? 0) > 0 && (
            <Tag status="neutral">
              {t('enquiriesCount', {
                count: lot?.enquiries_aggregate.aggregate?.count ?? 0,
              })}
            </Tag>
          )}
          {(lot?.matching_buyer_leads.aggregate?.count ?? 0) > 0 && (
            <Tag status="neutral">
              {t('matchesCount', {
                count: lot?.matching_buyer_leads.aggregate?.count ?? 0,
              })}
            </Tag>
          )}
          {(lot?.offers_aggregate.aggregate?.count ?? 0) > 0 && (
            <Tag status="neutral">
              {t('offersCount', {
                count: lot?.offers_aggregate.aggregate?.count ?? 0,
              })}
            </Tag>
          )}
        </Stack>
      )}
    </Stack>
  );
};
