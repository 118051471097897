/**
 * @generated SignedSource<<0c9d0535cc15b7b36261499a9136c5b2>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type LotMandateType = "commission_based" | "fixed_fee";
export type OfferStatus = "accepted" | "pending" | "refused";
export type SubscriptionStatus = "active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "trialing" | "unpaid";
export type editorSnippetLotQuery$variables = {|
  id: string,
|};
export type editorSnippetLotQuery$data = {|
  +lotById: ?{|
    +broker: ?{|
      +firstName: ?string,
      +id: string,
      +lastName: ?string,
      +organisation: ?{|
        +formattedAddress: ?string,
        +name: ?string,
      |},
      +primaryEmail: ?{|
        +email: string,
      |},
      +primaryImage: ?{|
        +url: string,
      |},
      +subscription: ?{|
        +status: SubscriptionStatus,
      |},
    |},
    +commissionRate: ?number,
    +fixedFee: ?number,
    +id: string,
    +lotReference: ?string,
    +mandateType: LotMandateType,
    +offers: ?$ReadOnlyArray<{|
      +amount: number,
      +createdAt: string,
      +status: OfferStatus,
    |}>,
    +primaryPropertyImage: ?{|
      +image: {|
        +url: string,
      |},
    |},
    +product: ?{|
      +id: string,
    |},
    +property: {|
      +formattedAddress: ?string,
      +id: string,
      +livingSurface: ?number,
      +locality: ?string,
      +numberOfRooms: ?number,
      +postcode: ?string,
      +propertyType: ?{|
        +label: ?string,
      |},
    |},
    +salePrice: ?number,
    +seller: ?{|
      +firstName: ?string,
      +id: string,
      +lastName: ?string,
      +organisation: ?{|
        +formattedAddress: ?string,
        +name: ?string,
      |},
      +primaryEmail: ?{|
        +email: string,
      |},
      +primaryImage: ?{|
        +url: string,
      |},
      +subscription: ?{|
        +status: SubscriptionStatus,
      |},
    |},
    +title: ?string,
  |},
  +tenantSettings: ?{|
    +id: string,
  |},
|};
export type editorSnippetLotQuery = {|
  response: editorSnippetLotQuery$data,
  variables: editorSnippetLotQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lotReference",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "salePrice",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mandateType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fixedFee",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commissionRate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formattedAddress",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postcode",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locality",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfRooms",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "livingSurface",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v16 = [
  (v15/*: any*/)
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v22 = [
  (v2/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Email",
    "kind": "LinkedField",
    "name": "primaryEmail",
    "plural": false,
    "selections": [
      (v19/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Image",
    "kind": "LinkedField",
    "name": "primaryImage",
    "plural": false,
    "selections": (v16/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "organisation",
    "plural": false,
    "selections": [
      (v20/*: any*/),
      (v9/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Subscription",
    "kind": "LinkedField",
    "name": "subscription",
    "plural": false,
    "selections": [
      (v21/*: any*/)
    ],
    "storageKey": null
  }
],
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v25 = [
  (v2/*: any*/)
],
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": (v25/*: any*/),
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenantSettings",
  "plural": false,
  "selections": (v25/*: any*/),
  "storageKey": null
},
v28 = [
  (v15/*: any*/),
  (v2/*: any*/)
],
v29 = [
  (v2/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Email",
    "kind": "LinkedField",
    "name": "primaryEmail",
    "plural": false,
    "selections": [
      (v19/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Image",
    "kind": "LinkedField",
    "name": "primaryImage",
    "plural": false,
    "selections": (v28/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "organisation",
    "plural": false,
    "selections": [
      (v20/*: any*/),
      (v9/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Subscription",
    "kind": "LinkedField",
    "name": "subscription",
    "plural": false,
    "selections": [
      (v21/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editorSnippetLotQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Lot",
        "kind": "LinkedField",
        "name": "lotById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Property",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PropertyType",
                "kind": "LinkedField",
                "name": "propertyType",
                "plural": false,
                "selections": [
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyImage",
            "kind": "LinkedField",
            "name": "primaryPropertyImage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": (v16/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "broker",
            "plural": false,
            "selections": (v22/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "seller",
            "plural": false,
            "selections": (v22/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Offer",
            "kind": "LinkedField",
            "name": "offers",
            "plural": true,
            "selections": [
              (v21/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/)
            ],
            "storageKey": null
          },
          (v26/*: any*/)
        ],
        "storageKey": null
      },
      (v27/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editorSnippetLotQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Lot",
        "kind": "LinkedField",
        "name": "lotById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Property",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PropertyType",
                "kind": "LinkedField",
                "name": "propertyType",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyImage",
            "kind": "LinkedField",
            "name": "primaryPropertyImage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": (v28/*: any*/),
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "broker",
            "plural": false,
            "selections": (v29/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "seller",
            "plural": false,
            "selections": (v29/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Offer",
            "kind": "LinkedField",
            "name": "offers",
            "plural": true,
            "selections": [
              (v21/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v26/*: any*/)
        ],
        "storageKey": null
      },
      (v27/*: any*/)
    ]
  },
  "params": {
    "cacheID": "7531d32f3f019970558592ed38d79ae5",
    "id": null,
    "metadata": {},
    "name": "editorSnippetLotQuery",
    "operationKind": "query",
    "text": "query editorSnippetLotQuery(\n  $id: ID!\n) {\n  lotById(id: $id) {\n    id\n    title\n    lotReference\n    salePrice\n    mandateType\n    fixedFee\n    commissionRate\n    property {\n      id\n      formattedAddress\n      postcode\n      locality\n      numberOfRooms\n      livingSurface\n      propertyType {\n        label\n        id\n      }\n    }\n    primaryPropertyImage {\n      image {\n        url\n        id\n      }\n      id\n    }\n    broker {\n      id\n      firstName\n      lastName\n      primaryEmail {\n        email\n        id\n      }\n      primaryImage {\n        url\n        id\n      }\n      organisation {\n        name\n        formattedAddress\n        id\n      }\n      subscription {\n        status\n        id\n      }\n    }\n    seller {\n      id\n      firstName\n      lastName\n      primaryEmail {\n        email\n        id\n      }\n      primaryImage {\n        url\n        id\n      }\n      organisation {\n        name\n        formattedAddress\n        id\n      }\n      subscription {\n        status\n        id\n      }\n    }\n    offers {\n      status\n      amount\n      createdAt\n      id\n    }\n    product {\n      id\n    }\n  }\n  tenantSettings {\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "c54099f3cb4c9ba4fb098fe3134144ec";

export default node;
