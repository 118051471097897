import { type Reducer, useReducer } from 'react';

import { getFiltersFromComposedPropertyType } from '../../../../src/routes/Documents/compose-property-type';
import type {
  Merged_Listings_Compare_Args,
  StepListingsCompareFragment,
} from '../../../__generated__/graphql';
import { composePropertyType } from '../../../utils/propertyTypes';
type Property = NonNullable<
  NonNullable<StepListingsCompareFragment['lead']>['property']
>;

const getDefaultFilters = (
  property: null | Property,
  override?: Partial<Merged_Listings_Compare_Args>,
): Merged_Listings_Compare_Args => {
  return {
    ...(property?.property_type != null
      ? getFiltersFromComposedPropertyType(
          composePropertyType(
            property.property_type.main_type ?? '',
            property.property_type.name ?? '',
          ),
        )
      : null),
    number_of_rooms_lte:
      property?.number_of_rooms != null
        ? property.number_of_rooms + 1
        : property?.number_of_bedrooms != null
        ? property.number_of_bedrooms + 1
        : null,
    number_of_rooms_gte: null,
    sale_price_lte: null,
    sale_price_gte: null,
    computed_price_per_sqm_lte: null,
    computed_price_per_sqm_gte: null,
    land_surface_lte: null,
    land_surface_gte: null,
    computed_surface_lte: null,
    computed_surface_gte: null,
    locality_id_in: property?.locality_place?.id ?? null,
    state_id_in: null,
    district_id_in: null,
    municipality_id_in: null,
    neighbourhood_id_in: null,
    offline: null,
    count: 60,
    sort_by: 'id',
    sort_dir: 'desc',
    ...override,
  };
};

export const useAggregatesListingsFilters = (
  cmaReport: StepListingsCompareFragment | null,
  override?: Partial<Merged_Listings_Compare_Args>,
) => {
  return useReducer<Reducer<Merged_Listings_Compare_Args, any>>(
    (state, values) => {
      if (Object.keys(values).length === 0) {
        return {
          offline: null,
        };
      }

      return {
        ...state,
        ...values,
      };
    },
    getDefaultFilters(cmaReport?.lead?.property ?? null, override),
  );
};
