import { useCallback, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from '@mui/material';

import { useLocale } from '../../../src/hooks/locale';
import {
  LEAD_STATUS_QUERY,
  UPDATE_LEADS_STATUS_MUTATION,
} from '../../../src/shared/lead-status-dialog';
import { translateStatus } from '../../../src/utils/lead-labels';
import type {
  LeadStatusQuery,
  Leads_Status_Enum,
  UpdateLeadsStatusMutation,
} from '../../__generated__/graphql';

type DialogUpdateStatusProps = {
  leadsIds: string[];
  open: boolean;
  onCancel: () => void;
};

const DialogUpdateStatus = ({
  leadsIds,
  open,
  onCancel,
}: DialogUpdateStatusProps) => {
  const { t } = useLocale();
  const { data } = useQuery<LeadStatusQuery>(LEAD_STATUS_QUERY);
  const [selectedStatus, setSelectedStatus] =
    useState<Leads_Status_Enum | null>(null);

  const [updateLeadsStatus, { loading: updatingLeadsStatus }] =
    useMutation<UpdateLeadsStatusMutation>(UPDATE_LEADS_STATUS_MUTATION);

  const statuses = useMemo(
    () => data?.leads_status.map(status => status.value),
    [data],
  );

  const onSubmit = useCallback(async () => {
    if (!selectedStatus) {
      return;
    }
    await updateLeadsStatus({
      variables: { ids: leadsIds, status: selectedStatus },
    });

    setSelectedStatus(null);
    onCancel();
  }, [selectedStatus, updateLeadsStatus, leadsIds, onCancel]);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      PaperProps={{
        style: {
          width: '100%',
          maxWidth: 400,
          margin: '0 auto',
        },
      }}
    >
      <DialogTitle>{t('Change status')}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
            {t('Status')}
          </FormLabel>
          <Select
            fullWidth
            variant="outlined"
            size="small"
            sx={{ background: 'white' }}
            onChange={e =>
              setSelectedStatus(e.target.value as Leads_Status_Enum)
            }
            value={selectedStatus ?? ''}
          >
            {statuses?.map(status => (
              <MenuItem key={status} value={status}>
                {translateStatus(t, status)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ pb: 2, px: 3 }}>
        <Button onClick={onCancel}>{t('cancel')}</Button>
        <LoadingButton
          onClick={onSubmit}
          loadingPosition="start"
          startIcon={<Save />}
          loading={updatingLeadsStatus}
          color="primary"
          variant="contained"
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogUpdateStatus;
