import { useState } from 'react';

import { ExpandMoreOutlined } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import type { AccordionProps } from '@mui/material';

export const AccordionCard = ({
  children,
  title,
  defaultExpanded,
  ...props
}: AccordionProps) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  return (
    <Accordion
      {...props}
      expanded={expanded}
      onChange={(_, expanded) => setExpanded(expanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined />}
        sx={{
          '&.MuiAccordionSummary-root.Mui-expanded': { minHeight: 0 },
          '.MuiAccordionSummary-content.Mui-expanded': { mt: 1.5, mb: 1 },
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ display: 'block' }}>{children}</AccordionDetails>
    </Accordion>
  );
};
