import { graphql, useFragment } from 'react-relay';

import { EmailFormDataWrapper } from '../EmailForm/EmailFormDataWrapper';

import type { FeedEmailForm_buyerLead$key } from './__generated__/FeedEmailForm_buyerLead.graphql';
import type { FeedEmailForm_enquiry$key } from './__generated__/FeedEmailForm_enquiry.graphql';
import type { FeedEmailForm_lead$key } from './__generated__/FeedEmailForm_lead.graphql';
import type { FeedEmailForm_lot$key } from './__generated__/FeedEmailForm_lot.graphql';
import type { FeedEmailForm_root$key } from './__generated__/FeedEmailForm_root.graphql';
import type { FeedEmailForm_user$key } from './__generated__/FeedEmailForm_user.graphql';

type Props = {
  root: FeedEmailForm_root$key;
  enquiry?: null | FeedEmailForm_enquiry$key;
  lot?: null | FeedEmailForm_lot$key;
  lead?: null | FeedEmailForm_lead$key;
  user?: null | FeedEmailForm_user$key;
  buyerLead?: null | FeedEmailForm_buyerLead$key;
  onEmailSent: () => void;
  onCancel: () => void;
  showUnfinishedWarning: boolean;
  setShowUnfinishedWarning: (value: boolean) => void;
  setContactsCount: (value: number) => void;
  setAlertOpen: (value: boolean) => void;
};

export const FeedEmailForm = (props: Props) => {
  const {
    root,
    enquiry,
    lot,
    lead,
    user,
    buyerLead,
    ...emailFormDataWrapperProps
  } = props;

  const rootFragment = useFragment(
    graphql`
      fragment FeedEmailForm_root on Query {
        ...EmailFormDataWrapper_root
      }
    `,
    root,
  );

  const enquiryFragment = useFragment(
    graphql`
      fragment FeedEmailForm_enquiry on Enquiry {
        ...EmailFormDataWrapper_enquiry
      }
    `,
    enquiry ?? null,
  );
  const lotFragment = useFragment(
    graphql`
      fragment FeedEmailForm_lot on Lot {
        ...EmailFormDataWrapper_lot
      }
    `,
    lot ?? null,
  );
  const leadFragment = useFragment(
    graphql`
      fragment FeedEmailForm_lead on Lead {
        ...EmailFormDataWrapper_lead
      }
    `,
    lead ?? null,
  );
  const userFragment = useFragment(
    graphql`
      fragment FeedEmailForm_user on User {
        ...EmailFormDataWrapper_user
      }
    `,
    user ?? null,
  );
  const buyerLeadFragment = useFragment(
    graphql`
      fragment FeedEmailForm_buyerLead on BuyerLead {
        ...EmailFormDataWrapper_buyerLead
      }
    `,
    buyerLead ?? null,
  );

  return (
    <EmailFormDataWrapper
      isDialog={false}
      root={rootFragment}
      enquiry={enquiryFragment}
      lot={lotFragment}
      lead={leadFragment}
      user={userFragment}
      buyerLead={buyerLeadFragment}
      {...emailFormDataWrapperProps}
    />
  );
};
