/**
 * @generated SignedSource<<98eb993ee78172ad8e4addc4a80f0e9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EmailVisibilityTypeEnum = "organisation" | "private" | "shared";
export type UserGender = "female" | "male";
export type UserLanguage = "de" | "en" | "es" | "fr" | "it";
export type UserTitle = "doctor" | "madam" | "professor" | "sir";
import { FragmentRefs } from "relay-runtime";
export type EmailForm_replyToMessage$data = {
  readonly bcc: ReadonlyArray<{
    readonly email: string;
    readonly name: string | null;
    readonly user: {
      readonly firstName: string | null;
      readonly gender: UserGender | null;
      readonly id: string;
      readonly language: UserLanguage | null;
      readonly lastName: string | null;
      readonly organisation: {
        readonly formattedAddress: string | null;
        readonly id: string;
        readonly name: string | null;
      } | null;
      readonly primaryEmail: {
        readonly email: string;
        readonly id: string;
      } | null;
      readonly primaryPhoneNumber: {
        readonly formattedNumber: string;
        readonly id: string;
      } | null;
      readonly title: UserTitle | null;
    } | null;
  } | null>;
  readonly body: string | null;
  readonly cc: ReadonlyArray<{
    readonly email: string;
    readonly name: string | null;
    readonly user: {
      readonly firstName: string | null;
      readonly gender: UserGender | null;
      readonly id: string;
      readonly language: UserLanguage | null;
      readonly lastName: string | null;
      readonly organisation: {
        readonly formattedAddress: string | null;
        readonly id: string;
        readonly name: string | null;
      } | null;
      readonly primaryEmail: {
        readonly email: string;
        readonly id: string;
      } | null;
      readonly primaryPhoneNumber: {
        readonly formattedNumber: string;
        readonly id: string;
      } | null;
      readonly title: UserTitle | null;
    } | null;
  } | null>;
  readonly date: string;
  readonly from: ReadonlyArray<{
    readonly email: string;
    readonly name: string | null;
    readonly user: {
      readonly firstName: string | null;
      readonly gender: UserGender | null;
      readonly id: string;
      readonly language: UserLanguage | null;
      readonly lastName: string | null;
      readonly organisation: {
        readonly formattedAddress: string | null;
        readonly id: string;
        readonly name: string | null;
      } | null;
      readonly primaryEmail: {
        readonly email: string;
        readonly id: string;
      } | null;
      readonly primaryPhoneNumber: {
        readonly formattedNumber: string;
        readonly id: string;
      } | null;
      readonly title: UserTitle | null;
    } | null;
  } | null>;
  readonly id: string;
  readonly isSent: boolean;
  readonly messageId: string | null;
  readonly replyTo: ReadonlyArray<{
    readonly email: string;
    readonly name: string | null;
    readonly user: {
      readonly firstName: string | null;
      readonly gender: UserGender | null;
      readonly id: string;
      readonly language: UserLanguage | null;
      readonly lastName: string | null;
      readonly organisation: {
        readonly formattedAddress: string | null;
        readonly id: string;
        readonly name: string | null;
      } | null;
      readonly primaryEmail: {
        readonly email: string;
        readonly id: string;
      } | null;
      readonly primaryPhoneNumber: {
        readonly formattedNumber: string;
        readonly id: string;
      } | null;
      readonly title: UserTitle | null;
    } | null;
  } | null>;
  readonly subject: string | null;
  readonly to: ReadonlyArray<{
    readonly email: string;
    readonly name: string | null;
    readonly user: {
      readonly firstName: string | null;
      readonly gender: UserGender | null;
      readonly id: string;
      readonly language: UserLanguage | null;
      readonly lastName: string | null;
      readonly organisation: {
        readonly formattedAddress: string | null;
        readonly id: string;
        readonly name: string | null;
      } | null;
      readonly primaryEmail: {
        readonly email: string;
        readonly id: string;
      } | null;
      readonly primaryPhoneNumber: {
        readonly formattedNumber: string;
        readonly id: string;
      } | null;
      readonly title: UserTitle | null;
    } | null;
  } | null>;
  readonly visibility: EmailVisibilityTypeEnum | null;
  readonly " $fragmentType": "EmailForm_replyToMessage";
};
export type EmailForm_replyToMessage$key = {
  readonly " $data"?: EmailForm_replyToMessage$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmailForm_replyToMessage">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gender",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Email",
        "kind": "LinkedField",
        "name": "primaryEmail",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PhoneNumber",
        "kind": "LinkedField",
        "name": "primaryPhoneNumber",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "formattedNumber",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "formattedAddress",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "language",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmailForm_replyToMessage",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EmailRecipient",
      "kind": "LinkedField",
      "name": "from",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EmailRecipient",
      "kind": "LinkedField",
      "name": "to",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EmailRecipient",
      "kind": "LinkedField",
      "name": "cc",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EmailRecipient",
      "kind": "LinkedField",
      "name": "bcc",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EmailRecipient",
      "kind": "LinkedField",
      "name": "replyTo",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "messageId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSent",
      "storageKey": null
    }
  ],
  "type": "EmailMessage",
  "abstractKey": null
};
})();

(node as any).hash = "66a646f78ae2ea3ad028a495062cd99b";

export default node;
