import * as React from 'react';

import {
  Avatar,
  CircularProgress,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import { Image } from '@realadvisor/image';
import { endOfDay, format, isToday, parseISO } from 'date-fns';
import {
  fetchQuery,
  graphql,
  useLazyLoadQuery,
  useMutation,
  useRelayEnvironment,
} from 'react-relay';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, Flex } from 'react-system';

import { CreateListingModal } from '../../../apollo/components/create-listing/CreateListingModal';
import { fromGlobalId } from '../../../shared/global-id';
import { PipelineDialog } from '../../components/PipelineDialog';
import { InfiniteLoader } from '../../controls/load-more-indicator';
import { Menu } from '../../controls/popup';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { Add } from '../../icons/add';
import { AssignmentInd } from '../../icons/assignment-ind';
import { AssignmentTurnedIn } from '../../icons/assignment-turned-in';
import { Compass } from '../../icons/compass';
import { MeetingRoom } from '../../icons/meeting-room';
import { MoreVert } from '../../icons/more-vert';
import { Phone } from '../../icons/phone';
import { RoundWarning } from '../../icons/round-warning';
import { UserChat } from '../../icons/user-chat';
import {
  type Column as ColumnType,
  type PaginatedStateHook,
  useKanbanPaginatedState,
} from '../../shared/kanban/kanban-data';
import {
  KanbanDND,
  KanbanDNDColumn,
  type RenderItem,
} from '../../shared/kanban/kanban-dnd';
import {
  KanbanColumn,
  KanbanItem,
  KanbanLayout,
} from '../../shared/kanban/kanban-ui';
import { LeadDrawer } from '../../shared/lead-drawer';
import { LeadLostDialog } from '../../shared/lead-lost-dialog';
import { LeadUpdatedTimeAgo } from '../../shared/lead-updated-time-ago';
import { abbrDateDifference } from '../../utils/abbrDateDifference';
import {
  getHorizonLabel,
  translateAppraisalReason,
} from '../../utils/lead-labels';

import type { leadsKanbanCreateActivityMutation } from './__generated__/leadsKanbanCreateActivityMutation.graphql';
import type { leadsKanbanIdsQuery } from './__generated__/leadsKanbanIdsQuery.graphql';
import type {
  LeadStatus,
  leadsKanbanItemsQuery,
} from './__generated__/leadsKanbanItemsQuery.graphql';
import type { leadsKanbanStagesQuery } from './__generated__/leadsKanbanStagesQuery.graphql';
import type { leadsKanbanUpdateMutation } from './__generated__/leadsKanbanUpdateMutation.graphql';
import type { leadsKanbanUpdateStageMutation } from './__generated__/leadsKanbanUpdateStageMutation.graphql';
import { paramsToFilters, useLeadsParams } from './LeadsFilters';

graphql`
  fragment leadsKanbanItem on Lead {
    ...leadUpdatedTimeAgo_lead
    relationship
    appraisalReason
    appraisalPerceivedValue
    userCanViewLeadDetails
    saleHorizon
    buyHorizon
    status
    stageId
    mandateProbability
    predictedListingDate
    lot {
      id
    }
    contact {
      firstName
      lastName
      primaryEmail {
        email
      }
    }
    broker {
      firstName
      lastName
      primaryImage {
        url
      }
    }
    property {
      formattedAddress
      latestAppraisal {
        realadvisor {
          max
          min
        }
      }
    }
    nextActivity {
      __typename
      ... on ActivityAssignment {
        dueAt
        startDate
        overdue
      }
      ... on ActivityCall {
        dueAt
        startDate
        overdue
      }
      ... on ActivityVisit {
        dueAt
        startDate
        overdue
      }
      ... on ActivityTask {
        dueAt
        startDate
        overdue
      }
    }
  }
`;

const ItemMenu = ({
  lead,
  root,
  onUpdate,
}: {
  lead: LeadItem;
  root: leadsKanbanStagesQuery['response'];
  onUpdate: (
    data:
      | { type: 'status'; newStatus: LeadStatus }
      | { type: 'stage'; newStageId: string; newPipelineId: string },
  ) => void;
}) => {
  const { t } = useLocale();
  const navigate = useNavigate();
  const menuRef = React.useRef(null);
  const [menu, setMenu] = React.useState(false);
  const [pipelineDialog, setPipelineDialog] = React.useState(false);
  const [mandateWonDialog, setMandateWonDialog] = React.useState(false);
  const [leadLostDialog, setLeadLostDialog] = React.useState(false);

  // We need this so that we would not render <Menu> and <PipelineDialog>
  // for every card unless it's needed
  const [hasBeenOpen, setHasBeenOpen] = React.useState(false);

  const [upsertActivity] = useMutation<leadsKanbanCreateActivityMutation>(
    graphql`
      mutation leadsKanbanCreateActivityMutation($input: UpsertActivityInput!) {
        upsertActivity(input: $input) {
          activity {
            id
          }
        }
      }
    `,
  );

  const [updateLead] = useMutation<leadsKanbanUpdateMutation>(
    graphql`
      mutation leadsKanbanUpdateMutation($input: UpsertLeadInput!) {
        upsertLead(input: $input) {
          lead {
            id
            updatedAt
            status
            stageId
            lot {
              id
            }
            stage {
              id
            }
            mandateProbability
            predictedListingDate
          }
        }
      }
    `,
  );

  const { status } = lead;

  const updateStatus = (newStatus: LeadStatus) => {
    updateLead({
      variables: {
        input: {
          lead: { id: lead.id, status: newStatus },
        },
      },
      onCompleted: () => {
        onUpdate({
          type: 'status',
          newStatus,
        });
      },
    });
  };

  return (
    <>
      <IconButton
        ref={menuRef}
        size="small"
        onClick={() => {
          setMenu(v => !v);
          setHasBeenOpen(true);
        }}
      >
        <MoreVert size={20} />
      </IconButton>
      {hasBeenOpen && (
        <Menu referenceRef={menuRef} open={menu} onClose={() => setMenu(false)}>
          {status !== 'active' && (
            <MenuItem onClick={() => updateStatus('active')}>
              {t('markAsActive')}
            </MenuItem>
          )}
          {status !== 'won' && (
            <MenuItem
              onClick={() => {
                if (
                  lead.lot != null ||
                  !root.me?.modules.includes('brokerage')
                ) {
                  updateLead({
                    variables: {
                      input: {
                        lead: { id: lead.id, status: 'won' },
                      },
                    },
                    onCompleted: payload => {
                      if (payload.upsertLead?.lead?.lot?.id != null) {
                        navigate(
                          `/listings/${fromGlobalId(
                            payload.upsertLead.lead.lot.id,
                          )}`,
                        );
                      }
                    },
                  });
                } else {
                  setMandateWonDialog(true);
                }
              }}
            >
              {t('markAsWon')}
            </MenuItem>
          )}
          {status !== 'lost' && (
            <MenuItem onClick={() => setLeadLostDialog(true)}>
              {t('markAsLost')}
            </MenuItem>
          )}
          <MenuItem onClick={() => setPipelineDialog(true)}>
            {t('Change stage')}
          </MenuItem>
        </Menu>
      )}

      {hasBeenOpen &&
        lead.lot == null &&
        root.me?.modules.includes('brokerage') && (
          <CreateListingModal
            fromLeadId={fromGlobalId(lead.id)}
            opened={mandateWonDialog}
            onClose={() => setMandateWonDialog(false)}
            onListingCreated={lotId => {
              updateLead({
                variables: {
                  input: {
                    lead: { id: lead.id, status: 'won' },
                  },
                },
                onCompleted: () => {
                  setMandateWonDialog(false);
                  navigate(`/listings/${lotId}`);
                },
              });
            }}
          />
        )}

      {hasBeenOpen && (
        <LeadLostDialog
          title={t('whyIsThisLeadDead')}
          open={leadLostDialog}
          onConfirm={(id, note) => {
            setLeadLostDialog(false);
            updateLead({
              variables: {
                input: {
                  lead: { id: lead.id, status: 'lost', leadLostId: id },
                },
              },
            });
            upsertActivity({
              variables: {
                input: {
                  activity: {
                    parentId: lead.id,
                    activityType: 'note',
                    note,
                    success: false,
                    done: true,
                    doneAt: new Date().toISOString(),
                  },
                },
              },
            });
          }}
          onClose={() => setLeadLostDialog(false)}
        />
      )}

      {hasBeenOpen && (
        <PipelineDialog
          pipelines="sales"
          open={pipelineDialog}
          initialStageId={lead.stageId}
          onChange={({ stageId, pipelineId }) => {
            updateLead({
              variables: {
                input: {
                  lead: { id: lead.id, stageId },
                },
              },
              onCompleted: () => {
                onUpdate({
                  type: 'stage',
                  newPipelineId: pipelineId,
                  newStageId: stageId,
                });
                setPipelineDialog(false);
              },
            });
          }}
          onClose={() => setPipelineDialog(false)}
        />
      )}
    </>
  );
};

const Chip = ({
  children,
  color,
}: {
  children: React.ReactNode;
  color?: 'green' | 'orange';
}) => {
  const { colors } = useTheme();
  let chipColors = {};

  switch (color) {
    case 'green':
      chipColors = { color: colors.white, background: colors.green400 };
      break;
    case 'orange':
      chipColors = { color: colors.white, background: colors.orange600 };
      break;
    default:
      chipColors = { color: colors.grey600, background: colors.grey100 };
      break;
  }

  return children != null && children !== '' && children !== false ? (
    <div
      css={{
        ...chipColors,
        borderRadius: 100,
        marginRight: 6,
        marginBottom: 6,
        display: 'inline-block',
        padding: '2px 10px',
      }}
    >
      {children}
    </div>
  ) : null;
};

type BoundPaginationStateHook = PaginatedStateHook<LeadItem, { label: string }>;

// This component should be as lighweight as possible.
// We'll have a lot of them on a page.
const Item = React.memo(
  ({
    lead,
    root,
    updateItem,
    removeItem,
    moveItem,
    pipelineId,
    statusFilter,
    onRowClick,
    isAdmin,
  }: {
    lead: LeadItem;
    root: leadsKanbanStagesQuery['response'];
    updateItem: BoundPaginationStateHook['updateItem'];
    removeItem: BoundPaginationStateHook['removeItem'];
    moveItem: BoundPaginationStateHook['moveItem'];
    pipelineId: string;
    statusFilter: string[] | null;
    onRowClick?: null | ((id: string) => void);
    isAdmin: boolean;
  }) => {
    const { t, locale } = useLocale();
    const { colors, text } = useTheme();
    const leadLink = { pathname: `/leads/${lead.id}`, search: '' };

    const { contact, userCanViewLeadDetails, property } = lead;
    const contactName = [
      contact?.firstName,
      userCanViewLeadDetails === true
        ? contact?.lastName
        : (contact?.lastName ?? '').slice(0, 1) + '.',
    ]
      .filter(Boolean)
      .join(' ');

    const contactLabel =
      contact == null
        ? t('noContact')
        : contactName === ''
        ? contact.primaryEmail?.email
        : contactName;

    const address =
      userCanViewLeadDetails === true
        ? property?.formattedAddress
        : property?.formattedAddress?.split(',').slice(1).join(' ');

    const relationship = {
      owner: t('owner'),
      tenant: t('tenant'),
      buyer: t('buyer'),
      heir: t('heir'),
      agent: t('agent'),
      other: t('other'),
      not_set: null,
    }[lead.relationship];

    const formatMillions = (value: number) => {
      const formatted = (value / 1000000).toLocaleString(locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      return t('millionsOfShort', { value: formatted });
    };

    const appraisal = lead.property?.latestAppraisal?.realadvisor;
    const appraisalMinMax = [
      appraisal?.min == null ? [] : [formatMillions(appraisal.min)],
      appraisal?.max == null ? [] : [formatMillions(appraisal.max)],
    ].join(' – ');
    const appraisalPerceived =
      lead.appraisalPerceivedValue == null
        ? null
        : formatMillions(lead.appraisalPerceivedValue);

    // check for .dueAt != null is for Flow
    // this cannot happen, we select only activities where it's not null on API side
    const activity =
      lead.nextActivity == null ||
      lead.nextActivity.__typename === '%other' ||
      (lead.nextActivity.dueAt == null && lead.nextActivity.startDate == null)
        ? null
        : {
            type: lead.nextActivity.__typename,
            overdue: lead.nextActivity.overdue,
            date:
              lead.nextActivity?.startDate != null
                ? endOfDay(parseISO(lead.nextActivity.startDate))
                : parseISO(lead.nextActivity?.dueAt ?? ''),
          };

    const isTodayActivity =
      activity?.date != null ? isToday(activity.date) : false;
    const overdueBy =
      activity?.date != null
        ? abbrDateDifference(t, new Date(), activity.date, true).text
        : '';

    const ActivityIcon =
      activity == null
        ? RoundWarning
        : {
            ActivityAssignment: AssignmentInd,
            ActivityCall: Phone,
            ActivityTask: AssignmentTurnedIn,
            ActivityVisit: MeetingRoom,
          }[activity.type];

    const brokerImageUrl = lead.broker?.primaryImage?.url;
    const brokerName = [
      (lead.broker?.firstName ?? '').trim().slice(0, 1).toUpperCase(),
      lead.broker?.lastName,
    ]
      .filter(x => x !== '')
      .join('. ');

    const DATE_WIDTH = 25;
    const PADDING_X = 12;
    const PADDING_Y = 8;
    const MENU_WIDTH = 22;

    return (
      <div css={{ position: 'relative' }}>
        <Link
          to={leadLink}
          onClick={
            onRowClick != null
              ? e => {
                  if (!e.ctrlKey && !e.metaKey) {
                    e.preventDefault();
                    onRowClick(lead.id);
                  }
                }
              : undefined
          }
          css={[
            text.caption,
            {
              cursor: 'pointer',
              paddingTop: PADDING_Y,
              paddingBottom: PADDING_Y,
              display: 'block',
              ':hover': { textDecoration: 'none' },
            },
          ]}
        >
          <div
            css={{
              paddingLeft: PADDING_X,
              marginBottom: 8,
            }}
          >
            <Flex alignItems="center">
              <div
                css={{
                  color: colors.grey900,
                  fontWeight: text.font.medium,
                  fontSize: text.size(16),
                }}
              >
                {contactLabel}
              </div>

              <div
                css={{
                  color: colors.grey600,
                  marginLeft: 'auto',
                  paddingRight: PADDING_X + MENU_WIDTH,
                }}
              >
                <LeadUpdatedTimeAgo
                  shortVersion={true}
                  lead={lead}
                  showBothDate={isAdmin}
                />
              </div>
            </Flex>
            <div
              css={{ marginRight: DATE_WIDTH + MENU_WIDTH, marginBottom: 6 }}
            >
              {address != null && (
                <div
                  css={[text.truncate(1), { color: colors.grey800 }]}
                  title={address}
                >
                  {address}
                </div>
              )}
            </div>
            <Chip>{relationship}</Chip>
            <Chip>
              {lead.appraisalReason !== 'not_set' &&
                translateAppraisalReason(t, lead.appraisalReason)}
            </Chip>
            <Chip>
              {getHorizonLabel(t, {
                appraisalReason: lead.appraisalReason,
                saleHorizon: lead.saleHorizon,
                buyHorizon: lead.buyHorizon,
              })}
            </Chip>
            <Flex flexDirection="row-reverse">
              {lead.mandateProbability && (
                <Chip
                  color={lead.mandateProbability >= 50 ? 'green' : 'orange'}
                >
                  {lead.mandateProbability + '%'}
                </Chip>
              )}
              {lead.predictedListingDate && (
                <Chip color="green">
                  {format(
                    new Date(lead.predictedListingDate ?? ''),
                    'MMM yyyy',
                  )}
                </Chip>
              )}
            </Flex>
          </div>
          {(appraisalMinMax !== '' || appraisalPerceived != null) && (
            <div
              css={{
                background: colors.grey100,
                paddingBottom: 6,
                paddingTop: 6,
                paddingRight: PADDING_X,
                paddingLeft: PADDING_X,
                color: colors.grey900,
                fontWeight: text.font.medium,
                fontSize: text.size(16),
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {appraisalMinMax !== '' && (
                  <>
                    <Compass size={18} />
                    <span
                      css={{
                        marginLeft: '0.3em',
                      }}
                    >
                      {appraisalMinMax}
                    </span>
                  </>
                )}
              </span>
              {appraisalPerceived != null && (
                <span
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginLeft: '0.5em',
                  }}
                >
                  <UserChat size={18} />
                  <span
                    css={{
                      marginLeft: '0.3em',
                    }}
                  >
                    {appraisalPerceived}
                  </span>
                </span>
              )}
            </div>
          )}
          <div
            css={{
              display: 'flex',
              paddingRight: PADDING_X,
              paddingLeft: PADDING_X,
              paddingTop: 10,
              paddingBottom: 4,
              justifyContent: 'space-between',
            }}
          >
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: text.font.medium,
                color:
                  activity == null || activity.overdue
                    ? colors.errorText
                    : isTodayActivity
                    ? colors.warningText
                    : colors.successText,
              }}
            >
              <ActivityIcon size={18} css={{ marginRight: '0.3em' }} />
              {activity == null
                ? t('noActivity')
                : activity.overdue
                ? t('overdueBy', {
                    time: overdueBy,
                  })
                : t('dueIn', {
                    time: overdueBy,
                  })}
            </div>
            <div
              css={{
                color: colors.grey900,
                fontWeight: text.font.medium,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {brokerImageUrl != null && (
                <Avatar css={{ width: 22, height: 22, marginRight: '0.3em' }}>
                  {/* Using 40x40 so we reuse the file generated for UserCard */}
                  <Image src={brokerImageUrl} srcSize={[[40, 40]]} />
                </Avatar>
              )}
              {brokerName}
            </div>
          </div>
        </Link>
        <div css={{ position: 'absolute', right: 4, top: PADDING_Y }}>
          <ItemMenu
            lead={lead}
            root={root}
            onUpdate={action => {
              if (action.type === 'status') {
                if (
                  statusFilter != null &&
                  !statusFilter.includes(action.newStatus)
                ) {
                  removeItem(lead.id);
                } else {
                  updateItem({
                    ...lead,
                    status: action.newStatus,
                  });
                }
              }
              if (action.type === 'stage') {
                if (action.newPipelineId !== pipelineId) {
                  removeItem(lead.id);
                } else {
                  moveItem(lead.id, action.newStageId);
                }
              }
            }}
          />
        </div>
      </div>
    );
  },
);

const getItemId = (lead: LeadItem) => lead.id;
const updateItemAfterMove = (lead: LeadItem, columnId: string) =>
  lead.stageId === columnId ? lead : { ...lead, stageId: columnId };

const Column = ({
  index,
  column,
  paginatedState,
  pipelineId,
  statusFilter,
  onRowClick,
  isAdmin,
  root,
}: {
  index: number;
  column: ColumnType<LeadItem, { label: string }>;
  paginatedState: BoundPaginationStateHook;
  pipelineId: string;
  statusFilter: string[] | null;
  onRowClick?: (id: string) => void;
  isAdmin: boolean;
  root: leadsKanbanStagesQuery['response'];
}) => {
  const { colors } = useTheme();
  const { search } = useLocation();
  const newLink = {
    pathname: `/leads/add/${column.columnId}`,
    search,
  };

  const totalCount =
    column.readyItems.length +
    [column.loadingPage, ...column.idlePages]
      .map(page => page?.itemIds.length ?? 0)
      .reduce((a, b) => a + b, 0);

  const { updateItem, removeItem, moveItem } = paginatedState;

  const renderItem: RenderItem<LeadItem> = React.useCallback(
    ({ item, isDragging, props, ref }) => {
      return (
        <KanbanItem {...props} isDragging={isDragging} ref={ref}>
          <Item
            lead={item}
            root={root}
            updateItem={updateItem}
            removeItem={removeItem}
            moveItem={moveItem}
            pipelineId={pipelineId}
            statusFilter={statusFilter}
            onRowClick={!isDragging ? onRowClick : null}
            isAdmin={isAdmin}
          />
        </KanbanItem>
      );
    },
    [
      updateItem,
      removeItem,
      moveItem,
      pipelineId,
      statusFilter,
      onRowClick,
      isAdmin,
      root,
    ],
  );

  return (
    <KanbanDNDColumn
      key={column.columnId}
      id={column.columnId}
      items={column.readyItems}
      getItemId={getItemId}
      renderItem={renderItem}
      renderContainer={({ children, ref, isDraggingOver }) => (
        <KanbanColumn
          ref={ref}
          index={index}
          label={
            <>
              <Box flexGrow={1}>
                {column.data.label} ({totalCount})
              </Box>
              <Box>
                <IconButton component={Link} to={newLink} size="small">
                  <Add size={20} fill={colors.primaryMain} />
                </IconButton>
              </Box>
            </>
          }
        >
          {children}

          {column.loadingPage && (
            <Flex
              justifyContent="center"
              mt={column.readyItems.length === 0 ? 4 : 0}
              mb={2}
            >
              <CircularProgress size={24} disableShrink />
            </Flex>
          )}

          {column.loadingPage == null &&
            column.idlePages.length > 0 &&
            !isDraggingOver && (
              <InfiniteLoader
                offset={-500}
                onLoadMore={() => paginatedState.loadPage(column.idlePages[0])}
              />
            )}
        </KanbanColumn>
      )}
    />
  );
};

const PAGE_SIZE = 8;

type LeadItem = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<leadsKanbanItemsQuery['response']['leads']>['edges']
    >[number]
  >['node']
>;

const LeadsKanbanBase = ({
  stages,
  leadIds,
  pipelineId,
  statusFilter,
  onRowClick,
  leadId,
  onClose,
  isAdmin,
  root,
}: {
  stages: NonNullable<
    leadsKanbanStagesQuery['response']['pipelineById']
  >['stages'];
  leadIds: NonNullable<leadsKanbanIdsQuery['response']['leads']>;
  pipelineId: string;
  statusFilter: string[] | null;
  onRowClick?: (id: string) => void;
  leadId: null | string;
  onClose: () => void;
  isAdmin: boolean;
  root: leadsKanbanStagesQuery['response'];
}) => {
  const relayEnv = useRelayEnvironment();

  const [updateStage] = useMutation<leadsKanbanUpdateStageMutation>(
    graphql`
      mutation leadsKanbanUpdateStageMutation($input: UpdateLeadStageInput!) {
        updateLeadStage(input: $input) {
          clientMutationId
        }
      }
    `,
  );

  const loadItems = async (itemsId: string[]) => {
    // Please talk to @istarkov, @TrySound, or @rpominov before using fetchQuery() somewhere else
    // We should avoid it if possible because it's a low level API that may work unexpectedly
    const resp = await fetchQuery<leadsKanbanItemsQuery>(
      relayEnv,

      // NOTE:
      //  Do not use fragments w/o (mask: false) in this query or its subfragments.
      //  If we do that, Relay will put data into it's store,
      //  and then it will either delete it at a random time, or hold indefinitely: both bad.
      graphql`
        query leadsKanbanItemsQuery($first: Int!, $filters: LeadsFilters!) {
          leads(first: $first, filters: $filters) {
            edges {
              node {
                id
                ...leadsKanbanItem @relay(mask: false)
              }
            }
          }
        }
      `,
      { first: itemsId.length, filters: { id_in: itemsId } },
    ).toPromise();

    return (resp?.leads?.edges ?? []).flatMap(edge =>
      edge?.node == null ? [] : [edge.node],
    );
  };

  const getInitialState = () => {
    const leads = (leadIds.edges ?? []).flatMap(edge =>
      edge?.node ? [edge.node] : [],
    );
    return stages.map(stage => {
      const stageLeads = leads.filter(lead => lead.stageId === stage.id);
      const pagesCount = Math.ceil(stageLeads.length / PAGE_SIZE);
      return {
        columnId: stage.id,
        data: { label: stage.label },
        readyItems: [],
        loadingPage: null,
        idlePages: Array.from(Array(pagesCount), (_, i) => ({
          pageId: [stage.id, i].join('/'),
          itemIds: stageLeads
            .slice(i * PAGE_SIZE, i * PAGE_SIZE + PAGE_SIZE)
            .map(x => x.id),
        })),
      };
    });
  };

  const paginatedState = useKanbanPaginatedState<LeadItem, { label: string }>({
    getInitialState,
    getItemId,
    loadItems,
    afterDragEnd: (args, updatedState) => {
      const { itemId, destColumnId, destIndex } = args;
      updateStage({
        variables: {
          input: {
            stageId: destColumnId,
            id: itemId,
            putAfter:
              destIndex === 0
                ? null
                : updatedState.find(column => column.columnId === destColumnId)
                    ?.readyItems[destIndex - 1].id,
          },
        },
      });
    },
    updateItemAfterMove,
  });

  return (
    <KanbanDND onDragEnd={paginatedState.onDragEnd}>
      <KanbanLayout>
        {paginatedState.state.map((column, index) => (
          <Column
            key={column.columnId}
            index={index}
            column={column}
            paginatedState={paginatedState}
            pipelineId={pipelineId}
            statusFilter={statusFilter}
            onRowClick={onRowClick}
            isAdmin={isAdmin}
            root={root}
          />
        ))}
      </KanbanLayout>
      {leadId != null && (
        <LeadDrawer
          leadId={leadId}
          onClose={onClose}
          onDelete={() => paginatedState.removeItem(leadId)}
        />
      )}
    </KanbanDND>
  );
};

export const LeadsKanban = (props: {
  pipelineId: string;
  onRowClick?: (id: string) => void;
  leadId: string | null;
  onClose: () => void;
}) => {
  const [params] = useLeadsParams();
  const filters = paramsToFilters(params);

  const statusFilterAsString = JSON.stringify(
    filters.status_in?.filter(Boolean) ?? null,
  );
  // Makes it a stable array
  const statusFilter: null | string[] = React.useMemo(
    () => JSON.parse(statusFilterAsString),
    [statusFilterAsString],
  );

  const data = useLazyLoadQuery<leadsKanbanStagesQuery>(
    graphql`
      query leadsKanbanStagesQuery($id: ID!) {
        pipelineById(id: $id) {
          stages {
            id
            label
          }
        }
        me {
          isAdmin
          modules
        }
      }
    `,
    { id: props.pipelineId },
  );

  const pipeline = data.pipelineById;

  // We load IDs of all itmes at the beginning.
  // This number is somewhat arbitrary limit,
  // we may extend it in the future if need arise and performance allow.
  const MAX_SUPPORTED_ITEMS = 200000;

  const { leads } = useLazyLoadQuery<leadsKanbanIdsQuery>(
    graphql`
      query leadsKanbanIdsQuery($first: Int!, $filters: LeadsFilters!) {
        leads(first: $first, sortBy: "posWithinStage", filters: $filters) {
          edges {
            node {
              id
              stageId
            }
          }
        }
      }
    `,
    {
      first: MAX_SUPPORTED_ITEMS,
      filters: {
        ...filters,
        stageName_in: null,
        stageId_in: pipeline?.stages.map(stage => stage.id),
      },
    },
    { fetchPolicy: 'store-and-network' },
  );

  // should never happen unless a bug
  if (pipeline == null) {
    return <>Error: pipeline not found</>;
  }

  // for flow
  if (leads == null) {
    return null;
  }

  // temporary solution
  // after discussion with the business, a new kanban refactoring task will be created
  // // should not happen soon hopefully
  // if (leads.totalCount > MAX_SUPPORTED_ITEMS) {
  //   return <>Error: to many lead</>;
  // }

  // We rely on an assumption that `stages` and `leadIds`
  // never change during a lifetime of a LeadsKanbanBase instance.
  // (if they do change, useKanbanPaginatedState will still hold the old state)
  //
  // This is currently true because <*Renderer>s render `null` during loading,
  // so on a reload we unmount <LeadsKanbanBase> and then mount again.

  // key props force useKanbanPaginatedState and whole component to re-render thus updating data if leads count changes
  return (
    <LeadsKanbanBase
      key={leads.edges?.length}
      stages={pipeline.stages}
      leadIds={leads}
      pipelineId={props.pipelineId}
      statusFilter={statusFilter}
      onRowClick={props.onRowClick}
      leadId={props.leadId}
      onClose={props.onClose}
      isAdmin={data.me?.isAdmin ?? false}
      root={data}
    />
  );
};
