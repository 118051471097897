// @flow

import * as React from 'react';

import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Form, useFormik } from '@realadvisor/form';
import { useGoogleGeocoder } from '@realadvisor/hooks';
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from 'react-relay';
import { Box, Flex, useSystem } from 'react-system';

import {
  PropertyDrawer,
  useAppraiseProperty,
} from '../components/BasicListItem';
import {
  BUILT_SURFACE_MAX,
  BUILT_SURFACE_MIN,
  LAND_SURFACE_MAX,
  LAND_SURFACE_MIN,
  LIVING_SURFACE_MAX,
  LIVING_SURFACE_MIN,
  type PropertyFormState,
  getAppraisalRequirements,
} from '../components/PropertyForm';
import { GOOGLE_MAPS_TOKEN } from '../config';
import { CounterInput } from '../controls/counter-input';
import { CountryInput } from '../controls/country-input';
import {
  DrawerBottomToolbar,
  DrawerContent,
  DrawerHeader,
  DrawerLayer,
} from '../controls/drawer';
import { LocationMarker, Mapbox } from '../controls/mapbox';
import { SquareInput } from '../controls/meter-input';
import { ProgressButton } from '../controls/progress-button';
import { SelectInput } from '../controls/select-input';
import { YearInput } from '../controls/year-input';
import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { ChevronRight } from '../icons/chevron-right';
import { getDefaultLocationOnMap } from '../locale';
import { AddressInput } from '../shared/address-input';
import { parseGeocoderResult } from '../utils/geocoder-result';
import { number_of_string } from '../utils/number-format';

import type { leadCreateDrawer_user$key } from './__generated__/leadCreateDrawer_user.graphql';
import type { leadCreateDrawerAssignmentMutation } from './__generated__/leadCreateDrawerAssignmentMutation.graphql';
import type {
  PropertyInput,
  leadCreateDrawerMutation,
} from './__generated__/leadCreateDrawerMutation.graphql';
import type { leadCreateDrawerQuery } from './__generated__/leadCreateDrawerQuery.graphql';
import { type User, UserInput } from './user-input';

type FormValues = {|
  contact: ?User,
  lat: ?number,
  lng: ?number,
  route: ?string,
  streetNumber: ?string,
  postcode: ?string,
  state: ?string,
  locality: ?string,
  countryCode: ?string,

  mainType: null | string,
  propertyTypeId: null | string,
  propertyTypeName: null | string,
  livingSurface: null | string,
  landSurface: string,
  numberOfRooms: null | string,
  builtSurface: null | string,
  numberOfBedrooms: null | string,
  constructionYear: string,
  renovationYear: string,
  source: ?string,
  pipelineId: ?string,
  stageId: ?string,

  $propertyFormInputSnapshot: null | PropertyInput, // given input after PropertyForm, then pass to the upsert mutation
  $propertyFormStateSnapshot: null | PropertyFormState, // save and restore PropertyForm state
|};

const AddressForm = ({ mapRef, formik, setEditedMannually, mapViewPorts }) => {
  const { t, countryCode } = useLocale();
  const { media, px } = useSystem();
  const { values, setValues, setTouched } = formik;

  return (
    <div
      css={[
        px(2),
        media({
          display: 'grid',
          gridAutoRows: 'min-content',
          gap: 16,
          gridTemplateColumns: ['1fr', '1fr 1fr'],
        }),
      ]}
    >
      <div
        css={{
          gridColumn: '1 / -1',
          display: 'grid',
          gridTemplateColumns: '1fr 100px',
          gap: 16,
        }}
      >
        <FormControl required={true} error={formik.errors.route != null}>
          <InputLabel>{t('route')}</InputLabel>
          <AddressInput
            value={values.route ?? ''}
            location={mapViewPorts[countryCode]}
            onChange={value => {
              setValues({ route: value });
              setEditedMannually(true);
            }}
            country={countryCode}
            onSelect={address => {
              if (address) {
                setValues({
                  countryCode: address.countryCode,
                  route: address.route,
                  streetNumber: address.streetNumber,
                  locality: address.locality,
                  postcode: address.postcode,
                  state: address.state,
                  lat: address.lat,
                  lng: address.lng,
                });
                mapRef.current?.setCenter({
                  lat: address.lat,
                  lng: address.lng,
                });
                setEditedMannually(false);
              }
            }}
            onBlur={() => setTouched({ route: true })}
          />
          {formik.errors.route != null && (
            <FormHelperText>{formik.errors.route}</FormHelperText>
          )}
        </FormControl>
        <TextField
          variant="filled"
          label={t('streetNumber')}
          value={values.streetNumber ?? ''}
          onChange={event => {
            setValues({ streetNumber: event.target.value });
            setEditedMannually(true);
          }}
          onBlur={() => setTouched({ streetNumber: true })}
        />
      </div>

      <TextField
        variant="filled"
        label={t('postcode')}
        value={values.postcode ?? ''}
        onChange={event => {
          setValues({ postcode: event.target.value });
          setEditedMannually(true);
        }}
        onBlur={() => setTouched({ postcode: true })}
      />

      <TextField
        variant="filled"
        label={t('locality')}
        value={values.locality ?? ''}
        onChange={event => {
          setValues({ locality: event.target.value });
          setEditedMannually(true);
        }}
        onBlur={() => setTouched({ locality: true })}
      />

      <TextField
        label={t('state')}
        required={true}
        variant="filled"
        error={formik.errors.state != null}
        helperText={formik.errors.state ?? ' '}
        value={values.state ?? ''}
        onChange={event => {
          setValues({ state: event.target.value });
          setEditedMannually(true);
        }}
        onBlur={() => setTouched({ state: true })}
      />
      <FormControl required={true} error={formik.errors.countryCode != null}>
        <InputLabel>{t('country')}</InputLabel>
        <CountryInput
          value={values.countryCode}
          onChange={newValue => {
            setValues({ countryCode: newValue });
            setEditedMannually(true);
          }}
        />
        {formik.errors.countryCode != null && (
          <FormHelperText>{formik.errors.countryCode}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

const isEmpty = value =>
  value == null || (typeof value === 'string' && value.length === 0);

const outOfRange = (value: string, min: number, max: number) => {
  const number = number_of_string(value);
  return number == null || number < min || number > max;
};

const LeadCreateForm = ({
  address,
  onClose,
  onCreate,
  pipelineId: pipelineIdProp,
  stageId: stageIdProp,
  user,
}) => {
  const { t, countryCode } = useLocale();
  const { text } = useTheme();
  const { media } = useSystem();

  const data = useLazyLoadQuery<leadCreateDrawerQuery>(
    graphql`
      query leadCreateDrawerQuery {
        me {
          id
        }
        tenantSettings {
          defaultPipeline {
            id
          }
          countryCode
        }
        pipelines {
          id
          label
          name
          leadType
          stages {
            __typename
            id
            label
          }
        }
        leadSourceTypes: dictionaries(type: "lead_source_types") {
          name
          label
        }
        propertyTypes {
          id
          name
          label
          mainType
        }
      }
    `,
    {},
  );

  const isEsTenant = data.tenantSettings?.countryCode === 'ES';
  const pipelines = data.pipelines.filter(
    pipeline => pipeline.leadType === 'sales' && pipeline.stages.length > 0,
  );
  const pipelineId = pipelineIdProp ?? data.tenantSettings?.defaultPipeline?.id;
  const pipeline = data.pipelines.find(p => p.id === pipelineId);
  const defaultLocationOnMap = getDefaultLocationOnMap(countryCode);
  let firstStageInPipeline;
  if (pipeline?.stages) {
    [firstStageInPipeline] = pipeline.stages;
  }
  const stageId = stageIdProp ?? firstStageInPipeline?.id;

  const [createLead, creatingLead] = useMutation<leadCreateDrawerMutation>(
    graphql`
      mutation leadCreateDrawerMutation($input: UpsertLeadInput!) {
        upsertLead(input: $input) {
          lead {
            id
            property {
              id
              propertyType {
                mainType
                name
              }
            }
          }
        }
      }
    `,
  );

  const [assignAndClaimLead, creatingAssignAndClaimLead] =
    useMutation<leadCreateDrawerAssignmentMutation>(
      graphql`
        mutation leadCreateDrawerAssignmentMutation(
          $input: UpsertActivityInput!
        ) {
          upsertActivity(input: $input) {
            activity {
              id
            }
          }
        }
      `,
    );

  const [geocode] = useGoogleGeocoder(GOOGLE_MAPS_TOKEN);
  const [editedMannually, setEditedMannually] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [appraiseProperty, appraising] = useAppraiseProperty();

  const formik = useFormik<FormValues>({
    initialValues: {
      contact: user ?? null,
      lat: address?.lat,
      lng: address?.lng,
      route: address?.route,
      streetNumber: address?.streetNumber,
      state: address?.state,
      postcode: address?.postcode,
      countryCode: address?.countryCode,
      locality: address?.locality,
      // default to crm
      source: 'crm',
      pipelineId,
      stageId,
      mainType: null,
      propertyTypeId: null,
      propertyTypeName: null,
      landSurface: '',
      livingSurface: '',
      numberOfRooms: '',
      constructionYear: '',
      renovationYear: '',
      // for ES tenant
      builtSurface: '',
      numberOfBedrooms: '',

      $propertyFormInputSnapshot: null,
      $propertyFormStateSnapshot: null,
    },
    validate: values => {
      const errors = {};
      if (isEmpty(values.route)) {
        errors.route = t('addressIsRequired');
      }
      if (isEmpty(values.countryCode)) {
        errors.countryCode = t('countryIsRequired');
      }
      if (isEmpty(values.state)) {
        errors.state = t('stateIsRequired');
      }
      if (isEmpty(values.pipelineId)) {
        errors.pipelineId = t('pipelineIsRequired');
      }
      if (isEmpty(values.stageId)) {
        errors.stageId = t('stageIsRequired');
      }

      if (isEmpty(values.contact)) {
        errors.contact = t('Field is required');
      }

      if (values.mainType == null) {
        errors.mainType = t('Field is required');
      }
      if (values.propertyTypeId == null) {
        errors.propertyTypeId = t('Field is required');
      }

      if (
        ['HOUSE', 'APPT'].includes(values.mainType) &&
        values.propertyTypeName !== 'house_multiple_dwelling'
      ) {
        const renovationYear =
          number_of_string(values.renovationYear ?? '') ?? 0;
        const constructionYear =
          number_of_string(values.constructionYear ?? '') ?? 0;

        if (isEmpty(values.constructionYear)) {
          errors.constructionYear = t('Field is required');
        }

        if (!isEmpty(values.renovationYear)) {
          if (renovationYear > new Date().getFullYear()) {
            errors.renovationYear = t(
              'Renovation year must be less than current year',
            );
          }

          if (
            !isEmpty(values.constructionYear) &&
            renovationYear < constructionYear
          ) {
            errors.renovationYear = t(
              'renovationYearCannotBeBeforeConstructionYear',
            );
          }
        }

        if (isEsTenant) {
          if (isEmpty(values.builtSurface)) {
            errors.builtSurface = t('Field is required');
          }
          if (isEmpty(values.numberOfBedrooms)) {
            errors.numberOfBedrooms = t('Field is required');
          }
          if (
            values &&
            values.builtSurface != null &&
            values.builtSurface.length !== 0 &&
            outOfRange(
              values.builtSurface,
              BUILT_SURFACE_MIN,
              BUILT_SURFACE_MAX,
            )
          ) {
            errors.builtSurface = t('builtSurfaceMustBeInRange', {
              min: BUILT_SURFACE_MIN,
              max: BUILT_SURFACE_MAX,
            });
          }
          if (
            values &&
            values.numberOfBedrooms != null &&
            values.numberOfBedrooms.length !== 0 &&
            outOfRange(values.numberOfBedrooms, 1, 50)
          ) {
            errors.numberOfBedrooms = t('numberOfBedroomsMustBeInRange', {
              min: 1,
              max: 50,
            });
          }
        } else {
          if (isEmpty(values.livingSurface)) {
            errors.livingSurface = t('Field is required');
          }
          if (isEmpty(values.numberOfRooms)) {
            errors.numberOfRooms = t('Field is required');
          }
          if (
            values &&
            values.livingSurface != null &&
            values.livingSurface.length !== 0 &&
            outOfRange(
              values.livingSurface,
              LIVING_SURFACE_MIN,
              LIVING_SURFACE_MAX,
            )
          ) {
            errors.livingSurface = t('livingSurfaceMustBeInRange', {
              min: LIVING_SURFACE_MIN,
              max: LIVING_SURFACE_MAX,
            });
          }
          if (
            values &&
            values.numberOfRooms != null &&
            values.numberOfRooms.length !== 0 &&
            outOfRange(values.numberOfRooms, 1, 50)
          ) {
            errors.numberOfRooms = t('numberOfRoomsMustBeInRange', {
              min: 1,
              max: 50,
            });
          }
        }
      }
      if (
        values.mainType === 'PROP' ||
        values.propertyTypeName === 'house_multiple_dwelling'
      ) {
        if (isEmpty(values.landSurface)) {
          errors.landSurface = t('Field is required');
        }
        if (
          values.landSurface.length !== 0 &&
          outOfRange(values.landSurface, 0, LAND_SURFACE_MAX)
        ) {
          errors.landSurface = t('landSurfaceMustBeInRange', {
            min: LAND_SURFACE_MIN,
            max: LAND_SURFACE_MAX,
          });
        }
      }
      return errors;
    },
    onSubmit: values => {
      const property = {
        ...values.$propertyFormInputSnapshot,
        lat: values.lat,
        lng: values.lng,
        route: values.route,
        streetNumber: values.streetNumber,
        postcode: values.postcode,
        state: values.state,
        locality: values.locality,
        countryCode: values.countryCode,
        propertyTypeId: values.propertyTypeId,
        livingSurface: number_of_string(values.livingSurface ?? ''),
        landSurface: number_of_string(values.landSurface),
        numberOfRooms: number_of_string(values.numberOfRooms ?? ''),
        numberOfBedrooms: number_of_string(values.numberOfBedrooms ?? ''),
        builtSurface: number_of_string(values.builtSurface ?? ''),
        constructionYear: number_of_string(values.constructionYear),
        renovationYear: number_of_string(values.renovationYear),
      };

      const isBuildingAppraisable =
        countryCode === 'CH' &&
        values.$propertyFormStateSnapshot != null &&
        !getAppraisalRequirements(values.$propertyFormStateSnapshot).some(
          field => isEmpty(values.$propertyFormStateSnapshot?.[field]),
        );

      createLead({
        variables: {
          input: {
            lead: {
              property: property.route != null ? property : null,
              contactId: values.contact != null ? values.contact.id : null,
              source: values.source ?? 'crm',
              stageId: values.stageId,
              completed: true,
            },
          },
        },
        onCompleted: payload => {
          if (payload.upsertLead?.lead != null) {
            const { property, id: leadId } = payload.upsertLead.lead;

            assignAndClaimLead({
              variables: {
                input: {
                  activity: {
                    assignedTo: data.me?.id,
                    parentId: leadId,
                    success: true,
                    done: true,
                    doneAt: new Date().toISOString(),
                    activityType: 'assignment',
                  },
                },
              },
              onCompleted: () => {
                if (
                  (['HOUSE', 'APPT'].includes(
                    property?.propertyType?.mainType,
                  ) &&
                    property?.propertyType?.name !==
                      'house_multiple_dwelling') ||
                  (BUILDING_APPRAISABLE_TYPES.includes(
                    property?.propertyType?.name,
                  ) &&
                    isBuildingAppraisable)
                ) {
                  appraiseProperty({
                    variables: {
                      input: { id: property?.id ?? '' },
                    },
                    onCompleted: () => {
                      onCreate(leadId, values.stageId);
                    },
                  });
                } else {
                  onCreate(leadId, values.stageId);
                }
              },
            });
          }
        },
      });
    },
  });

  const { values, setValues, setTouched, errors } = formik;

  const COMMERCIAL_APPRAISABLE_TYPES = [
    'indus_commercial',
    'indus_commercial_and_residential',
  ];

  const BUILDING_APPRAISABLE_TYPES = [
    ...COMMERCIAL_APPRAISABLE_TYPES,
    'house_multiple_dwelling',
  ];

  // appraisal is available for HOUSE and APPT
  const categories = [
    {
      id: 'HOUSE',
      label: t('house'),
    },
    {
      id: 'APPT',
      label: t('apartment'),
    },
    {
      id: 'INDUS',
      label: t('commercial'),
    },
    {
      id: 'PROP',
      label: t('Land'),
    },
    {
      id: 'PARK',
      label: t('parking'),
    },
    {
      id: 'GASTRO',
      label: t('hotellerie'),
    },
  ];
  const mapToInitialValues = ({
    route,
    streetNumber,
    state,
    postcode,
    countryCode,
    locality,
    mainType,
    propertyTypeId,
    propertyTypeName,
    lat,
    lng,
    livingSurface,
    builtSurface,
    numberOfBedrooms,
    landSurface,
    numberOfRooms,
    constructionYear,
    renovationYear,
  }: FormValues) => ({
    ...(values.$propertyFormStateSnapshot ?? {}),
    route,
    streetNumber,
    state,
    postcode,
    countryCode,
    locality,
    mainType,
    propertyTypeId,
    propertyTypeName,
    lat,
    lng,
    livingSurface: livingSurface ?? '',
    numberOfRooms: numberOfRooms ?? '',
    builtSurface: builtSurface ?? '',
    numberOfBedrooms: numberOfBedrooms ?? '',
    landSurface,
    constructionYear,
    renovationYear,
  });
  const initialValues = mapToInitialValues(values);

  const stages =
    pipelines.find(p => p.id === formik.values.pipelineId)?.stages ?? [];

  const mapRef = React.useRef(null);

  const updateMarker = ({ latLng }) => {
    // prevent reverse geodecoding if address
    // is written manually
    if (editedMannually) {
      setValues(latLng);
    } else {
      geocode({ location: latLng }, results => {
        if (results.length !== 0) {
          setValues({
            ...parseGeocoderResult(results[0]),
            ...latLng,
          });
          setTouched({ route: true });
        }
      });
    }
  };

  return (
    <>
      <PropertyDrawer
        propertyId={null}
        open={open}
        onClose={() => setOpen(false)}
        initialValues={initialValues}
        showAppraisalAlert={true}
        disabledFields={[]}
        handleSubmit={(
          $propertyFormInputSnapshot,
          $propertyFormStateSnapshot,
        ) => {
          const {
            lat,
            lng,
            route,
            streetNumber,
            state,
            postcode,
            countryCode,
            locality,
            mainType,
            propertyTypeId,
            livingSurface,
            landSurface,
            numberOfRooms,
            numberOfBedrooms,
            builtSurface,
            constructionYear,
          } = $propertyFormStateSnapshot;
          setValues({
            lat,
            lng,
            route,
            streetNumber,
            state,
            postcode,
            countryCode,
            locality,
            mainType,
            propertyTypeId,
            livingSurface,
            landSurface,
            numberOfRooms,
            numberOfBedrooms,
            builtSurface,
            constructionYear,
            $propertyFormStateSnapshot,
            $propertyFormInputSnapshot,
          });

          setOpen(false);
        }}
      />
      <Form onSubmit={formik.submitForm}>
        <Box pt={3} pb={3}>
          <Box css={text.subtitle2} px={3} pb={2}>
            {t('contact')}
          </Box>
          <Box
            px={3}
            pb={3}
            css={media({
              display: 'grid',
              gap: 16,
              gridTemplateColumns: ['1fr', '1fr 1fr'],
            })}
          >
            <FormControl error={formik.errors.contact != null}>
              <InputLabel>{t('selectContact')}</InputLabel>
              <UserInput
                value={formik.values.contact}
                onChange={contact => formik.setValues({ contact })}
                onBlur={() => formik.setTouched({ contact: true })}
              />
              {formik.errors.contact != null && (
                <FormHelperText>{formik.errors.contact}</FormHelperText>
              )}
            </FormControl>
            <TextField
              label={t('leadOrigin')}
              value={formik.values.source}
              select={true}
              onChange={event =>
                formik.setValues({ source: event.target.value })
              }
            >
              {data.leadSourceTypes.map(({ name, label }) => (
                <MenuItem key={name} value={name}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label={t('pipeline')}
              value={formik.values.pipelineId}
              required={true}
              select={true}
              error={formik.errors.pipelineId != null}
              helperText={formik.errors.pipelineId ?? null}
              onChange={event => {
                const pipeline = pipelines.find(
                  p => p.id === event.target.value,
                );
                let stage;
                if (pipeline != null) {
                  [stage] = pipeline.stages;
                }
                formik.setValues({
                  pipelineId: event.target.value,
                  stageId: stage?.id,
                });
              }}
            >
              {pipelines.map(({ id, label }) => (
                <MenuItem key={id} value={id}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label={t('pipelineStep')}
              value={formik.values.stageId}
              required={true}
              select={true}
              error={formik.errors.stageId != null}
              helperText={formik.errors.stageId ?? ' '}
              onChange={event =>
                formik.setValues({ stageId: event.target.value })
              }
            >
              {stages.map(({ id, label }) => (
                <MenuItem key={id} value={id}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Flex flexWrap="wrap">
            <Box
              px={2}
              width={1}
              css={media({
                maxWidth: ['100%', 360, 480],
              })}
            >
              <Box css={text.subtitle2} pb={2} px={2}>
                {t('address')}
              </Box>
              <Box width={1}>
                <AddressForm
                  mapRef={mapRef}
                  formik={formik}
                  setEditedMannually={setEditedMannually}
                  mapViewPorts={defaultLocationOnMap}
                />
              </Box>
            </Box>

            <Box
              width={1}
              p={3}
              mt={3}
              flexShrink={1}
              flexGrow={1}
              flexBasis={'0%'}
            >
              <div css={{ height: '100%', minHeight: 300 }}>
                <Mapbox
                  mapRef={mapRef}
                  lat={address?.lat ?? defaultLocationOnMap.lat}
                  lng={address?.lng ?? defaultLocationOnMap.lng}
                  zoom={14}
                  onClick={updateMarker}
                />
                {formik.values.lat != null && formik.values.lng != null && (
                  <LocationMarker
                    mapRef={mapRef}
                    lat={formik.values.lat}
                    lng={formik.values.lng}
                    onDragend={updateMarker}
                  />
                )}
              </div>
            </Box>
          </Flex>
          <Box p={2}>
            <Box css={text.subtitle2} px={2} pb={2}>
              {t('Property type')}
            </Box>
            <Box
              px={2}
              pb={3}
              css={media({
                display: 'grid',
                gap: 16,
                gridTemplateColumns: ['1fr', '1fr 1fr'],
              })}
            >
              <FormControl error={errors.mainType != null}>
                <InputLabel>{t('category')}*</InputLabel>
                <SelectInput
                  items={categories}
                  value={values.mainType}
                  onChange={mainType =>
                    setValues({
                      mainType,
                      propertyTypeId: null,
                      propertyTypeName: null,
                      // reset property drawer form
                      $propertyFormInputSnapshot: null,
                      $propertyFormStateSnapshot: null,
                    })
                  }
                />
                {errors.mainType != null && (
                  <FormHelperText>{errors.mainType}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                disabled={values.mainType == null}
                error={errors.propertyTypeId != null}
              >
                <InputLabel>{t('Type')}*</InputLabel>
                <SelectInput
                  items={(data.propertyTypes ?? [])
                    .filter(Boolean)
                    .filter(type => type.mainType === values.mainType)
                    .filter(type =>
                      values.mainType === 'INDUS'
                        ? COMMERCIAL_APPRAISABLE_TYPES.includes(type.name ?? '')
                        : true,
                    )
                    .map(type => ({ id: type.id, label: type.label ?? '' }))}
                  value={values.propertyTypeId}
                  onChange={propertyTypeId => {
                    const propertyType = data.propertyTypes?.find(
                      type => type.id === propertyTypeId,
                    );

                    setValues({
                      propertyTypeId,
                      propertyTypeName: propertyType?.name ?? '',
                      // reset property drawer form
                      $propertyFormInputSnapshot: null,
                      $propertyFormStateSnapshot: null,
                    });
                  }}
                />
                {errors.propertyTypeId != null && (
                  <FormHelperText>{errors.propertyTypeId}</FormHelperText>
                )}
              </FormControl>
            </Box>
          </Box>
          {((['APPT', 'HOUSE'].includes(values.mainType) &&
            !isEmpty(values.propertyTypeName) &&
            values.propertyTypeName !== 'house_multiple_dwelling') ||
            values.mainType === 'PROP') && (
            <Box p={3}>
              <Box css={text.subtitle2} pb={2}>
                {t('Property details')}
              </Box>
              {values.mainType === 'PROP' ? (
                <Box width={[1, 1 / 2]}>
                  <FormControl error={errors.landSurface != null}>
                    <InputLabel css={[text.ellipsis, { paddingRight: 18 }]}>
                      {t('landSurface')}*
                    </InputLabel>
                    <SquareInput
                      decimalScale={2}
                      value={values.landSurface ?? ''}
                      onChange={newValue =>
                        setValues({ landSurface: newValue })
                      }
                      onBlur={() => setTouched({ landSurface: true })}
                    />
                    {errors.landSurface != null && (
                      <FormHelperText>{errors.landSurface}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              ) : (
                <div
                  css={media({
                    display: 'grid',
                    gap: 16,
                    gridTemplateColumns: ['1fr', '1fr 1fr'],
                  })}
                >
                  {isEsTenant ? (
                    <FormControl error={errors.builtSurface != null}>
                      <InputLabel css={[text.ellipsis, { paddingRight: 18 }]}>
                        {t('builtSurface')}*
                      </InputLabel>
                      <SquareInput
                        decimalScale={2}
                        value={values.builtSurface ?? ''}
                        onChange={newValue =>
                          setValues({ builtSurface: newValue })
                        }
                        onBlur={() => setTouched({ builtSurface: true })}
                      />
                      {errors.builtSurface != null && (
                        <FormHelperText>{errors.builtSurface}</FormHelperText>
                      )}
                    </FormControl>
                  ) : (
                    <FormControl error={errors.livingSurface != null}>
                      <InputLabel css={[text.ellipsis, { paddingRight: 18 }]}>
                        {t('livingSurface')}*
                      </InputLabel>
                      <SquareInput
                        decimalScale={2}
                        value={values.livingSurface ?? ''}
                        onChange={newValue =>
                          setValues({ livingSurface: newValue })
                        }
                        onBlur={() => setTouched({ livingSurface: true })}
                      />
                      {errors.livingSurface != null && (
                        <FormHelperText>{errors.livingSurface}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                  {values.mainType === 'HOUSE' && (
                    <FormControl error={errors.landSurface != null}>
                      <InputLabel css={[text.ellipsis, { paddingRight: 18 }]}>
                        {t('landSurface')}*
                      </InputLabel>
                      <SquareInput
                        decimalScale={2}
                        value={values.landSurface ?? ''}
                        onChange={newValue =>
                          setValues({ landSurface: newValue })
                        }
                        onBlur={() => setTouched({ landSurface: true })}
                      />
                      {errors.landSurface != null && (
                        <FormHelperText>{errors.landSurface}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                  {isEsTenant ? (
                    <FormControl error={errors.numberOfBedrooms != null}>
                      <InputLabel shrink={true}>{t('bedrooms')}*</InputLabel>
                      <CounterInput
                        step={values.countryCode !== 'CH' ? 1 : 0.5}
                        min={1}
                        max={50}
                        value={values.numberOfBedrooms ?? ''}
                        onChange={value =>
                          setValues({ numberOfBedrooms: value })
                        }
                        onBlur={() => setTouched({ numberOfBedrooms: true })}
                      />
                      {errors.numberOfBedrooms != null && (
                        <FormHelperText>
                          {errors.numberOfBedrooms}
                        </FormHelperText>
                      )}
                    </FormControl>
                  ) : (
                    <FormControl error={errors.numberOfRooms != null}>
                      <InputLabel shrink={true}>{t('rooms')}*</InputLabel>
                      <CounterInput
                        step={values.countryCode !== 'CH' ? 1 : 0.5}
                        min={1}
                        max={50}
                        value={values.numberOfRooms ?? ''}
                        onChange={value => setValues({ numberOfRooms: value })}
                        onBlur={() => setTouched({ numberOfRooms: true })}
                      />
                      {errors.numberOfRooms != null && (
                        <FormHelperText>{errors.numberOfRooms}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                  <FormControl error={errors.constructionYear != null}>
                    <InputLabel shrink={true}>
                      {t('Construction year')}*
                    </InputLabel>
                    <YearInput
                      value={values.constructionYear}
                      onChange={value => setValues({ constructionYear: value })}
                      onBlur={() => setTouched({ constructionYear: true })}
                    />
                    {errors.constructionYear != null && (
                      <FormHelperText>{errors.constructionYear}</FormHelperText>
                    )}
                  </FormControl>
                  <FormControl error={errors.renovationYear != null}>
                    <InputLabel shrink={true}>
                      {t('Renovation year')}
                    </InputLabel>
                    <YearInput
                      value={values.renovationYear}
                      onChange={value => setValues({ renovationYear: value })}
                      onBlur={() => setTouched({ renovationYear: true })}
                    />
                    {errors.renovationYear != null && (
                      <FormHelperText>{errors.renovationYear}</FormHelperText>
                    )}
                  </FormControl>
                </div>
              )}
            </Box>
          )}

          <Box width={1} px={3}>
            <Button
              endIcon={<ChevronRight />}
              size="small"
              onClick={() => {
                setOpen(true);
              }}
              css={{ display: 'inline-flex', marginTop: 8 }}
              disabled={
                values.mainType == null || values.propertyTypeName == null
              }
            >
              {t('editMoreDetails')}
            </Button>
            <FormHelperText>
              {t('createdPropertyWillBeAppraised')}
            </FormHelperText>
          </Box>
        </Box>
        <DrawerBottomToolbar>
          <Button onClick={onClose}>{t('cancel')}</Button>
          <ProgressButton
            loading={creatingLead || creatingAssignAndClaimLead || appraising}
            onClick={formik.submitForm}
          >
            {t('create')}
          </ProgressButton>
        </DrawerBottomToolbar>
      </Form>
    </>
  );
};

type LeadCreateDrawerProps = {|
  open: boolean,
  address?: ?{|
    lat: ?number,
    lng: ?number,
    route: ?string,
    streetNumber: ?string,
    postcode: ?string,
    state: ?string,
    locality: ?string,
    countryCode: ?string,
  |},
  pipelineId?: ?string,
  stageId?: ?string,
  onClose: () => void,
  onCreate: (leadId: string, stageId: ?string) => void,
  user?: ?leadCreateDrawer_user$key,
|};

export const LeadCreateDrawer = (props: LeadCreateDrawerProps): React.Node => {
  const { t } = useLocale();
  const user = useFragment(
    graphql`
      fragment leadCreateDrawer_user on User {
        ...userInput_user @relay(mask: false)
      }
    `,
    props.user,
  );

  return (
    <DrawerLayer
      width={['100vw', 'calc(100vw - 100px)', 960]}
      open={props.open}
      onClose={props.onClose}
    >
      <DrawerHeader onClose={props.onClose}>
        {t('createSellerLead')}
      </DrawerHeader>
      <DrawerContent>
        <React.Suspense fallback={null}>
          <LeadCreateForm
            address={props.address}
            onCreate={props.onCreate}
            onClose={props.onClose}
            pipelineId={props.pipelineId}
            stageId={props.stageId}
            user={user}
          />
        </React.Suspense>
      </DrawerContent>
    </DrawerLayer>
  );
};
